import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const WalletIcon = (props: Props) => {
  const { width = 38, height = 38 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 38 38" fill="none">
      <circle cx="19" cy="19" r="19" fill="#333333" />
      <path
        d="M8.65062 12.6252C9.46427 11.9249 10.5331 11.5 11.7038 11.5H25.5499C26.7206 11.5 27.7894 11.9249 28.6031 12.6252C28.4139 11.1453 27.1191 10 25.5499 10H11.7038C10.1346 10 8.83978 11.1453 8.65062 12.6252Z"
        fill="white"
      />
      <path
        d="M8.65062 15.6252C9.46427 14.9249 10.5331 14.5 11.7038 14.5H25.5499C26.7206 14.5 27.7894 14.9249 28.6031 15.6252C28.4139 14.1453 27.1191 13 25.5499 13H11.7038C10.1346 13 8.83978 14.1453 8.65062 15.6252Z"
        fill="white"
      />
      <path
        d="M11.7039 16C10.0045 16 8.62695 17.3431 8.62695 19V25C8.62695 26.6569 10.0045 28 11.7039 28H25.55C27.2494 28 28.627 26.6569 28.627 25V19C28.627 17.3431 27.2494 16 25.55 16H21.7039C21.279 16 20.9346 16.3358 20.9346 16.75C20.9346 17.9926 19.9015 19 18.627 19C17.3524 19 16.3193 17.9926 16.3193 16.75C16.3193 16.3358 15.9749 16 15.55 16H11.7039Z"
        fill="white"
      />
    </svg>
  );
};
export default WalletIcon;
