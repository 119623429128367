type Props = {
  width?: string;
  height?: string;
};
const ProfileIcon = (props: Props) => {
  const { width = "4em", height = "4em" } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" id="profile">
      <defs>
        <linearGradient
          id="a"
          x1="32"
          x2="32"
          y1="64.044"
          y2="-.044"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fd5900"></stop>
          <stop offset="1" stopColor="#fedc00"></stop>
        </linearGradient>
        <linearGradient
          id="b"
          x1="12.92"
          x2="12.92"
          y1="64.044"
          y2="-.044"
        ></linearGradient>
        <linearGradient
          id="c"
          x1="48.25"
          x2="48.25"
          y1="64.044"
          y2="-.044"
        ></linearGradient>
      </defs>
      <path
        fill="url(#a)"
        d="M41,25V23a9,9,0,0,0-18,0v2a9,9,0,0,0,18,0ZM25,25V23a7,7,0,0,1,14,0v2a7,7,0,0,1-14,0Z"
      ></path>
      <path
        fill="url(#a)"
        d="M16.312,43.057a1,1,0,1,0,1.5,1.326,18.978,18.978,0,0,1,27.625-.815c.257.257.514.533.761.809a1,1,0,0,0,1.49-1.334c-.272-.3-.556-.608-.838-.89a20.977,20.977,0,0,0-30.535.9Z"
      ></path>
      <path
        fill="url(#a)"
        d="M32,4A27.847,27.847,0,0,0,16.164,8.906,1,1,0,0,0,17.3,10.555,25.993,25.993,0,1,1,10.554,17.3a1,1,0,1,0-1.648-1.133A28,28,0,1,0,32,4Z"
      ></path>
      <path
        fill="url(#b)"
        d="M13.157,14.1c.3-.328.61-.638.938-.939a1,1,0,1,0-1.35-1.474c-.372.339-.722.69-1.062,1.061A1,1,0,0,0,13.157,14.1Z"
      ></path>
      <path
        fill="url(#c)"
        d="M47.75,49.75a1,1,0,0,0,.7-.282c.346-.337.683-.674,1.02-1.022a1,1,0,1,0-1.434-1.392c-.324.332-.647.655-.98.978a1,1,0,0,0,.7,1.718Z"
      ></path>
      <path
        fill="url(#a)"
        d="M50.841,45.223a1,1,0,0,0,1.381-.3,24,24,0,1,0-7.3,7.3,1,1,0,0,0-1.078-1.686,22,22,0,1,1,6.7-6.7A1,1,0,0,0,50.841,45.223Z"
      ></path>
    </svg>
  );
};
export default ProfileIcon;
