import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import AuthLayout from "./layouts/authLayout/AuthLayout";
import LoginPage from "./pages/login/LoginPage";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import DashboardPage from "./pages/dashboard/DashboardPage";
import UsersPage from "./pages/user/UsersPage";
import SellPage from "./pages/sell/SellPage";
import TransactionsPage from "./pages/transactions/TransactionsPage";
import BankPage from "./pages/bank/BankPage";
import { STORE_KEY } from "./utils/AppConstants";
import { loginSuccess } from "./store/reducers/AuthReducer";
import { setAuthToken } from "./api/config";
import RedeemPage from "./pages/redeem/RedeemPage";
import SettingsPage from "./pages/settings/SettingsPage";
import CurrencyPage from "./pages/currency/CurrencyPage";
import UserDetailPage from "./pages/user/details/UserDetailPage";
import ProfilePage from "./pages/profile/Profile";
import AdminUsers from "./pages/admin/users/AdminUsers";
import AdminUserDetails from "./pages/admin/userdetails/AdminUserDetails";
import PageNotFound from "./pages/pageNotFound/PageNotFound";

const AuthRoute = ({ element }: any) => {
  const sessionData = localStorage.getItem(STORE_KEY.SESSION_DATA);
  return sessionData ? <Navigate to="/dashboard" /> : element;
};

const ProtectedRoute = ({ element }: any) => {
  const sessionData = localStorage.getItem(STORE_KEY.SESSION_DATA);
  return sessionData ? element : <Navigate to="/" />;
};

const AppRouter = () => {
  const dispatch = useDispatch();

  const saveToAuthState = (loginData: any) => {
    dispatch({
      type: loginSuccess.type,
      payload: {
        token: loginData.token,
        result: loginData,
      },
    });
    setAuthToken(loginData.token);
  };
  useEffect(() => {
    const sessionData = localStorage.getItem(STORE_KEY.SESSION_DATA);
    if (sessionData) {
      const userData = JSON.parse(sessionData);
      saveToAuthState(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<AuthRoute element={<LoginPage />} />} />
        </Route>

        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<DashboardLayout />} />}
        >
          <Route index element={<DashboardPage />} />
          <Route path="user-list" element={<UsersPage />} />
          <Route path="admin-user-list" element={<AdminUsers />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="user-list/user/:userId" element={<UserDetailPage />} />
          <Route path="redeem-request" element={<RedeemPage />} />
          <Route path="sell-request" element={<SellPage />} />
          <Route path="transactions" element={<TransactionsPage />} />
          <Route path="bank-list" element={<BankPage />} />
          <Route path="currency-list" element={<CurrencyPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route
            path="admin-user-list/user/:userId"
            element={<AdminUserDetails />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};
export default AppRouter;
