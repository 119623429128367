import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileData, ProfileResponse } from "../../types/ProfileUpdateModel";

interface ProfileState {
  result: ProfileData | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProfileState = {
  result: null,
  loading: false,
  error: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileRequest(state) {
      state.loading = true;
      state.error = null;
    },
    profileSuccess(state, action: PayloadAction<ProfileResponse>) {
      console.log("Profile Payload: ", action.payload);

      state.loading = false;
      state.result = action.payload.data;
    },
    profileFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { profileRequest, profileSuccess, profileFailure } =
  profileSlice.actions;

export default profileSlice.reducer;
