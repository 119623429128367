import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LogoIcon } from "../../assets";
import { COLOR } from "../../theme";
import NotFoundIcon from "../../assets/svg/NotFoundIcon";

const PageNotFound = () => {
  const navigation = useNavigate();

  const goHome = () => {
    navigation(-1);
  };

  return (
    <Box sx={styles.container}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <LogoIcon height={35} width={53} />
          <Typography sx={styles.logoText}>AEG Web Wallet</Typography>
        </Stack>
        <NotFoundIcon />

        <Typography sx={styles.infoText}>
          The page you requested was not found
        </Typography>
        <Typography sx={styles.descText}>
          Sorry! The page you were looking for does not exit.
          <br />
          This page may have been moved or deleted.
        </Typography>
        <Button sx={styles.buttonStyle} onClick={() => goHome()}>
          Go Back
        </Button>
      </Container>
    </Box>
  );
};
export default PageNotFound;
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  logoText: {
    fontSize: "2rem",
    fontWeight: 700,
    color: COLOR.primary,
  },
  Text404: {
    fontSize: "8rem",
    fontWeight: 700,
    color: "#611923",
  },
  infoText: {
    fontSize: "1.4rem",
    fontWeight: 500,
    color: "#E01717",
    marginBottom: "2em",
  },
  descText: {
    fontSize: "1rem",
    fontWeight: 400,
    color: "#9999A1",
  },
  buttonStyle: {
    fontSize: "1rem",
    fontWeight: 500,
    color: COLOR.primary,
    border: "2px solid #FFD700",
    padding: "0.5em 1.5em",
    borderRadius: "10px",
    marginTop: "3em",
    position: "relative",
    zIndex: 1,
    transition: "color 0.4s, border-color 0.4s",
    outline: "none",
    "&:hover": {
      color: "#FFD700",
      borderColor: "#FFFFFF",
    },
    ":hover::before": {
      opacity: 1,
      boxShadow:
        "0 0 20px rgba(255, 215, 0, 0.8),0 0 40px rgba(255, 215, 0, 0.6),0 0 60px rgba(255, 215, 0, 0.4)",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "10px",
      zIndex: -1,
      transition: "box-shadow 0.4s ease-in-out",
      boxShadow:
        "0 0 20px rgba(255, 215, 0, 0.6),0 0 40px rgba(255, 215, 0, 0.4),0 0 60px rgba(255, 215, 0, 0.2)",
      opacity: 0,
    },
  },
};
