import { Stack, Typography } from "@mui/material";

type TProps = {
  message?: string;
};
const NoDataFound = (props: TProps) => {
  const { message = "No Data Found" } = props;
  return (
    <Stack direction={"column"}>
      <Typography sx={styles.noDataFoundTextHeading} mt={2}>
        Sorry!
      </Typography>
      <Typography sx={styles.noDataFoundText} my={2}>
        {message}
      </Typography>
    </Stack>
  );
};
export default NoDataFound;

const styles = {
  noDataFoundTextHeading: {
    fontSize: "1.8rem",
    fontWeight: 900,
    color: "#454973",
    textAlign: "center",
  },
  noDataFoundText: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#CCCCCC",
    textAlign: "center",
  },
};
