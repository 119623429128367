import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const CurrencyIcon = (props: Props) => {
  const { width = 28, height = 28 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none">
      <path
        d="M21.5 8.66667C21.5 6.0895 17.5823 4 12.75 4C7.91767 4 4 6.0895 4 8.66667C4 11.2438 7.91767 13.3333 12.75 13.3333C17.5823 13.3333 21.5 11.2438 21.5 8.66667ZM12.75 15.6667C13.9482 15.6667 15.0845 15.5325 16.1228 15.2992C17.7083 14.1103 20.3065 13.3333 23.25 13.3333C28.0823 13.3333 32 15.4228 32 18C32 20.5772 28.0823 22.6667 23.25 22.6667C18.4177 22.6667 14.5 20.5772 14.5 18C14.5 17.9662 14.5082 17.9335 14.5093 17.8997H14.5V20.3333C14.5 22.9105 18.4177 25 23.25 25C28.0823 25 32 22.9105 32 20.3333V22.6667C32 25.2438 28.0823 27.3333 23.25 27.3333C18.4177 27.3333 14.5 25.2438 14.5 22.6667V25C14.5 27.5772 18.4177 29.6667 23.25 29.6667C28.0823 29.6667 32 27.5772 32 25V27.3333C32 29.9105 28.0823 32 23.25 32C18.4177 32 14.5 29.9105 14.5 27.3333V27.24C13.9342 27.3007 13.3497 27.3333 12.75 27.3333C7.91767 27.3333 4 25.2438 4 22.6667V20.3333C4 22.8055 7.60733 24.8238 12.1667 24.9848V22.6515C7.60733 22.4905 4 20.4733 4 18V15.6667C4 18.1388 7.60733 20.1572 12.1667 20.3182V17.9848C7.60733 17.8238 4 15.8067 4 13.3333V11C4 13.5772 7.91767 15.6667 12.75 15.6667Z"
        fill="currentColor"
      />
      <clipPath id="clip0_1794_7497">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </svg>
  );
};
export default CurrencyIcon;
