import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  userWalletFailure,
  userWalletRequest,
  userWalletSuccess,
} from "../reducers/UserWalletReducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { UserWalletResponse } from "../../types/UserWalletModel";

function* requestUserWalletSaga(action: PayloadAction<number>): any {
  try {
    const response = yield call(
      axiosInstance.get,
      `/user/wallet/${action.payload}`
    );
    if (response.status === 200) {
      const data: UserWalletResponse = response.data;
      yield put(userWalletSuccess(data));
    } else {
      yield put(userWalletFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      userWalletFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchUserWalletSaga() {
  yield takeLatest(userWalletRequest.type, requestUserWalletSaga);
}

export default watchFetchUserWalletSaga;
