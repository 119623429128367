import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  TextField,
  Select,
  InputAdornment,
  FormControl,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  SelectChangeEvent,
} from "@mui/material";
import styles from "./RedeemPage.styles";
import SearchIcon from "@mui/icons-material/Search";
import { formatNumber, getColorByStatus } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { redeemListRequest } from "../../store/reducers/RedeemReducer";
import { RootState } from "../../store/reducers/RootReducers";
import { RedeemList } from "../../types/RedeemListModel";
import { getTransactionListDateTimeFormat } from "../../utils/DateUtils";
import RedeemDialog from "../../components/RedeemDialog";
import AlertComponent from "../../components/AlertComponent";
import { AlertDataProps } from "../../types/Common";
import { RightArrowIcon } from "../../assets";
import Loader from "../../components/Loader";

const RedeemPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const recordsPerPage = 10;
  const redeem = useSelector((state: RootState) => state.redeemState);
  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState("");
  const [redeemList, setRedeemList] = useState<RedeemList[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [redeemRow, setRedeemRow] = useState<RedeemList | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  const requestRedeem = (currentPage?: number) => {
    const queryString = [];
    if (filterStatus) {
      const query = `${encodeURIComponent("status")}=${encodeURIComponent(
        filterStatus
      )}`;
      queryString.push(query);
    }

    const query = `${encodeURIComponent("page")}=${encodeURIComponent(
      currentPage ? currentPage : page
    )}&${encodeURIComponent("limit")}=${encodeURIComponent(recordsPerPage)}`;
    queryString.push(query);

    if (
      searchInputRef?.current?.value &&
      searchInputRef?.current?.value?.length > 0
    ) {
      const query = `${encodeURIComponent("filter")}=${encodeURIComponent(
        searchInputRef?.current?.value
      )}`;
      queryString.push(query);
    }

    const strJoin = queryString.join("&");
    dispatch(redeemListRequest(strJoin));
  };

  useEffect(() => {
    requestRedeem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (
      redeem.resultList?.redeemOrders &&
      redeem.resultList.redeemOrders.length > 0
    ) {
      setRedeemList(redeem.resultList.redeemOrders);
      setTotalPages(redeem.resultList.totalPages);
      setTotalRecords(redeem.resultList.totalCount);
    } else {
      setRedeemList([]);
    }
  }, [redeem.resultList]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setFilterStatus(event.target.value);
  };

  useEffect(() => {
    requestRedeem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    requestRedeem(value);
    setPage(value);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      setTimeout(() => {
        setPage(1);
        requestRedeem(1);
      }, 1500);
    } else {
      if (
        redeem?.resultList?.redeemOrders &&
        redeem.resultList.redeemOrders.length > 0
      ) {
        requestRedeem();
      }
    }
  };

  const handleRowClick = (data: RedeemList) => {
    setRedeemRow(data);
  };

  return (
    <>
      <Card sx={styles.cardContainerStyle}>
        <CardContent sx={styles.paddingZero}>
          <Stack direction="row" sx={styles.filterContainer}>
            <TextField
              placeholder="Search"
              size="small"
              inputRef={searchInputRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "white" }} />
                  </InputAdornment>
                ),
                style: { color: "white", borderColor: "white" },
              }}
              variant="outlined"
              onChange={handleSearchChange}
              sx={styles.searchInputStyle}
            />
            <FormControl
              variant="outlined"
              sx={{
                minWidth: 160,
                height: "35px !important",
                maxHeight: "35px !important",
              }}
            >
              <Select
                value={filterStatus}
                onChange={handleStatusChange}
                displayEmpty
                sx={{
                  color: "white",
                  borderColor: "white",
                  height: "35px !important",
                  maxHeight: "35px !important",
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>Choose Status</em>
                </MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={styles.tableTitleRowBorder}>
                  <TableCell>#</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>AEG Qty</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Requested Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {redeem?.loading ? (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Box sx={styles.loadingRow}>
                        <Loader />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : redeemList && redeemList.length > 0 ? (
                  redeemList?.map((row: RedeemList, index: number) => (
                    <TableRow key={index} onClick={() => handleRowClick(row)}>
                      <TableCell sx={styles.customRows}>
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell sx={styles.customRows}>
                        <img
                          src={row.cryptoIconUrl}
                          alt={row.cryptoTicker}
                          style={styles.profileImageStyle}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {row.transactionId}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {row.userName
                            ? row.userName?.includes("null")
                              ? "Anonymous User"
                              : row.userName
                            : "Anonymous User"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {formatNumber(row.txValue)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "0.9em",
                            fontWeight: 500,
                            textAlign: "center",
                            color: getColorByStatus(row.status),
                          }}
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {getTransactionListDateTimeFormat(row.createdAt)}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography sx={styles.customRows}>
                          <RightArrowIcon width={26} height={26} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography sx={styles.sorryText}>Sorry!!</Typography>
                      <Typography sx={styles.noDataRow}>
                        No Data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
            px={3}
            mt={2}
          >
            <Typography
              sx={styles.totalRecordsStyle}
            >{`Total Records: ${totalRecords}`}</Typography>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              sx={{
                button: { color: "white" },
                ul: { justifyContent: "center" },
              }}
            />
          </Box>
        </CardContent>
      </Card>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
      {redeemRow !== null ? (
        <RedeemDialog
          open={redeemRow !== null ? true : false}
          data={redeemRow}
          onClose={() => {
            setRedeemRow(null);
            requestRedeem();
          }}
          onError={(error: string) => {
            setAlertData({
              title: "Redeem Error",
              message: error,
              type: "error",
            });
            requestRedeem();
            setRedeemRow(null);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default RedeemPage;
