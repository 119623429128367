/* eslint-disable array-callback-return */
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../store/reducers/RootReducers";
import { useEffect, useState } from "react";
import { configGroupRequest } from "../../../store/reducers/AEGConfigGroupReducer";
import { COLOR } from "../../../theme";
import AlertComponent from "../../../components/AlertComponent";
import { AlertDataProps } from "../../../types/Common";
import { requestConfigGroupUpdate } from "../../../api/api";
import { Form, FormikProvider, useFormik } from "formik";
import { walletVaultSchema } from "../../../validations/Validation";
import PrimaryButton from "../../../components/PrimaryButton";

const WalletVault = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const configData = useSelector((state: RootState) => state.configGroupState);
  const authData = useSelector((state: RootState) => state.authState);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    dispatch(configGroupRequest("hot_vault"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (configData && configData.result && configData.result.data.length > 0) {
      formik.setFieldValue(
        "binanceAddress",
        getConfigValueByKey("binance_address")
      );
      formik.setFieldValue(
        "binancePrivateKey",
        getConfigValueByKey("binance_private_key")
      );
      formik.setFieldValue(
        "ethereumAddress",
        getConfigValueByKey("ethereum_address")
      );
      formik.setFieldValue(
        "ethereumPrivateKey",
        getConfigValueByKey("ethereum_private_key")
      );
      formik.setFieldValue("trxAddress", getConfigValueByKey("tron_address"));
      formik.setFieldValue(
        "tronPrivateKey",
        getConfigValueByKey("tron_private_key")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  const getConfigValueByKey = (key: string) => {
    if (
      configData !== null &&
      configData.result &&
      configData.result.data.length > 0
    ) {
      const configValueRes = configData.result.data.find(
        (data) => data.configKey === key
      );
      return configValueRes?.configValue;
    } else {
      return "";
    }
  };

  const submitConfigData = async (data: any) => {
    const response = await requestConfigGroupUpdate("hot_vault", data);
    if (response) {
      if (response.status === 200) {
        setAlertData({
          message: response.data.message,
          type: "success",
        });
        dispatch(configGroupRequest("hot_vault"));
      } else {
        setAlertData({
          message: response.data.message,
          type: "error",
        });
      }
    } else {
      setAlertData({
        message: "Failed to update config data. Please try again.",
        type: "error",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      binanceAddress: "",
      binancePrivateKey: "",
      ethereumAddress: "",
      ethereumPrivateKey: "",
      trxAddress: "",
      tronPrivateKey: "",
    },
    validationSchema: walletVaultSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      if (configData?.result?.data && configData.result.data.length > 0) {
        const configDataList = configData.result.data;
        const updatedData = configDataList.map((data) => {
          if (data.configKey === "binance_address") {
            return {
              ...data,
              configValue: formik.getFieldProps("binanceAddress")?.value,
            };
          } else if (data.configKey === "binance_private_key") {
            return {
              ...data,
              configValue: formik.getFieldProps("binancePrivateKey")?.value,
            };
          } else if (data.configKey === "ethereum_address") {
            return {
              ...data,
              configValue: formik.getFieldProps("ethereumAddress")?.value,
            };
          } else if (data.configKey === "ethereum_private_key") {
            return {
              ...data,
              configValue: formik.getFieldProps("ethereumPrivateKey")?.value,
            };
          } else if (data.configKey === "tron_address") {
            return {
              ...data,
              configValue: formik.getFieldProps("trxAddress")?.value,
            };
          } else if (data.configKey === "tron_private_key") {
            return {
              ...data,
              configValue: formik.getFieldProps("tronPrivateKey")?.value,
            };
          }
        });
        submitConfigData(updatedData);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Box sx={styles.container}>
      <Card sx={styles.cardContainerStyle}>
        <CardHeader sx={styles.headerRow} title="Wallet Vault Config" />
        <CardContent sx={styles.paddingZero}>
          <Box sx={styles.cardPadding}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Binance Address</label>
                    <TextField
                      disabled={
                        authData?.result?.adminRole !== "superadmin"
                          ? true
                          : false
                      }
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("binanceAddress")}
                      error={Boolean(
                        touched.binanceAddress && errors.binanceAddress
                      )}
                      helperText={
                        touched.binanceAddress && errors.binanceAddress
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Binance Private Key</label>
                    <TextField
                      disabled={
                        authData?.result?.adminRole !== "superadmin"
                          ? true
                          : false
                      }
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("binancePrivateKey")}
                      error={Boolean(
                        touched.binancePrivateKey && errors.binancePrivateKey
                      )}
                      helperText={
                        touched.binancePrivateKey && errors.binancePrivateKey
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Ethereum Address</label>
                    <TextField
                      disabled={
                        authData?.result?.adminRole !== "superadmin"
                          ? true
                          : false
                      }
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("ethereumAddress")}
                      error={Boolean(
                        touched.ethereumAddress && errors.ethereumAddress
                      )}
                      helperText={
                        touched.ethereumAddress && errors.ethereumAddress
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Ethereum Private Key</label>
                    <TextField
                      disabled={
                        authData?.result?.adminRole !== "superadmin"
                          ? true
                          : false
                      }
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("ethereumPrivateKey")}
                      error={Boolean(
                        touched.ethereumPrivateKey && errors.ethereumPrivateKey
                      )}
                      helperText={
                        touched.ethereumPrivateKey && errors.ethereumPrivateKey
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Tron (TRX) Address</label>
                    <TextField
                      disabled={
                        authData?.result?.adminRole !== "superadmin"
                          ? true
                          : false
                      }
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("trxAddress")}
                      error={Boolean(touched.trxAddress && errors.trxAddress)}
                      helperText={touched.trxAddress && errors.trxAddress}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Tron Private Key</label>
                    <TextField
                      disabled={
                        authData?.result?.adminRole !== "superadmin"
                          ? true
                          : false
                      }
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("tronPrivateKey")}
                      error={Boolean(
                        touched.tronPrivateKey && errors.tronPrivateKey
                      )}
                      helperText={
                        touched.tronPrivateKey && errors.tronPrivateKey
                      }
                    />
                  </Grid>
                </Grid>
                <Stack direction="row" sx={styles.updateButton}>
                  <PrimaryButton title="Update" onClick={handleSubmit} />
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </CardContent>
      </Card>

      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};
export default WalletVault;
const styles = {
  container: {
    padding: "1em",
  },
  headerRow: {
    backgroundColor: "#171616",
    padding: "1em",
    "& .MuiCardHeader-title": {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#FFFFFF",
    },
  },
  labelText: {
    fontSize: "1rem",
    fontWeight: 350,
    color: "#FFFFFF",
    marginBottom: "0.5em",
  },
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
    padding: "0em",
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "2em 1em",
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      color: "#FFFFFF",
      backgroundColor: "#1C1C1C",
    },
  },
  actionRow: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "#171616",
    padding: "1em",
  },
  addNewButton: {
    backgroundColor: "rgba(20, 203, 27, 1)",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
    padding: "0.5em 1.5em",
    "&:hover": {
      transitions: "all 0.5s ease",
      backgroundColor: "rgba(20, 203, 27, 0.5)",
    },
  },
  textCenter: {
    textAlign: "center",
    backgroundColor: "transparent",
  },
  updateButton: {
    width: { sm: "100%", xs: "100%", md: "40%" },
    margin: "3em auto",
  },
};
