var CryptoJS = require("crypto-js");

export const aesEncrypt = (data: string) => {
  const apiEncryptionKey = process.env.REACT_APP_API_ENCRYPTION_KEY;
  const apiBase64IV = process.env.REACT_APP_BASE64_IV;
  const key = CryptoJS.enc.Base64.parse(apiEncryptionKey);
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: CryptoJS.enc.Utf8.parse(apiBase64IV),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const encryptedData = encrypted.toString();
  return encryptedData;
};

export const apiDecrypt = (dataCipher: any) => {
  try {
    const apiEncryptionKey = process.env.REACT_APP_API_ENCRYPTION_KEY;
    const apiBase64IV = process.env.REACT_APP_BASE64_IV;

    const key = CryptoJS.enc.Base64.parse(apiEncryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(apiBase64IV);
    const decrypted = CryptoJS.AES.decrypt(dataCipher, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (error) {
    console.log("Error: ", error);
    return null;
  }
};
