import React from "react";
import { Box, Stack, styled, Typography } from "@mui/material";
import DashboardNavbar from "./DashboardNavbar";
import { useState } from "react";
import DashboardSidebar from "./DashboardSidebar";
import { Outlet } from "react-router-dom";
import {
  APP_BAR_DESKTOP,
  APP_BAR_MOBILE,
  APP_COPYRIGHT,
  APP_VERSION,
} from "../../utils/AppConstants";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  height: window.innerHeight - APP_BAR_MOBILE + APP_BAR_MOBILE,
  paddingTop: APP_BAR_MOBILE + 20,
  paddingBottom: "1em",
  background: "#000000",
  color: "white",
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const DashboardLayout = () => {
  const [open, setOpen] = useState(false);
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          backgroundColor: "#000000",
        }}
      >
        <MainStyle>
          <Outlet />
        </MainStyle>
        <footer>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1em 3em",
            }}
          >
            <Typography
              sx={styles.footerText}
            >{`Version: ${APP_VERSION}`}</Typography>
            <Typography sx={styles.footerText}>{APP_COPYRIGHT}</Typography>
          </Stack>
        </footer>
      </Box>
    </RootStyle>
  );
};
export default DashboardLayout;

const styles = {
  footerText: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 0.6)",
  },
  footer: {
    backgroundColor: "#000000",
  },
};
