import * as Yup from "yup";

export const portRegex =
  /^(6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|\d{1,4})$/;
export const protocolRegex = /^(https?|ftp|ftps|smtp|sftp|ws|wss)$/;
export const mailProtocolRegex = /^(smtp|smtps|pop3|imap)$/;
export const apiKeyRegex = /^[A-Za-z0-9]{25,50}$/;
export const urlRegex =
  /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?(\?.*)?(#.*)?$/i;
export const blockChainAddress =
  /^(0x[a-fA-F0-9]{40}|[TS][a-zA-Z0-9]{33}|0x[a-zA-Z0-9]{40})$/;

export const LoginSchema = Yup.object().shape({
  email: Yup.string().required("Mail address is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    ),
});

export const ProfileUpdateSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  phone_number: Yup.string()
    .required("Phone no. is required")
    .matches(
      /^(\+?\d{1,4}[\s.-]?)?(\(?\d{3}\)?[\s.-]?)?[\d\s.-]{7,10}$/,
      "Phone number is not valid"
    ),
});

export const UpdateCurrencySchema = Yup.object().shape({
  cryptoName: Yup.string().required("Crypto name is required"),
  cryptoTicker: Yup.string().required("Crypto ticker is required"),
  cryptoBlockchain: Yup.string().required("Crypto Blockchain is required"),
  cryptoContractAddress: Yup.string().required(
    "Crypto Contract Address is required"
  ),
  cryptoIconUrl: Yup.string().required("Crypto Icon is required"),
  cryptoRpcUrl: Yup.string().required("Crypto RPC url is required"),
  cryptoExplorerUrl: Yup.string().required("Crypto Explorer url is required"),
  description: Yup.string().required("Description is required"),
});

export const isEmpty = (text: string | undefined) => {
  if (text === null || text === undefined || text === "") {
    return true;
  } else {
    return false;
  }
};

export const isNumber = (value: string | undefined | null) => {
  if (value === null || value === undefined || value === "") {
    return false;
  } else {
    const regex = /^-?\d+(\.\d+)?$/;
    return regex.test(value);
  }
};

export const isValidPassword = (text: string) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;
  return re.test(text);
};

export const AddAdminUserSchema = Yup.object().shape({
  email: Yup.string().required("Email address is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
});

export const aegTokenSpecsSchema = Yup.object().shape({
  goldPerPrice: Yup.string().required("Gold Per Price is required"),
  sellMarginFees: Yup.string().required("Sell Margin Fees is required"),
  sellMinimumQty: Yup.string().required("Sell Minimum Qty is required"),
  buyMax: Yup.string().required("Buy Max Qty is required"),
  goldPriceAed: Yup.string().required("Gold Price AED is required"),
  trnxFeesMultiplier: Yup.string().required(
    "Transaction Fees Multiplier is required"
  ),
});
export const emailSpecsSchema = Yup.object().shape({
  serverName: Yup.string()
    .required("SMTP server Name is required")
    .matches(protocolRegex, "SMTP server protocol is not valid"),
  hostName: Yup.string().required("SMTP host name is required"),
  portNumber: Yup.string()
    .required("SMTP port number is required")
    .matches(portRegex, "Port number is not valid"),
  userName: Yup.string()
    .required("SMTP user name is required")
    .min(6, "User name must be at least 6 characters"),
  password: Yup.string()
    .required("SMTP password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    ),
  senderAddress: Yup.string()
    .required("Sender email address is required")
    .email("Sender email address is not valid"),
  userSupportEmail: Yup.string()
    .required("User support email address is required")
    .email("User support email address is not valid"),
  devTeamEmail: Yup.string()
    .required("Dev tem email address is required")
    .email("Dev team email address is not valid"),
});

export const cryptoCurrencySchema = Yup.object().shape({
  bscEndpoint: Yup.string()
    .required("Binance Smart Chain Endpoint is required")
    .matches(urlRegex, "Binance Smart Chain Endpoint is not valid"),
  bscApiKey: Yup.string()
    .required("Binance Smart Chain API EndPoint is required")
    .matches(apiKeyRegex, "Binance Smart Chain API EndPoint is not valid"),
  ethEndpoint: Yup.string()
    .required("Ethereum EndPoint is required")
    .matches(urlRegex, "Ethereum Endpoint is not valid"),
  ethApiKey: Yup.string()
    .required("Ethereum API EndPoint is required")
    .matches(apiKeyRegex, "Ethereum API EndPoint is not valid"),
  trxEndpoint: Yup.string()
    .required("Tron EndPoint is required")
    .matches(urlRegex, "Tron EndPoint is not valid"),
});

export const goldRateApiSchema = Yup.object().shape({
  apiKey: Yup.string()
    .required("API Key is required")
    .matches(urlRegex, "API Key  is not valid"),
  apiEndPoint: Yup.string()
    .required("API EndPoint is required")
    .matches(urlRegex, "API Endpoint is not valid"),
});

export const walletVaultSchema = Yup.object().shape({
  binanceAddress: Yup.string().required(
    "Binance Smart Chain (BSC) address is required"
  ),
  binancePrivateKey: Yup.string().required(
    "Binance Smart Chain Private Key is required"
  ),
  ethereumAddress: Yup.string().required("Ethereum (ETH) address is required"),
  ethereumPrivateKey: Yup.string().required(
    "Ethereum API EndPoint is required"
  ),
  trxAddress: Yup.string().required("Tron (TRX) address is required"),
  tronPrivateKey: Yup.string().required("Tron Private Key is required"),
});

export const updatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
