import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  TextField,
  Select,
  InputAdornment,
  FormControl,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  SelectChangeEvent,
} from "@mui/material";
import styles from "./BankPage.styles";
import SearchIcon from "@mui/icons-material/Search";
import { getBankName, toPascalCase } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducers";
import { bankListRequest } from "../../store/reducers/BankReducer";
import { BankList } from "../../types/BankDetailModel";
import BankDetailsDialog from "../../components/BankDetailsDialog";
import { AlertDataProps } from "../../types/Common";
import AlertComponent from "../../components/AlertComponent";
import Loader from "../../components/Loader";

const BankPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const recordsPerPage = 10;
  const bank = useSelector((state: RootState) => state.bankState);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterStatus, setFilterStatus] = useState("");
  const [bankList, setBankList] = useState<BankList[]>([]);
  const [showBankDetails, setShowBankDetails] = useState<BankList | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  const requestBank = (currentPage?: number) => {
    const queryString = [];
    if (filterStatus) {
      const query = `${encodeURIComponent("status")}=${encodeURIComponent(
        filterStatus
      )}`;
      queryString.push(query);
    }
    const query = `${encodeURIComponent("page")}=${encodeURIComponent(
      currentPage ? currentPage : page
    )}&${encodeURIComponent("limit")}=${encodeURIComponent(recordsPerPage)}`;
    queryString.push(query);

    if (
      searchInputRef?.current?.value &&
      searchInputRef?.current?.value?.length > 0
    ) {
      const query = `${encodeURIComponent("filter")}=${encodeURIComponent(
        searchInputRef?.current?.value
      )}`;
      queryString.push(query);
    }

    const strJoin = queryString.join("&");
    console.log("StrJoin: ", strJoin);

    dispatch(bankListRequest(strJoin));
  };

  useEffect(() => {
    requestBank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (bank.result?.banksList && bank.result?.banksList.length > 0) {
      setBankList(bank.result?.banksList);
      setTotalPages(bank.result?.totalPages);
      setTotalRecords(bank.result?.totalCount);
    } else {
      setBankList([]);
    }
  }, [bank.result]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setFilterStatus(event.target.value);
  };

  useEffect(() => {
    if (filterStatus) {
      requestBank(1);
    } else {
      requestBank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (
      searchInputRef?.current?.value &&
      searchInputRef?.current?.value.length > 0
    ) {
      requestBank(value);
    } else {
      requestBank(value);
      setPage(value);
    }
  };

  const searchUsers = (searchQuery: string) => {
    if (
      searchQuery === "" &&
      bank.result?.banksList &&
      bank.result?.banksList.length > 0
    ) {
      setPage(1);
      requestBank(1);
      return;
    }

    const queryString = [];
    if (filterStatus) {
      const query = `${encodeURIComponent("status")}=${encodeURIComponent(
        filterStatus
      )}`;
      queryString.push(query);
    }

    const query = `${encodeURIComponent("page")}=${encodeURIComponent(
      1
    )}&${encodeURIComponent("limit")}=${encodeURIComponent(
      recordsPerPage
    )}&${encodeURIComponent("filter")}=${encodeURIComponent(searchQuery)}`;
    queryString.push(query);
    const strJoin = queryString.join("&");
    dispatch(bankListRequest(strJoin));
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      setTimeout(() => {
        searchUsers(value);
      }, 1500);
    } else {
      if (bank.result?.banksList && bank.result?.banksList.length > 0) {
        requestBank(1);
      }
    }
  };

  const handleRowClick = useCallback((bank: BankList) => {
    setShowBankDetails(bank);
  }, []);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Pending":
        return styles.pendingRow;
      case "Verified":
      case "active":
        return styles.activeRow;
      case "Rejected":
      case "inactive":
        return styles.deActiveRow;
      default:
        return styles.activeRow;
    }
  };

  return (
    <>
      <Card sx={styles.cardContainerStyle}>
        <CardContent sx={styles.paddingZero}>
          <Stack direction="row" sx={styles.filterContainer}>
            <TextField
              placeholder="Search"
              size="small"
              inputRef={searchInputRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "white" }} />
                  </InputAdornment>
                ),
                style: { color: "white", borderColor: "white" },
              }}
              variant="outlined"
              onChange={handleSearchChange}
              sx={styles.searchInputStyle}
            />
            <FormControl variant="outlined" sx={{ minWidth: 160 }}>
              <Select
                value={filterStatus}
                onChange={handleStatusChange}
                displayEmpty
                sx={{ color: "white", borderColor: "white" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>Choose Status</em>
                </MenuItem>
                <MenuItem value="Verified">Verified</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={styles.tableTitleRowBorder}>
                  <TableCell>#</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Bank Name</TableCell>
                  <TableCell>Account No.</TableCell>
                  <TableCell>Swift Code</TableCell>
                  <TableCell>IBAN</TableCell>
                  <TableCell>Verification Status</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bank?.loading ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box sx={styles.loadingRow}>
                        <Loader />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : bankList && bankList.length > 0 ? (
                  bankList?.map((row: BankList, index: number) => (
                    <TableRow key={index} onClick={() => handleRowClick(row)}>
                      <TableCell align="center">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.bankNameStyle}>
                          {`${row.firstName ? row.firstName : "Anonymous"} ${
                            row.lastName ? row.lastName : "User"
                          }`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box sx={styles.bankDetailContainer}>
                            <Box sx={styles.bankNameLogoContainer}>
                              <Box sx={styles.bankLogoContainer}>
                                <Box sx={styles.bankLogoGlass}>
                                  <Typography sx={styles.bankLogoText}>
                                    {getBankName(row.bankName)}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={styles.bankNameContainer}>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  gap={1}
                                >
                                  <Typography sx={styles.bankNameStyle}>
                                    {row.bankName}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Box>
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {row.accountNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {row.swiftBic}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {row.iban}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.centerText}>
                        <Typography sx={getStatusStyle(row.verificationStatus)}>
                          {toPascalCase(row.verificationStatus)}
                        </Typography>
                      </TableCell>

                      <TableCell sx={styles.centerText}>
                        <Typography sx={getStatusStyle(row.status)}>
                          {toPascalCase(row.status)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography sx={styles.sorryText}>Sorry!!</Typography>
                      <Typography sx={styles.noDataRow}>
                        No Data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
            px={3}
            mt={2}
          >
            <Typography
              sx={styles.totalRecordsStyle}
            >{`Total Records: ${totalRecords}`}</Typography>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              sx={{
                button: { color: "white" },
                ul: { justifyContent: "center" },
              }}
            />
          </Box>
        </CardContent>
        {showBankDetails !== null && (
          <BankDetailsDialog
            open={showBankDetails !== null}
            bankDetails={showBankDetails}
            handleClose={() => setShowBankDetails(null)}
            onError={(error) => {
              setShowBankDetails(null);
              setAlertData({ message: error, type: "error" });
            }}
            onSuccess={(message) => {
              setShowBankDetails(null);
              requestBank(1);
              setAlertData({ message: message, type: "success" });
            }}
          />
        )}
      </Card>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </>
  );
};

export default BankPage;
