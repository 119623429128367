import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdminUserData,
  AdminUserDetailData,
  AdminUserDetailResponse,
  AdminUserResponse,
} from "../../types/AdminUserModel";

interface AdminUserState {
  result: AdminUserData[] | null;
  details: AdminUserDetailData | null;
  loading: boolean;
  error: string | null;
}

const initialState: AdminUserState = {
  result: null,
  details: null,
  loading: false,
  error: null,
};

const adminUserSlice = createSlice({
  name: "adminUsers",
  initialState,
  reducers: {
    adminUsersRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    adminUsersDetailsRequest(state, action: PayloadAction<number>) {
      state.loading = true;
      state.error = null;
    },
    adminUsersSuccess(state, action: PayloadAction<AdminUserResponse>) {
      state.loading = false;
      state.result = action.payload.data;
    },
    adminUsersDetailsSuccess(
      state,
      action: PayloadAction<AdminUserDetailResponse>
    ) {
      state.loading = false;
      state.details = action.payload.data;
    },
    adminUsersFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
    adminUsersDetailsFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  adminUsersRequest,
  adminUsersSuccess,
  adminUsersFailure,
  adminUsersDetailsRequest,
  adminUsersDetailsSuccess,
  adminUsersDetailsFailure,
} = adminUserSlice.actions;

export default adminUserSlice.reducer;
