import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SellData, SellResponse } from "../../types/SellModel";

interface SellState {
  result: SellData | null;
  loading: boolean;
  error: string | null;
}

const initialState: SellState = {
  result: null,
  loading: false,
  error: null,
};

const sellSlice = createSlice({
  name: "sell",
  initialState,
  reducers: {
    sellRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },

    sellSuccess(state, action: PayloadAction<SellResponse>) {
      state.loading = false;
      state.result = action.payload.data;
    },
    sellFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { sellRequest, sellSuccess, sellFailure } = sellSlice.actions;

export default sellSlice.reducer;
