import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  UserWalletData,
  UserWalletResponse,
} from "../../types/UserWalletModel";

interface UserWalletState {
  result: UserWalletData | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserWalletState = {
  result: null,
  loading: false,
  error: null,
};

const userWalletSlice = createSlice({
  name: "userWallet",
  initialState,
  reducers: {
    userWalletRequest(state, action: PayloadAction<number>) {
      state.loading = true;
      state.error = null;
    },
    userWalletSuccess(state, action: PayloadAction<UserWalletResponse>) {
      state.loading = false;
      state.result = action.payload.data;
    },
    userWalletFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { userWalletRequest, userWalletSuccess, userWalletFailure } =
  userWalletSlice.actions;

export default userWalletSlice.reducer;
