import axios from "axios";
import { STORE_KEY } from "../utils/AppConstants";
const apiURL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;
// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: apiURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["x-api-key"] = apiKey;
    config.headers["x-source"] = "admin-web";
    const sessionData = localStorage.getItem(STORE_KEY.SESSION_DATA);
    if (sessionData !== null && sessionData !== "null") {
      const data = JSON.parse(sessionData);
      config.headers.Authorization = `Bearer ${data.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (
        error?.response?.data?.responseCode === 101201 ||
        error?.response?.data?.responseCode === 101202 ||
        error?.response?.data?.responseCode === 101303 ||
        error?.response?.data?.responseCode === 101401
      ) {
        localStorage.clear();
        window.location.replace("/");
      }
    }
    if (axios.isAxiosError(error)) {
      return error;
    }
    // console.log(
    //   "interceptor 🚫",
    //   `${error?.config?.url}\nSTATUS CODE: ${
    //     error?.response?.status ? error?.response?.status : ""
    //   }\nDATA: ${JSON.stringify(error?.response?.data)}`
    // );
    return error?.response ? error.response : error;
  }
);

export const setAuthToken = (token: string) => {
  if (token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export default axiosInstance;
