import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardHeader,
} from "@mui/material";
import ActiveUsersIcon from "../../assets/svg/ActiveUsersIcon";
import TotalPurchaseIcon from "../../assets/svg/TotalPurchaseIcon";
import LogoBlackAndWhite from "../../assets/svg/LogoBlackAndWhite";
import RedeemIcon from "../../assets/svg/RedeemIcon";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dashboardRequest } from "../../store/reducers/DashboardReducer";
import { RootState } from "../../store/reducers/RootReducers";
import { formatNumber, zeroNumber } from "../../utils/Utils";
import styles from "./DashboardPage.styles";
import StatisticsCard from "./components/StatisticsCard";
import AEGIcon from "../../assets/svg/AEGIcon";
import { getTransactionListDateTimeFormat } from "../../utils/DateUtils";
import RedeemDialog from "../../components/RedeemDialog";
import { AlertDataProps } from "../../types/Common";
import AlertComponent from "../../components/AlertComponent";
import { RedeemData, SellData } from "../../types/DashboardResponse";
import SellDialog from "../../components/SellDialog";
import { profileRequest } from "../../store/reducers/ProfileReducer";

const DashboardPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dashboardData = useSelector((state: RootState) => state.dashboardState);
  const [redeemRow, setRedeemRow] = useState<RedeemData | null>(null);
  const [sellRow, setSellRow] = useState<SellData | null>(null);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    dispatch(dashboardRequest());
    dispatch(profileRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const SellTable = () => {
    return (
      <Card sx={styles.cardContainerStyle}>
        <CardHeader title="Sell Requests" sx={styles.headerRow} />
        <CardContent sx={styles.cardPadding}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={styles.tableTitleRowBorder}>
                  <TableCell>#</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>User name</TableCell>
                  <TableCell>AEG Qty</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardData?.result?.sellData &&
                dashboardData?.result?.sellData.length > 0 ? (
                  dashboardData?.result?.sellData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={styles.rowBorder}
                      onClick={() => setSellRow(row)}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <AEGIcon />
                      </TableCell>
                      <TableCell>{row.transactionId}</TableCell>
                      <TableCell>
                        {row.userName
                          ? row.userName?.includes("null")
                            ? "Anonymous User"
                            : row.userName
                          : "Anonymous User"}
                      </TableCell>
                      <TableCell>{`${formatNumber(
                        row.txValue
                      )} AEG`}</TableCell>
                      <TableCell>
                        <Typography
                          sx={[
                            styles.normalText,
                            {
                              color:
                                row.status === "Pending"
                                  ? "#DFB23F"
                                  : "#CB1F14",
                            },
                          ]}
                        >
                          {row.status}
                        </Typography>
                      </TableCell>

                      <TableCell sx={styles.centerText}>
                        {getTransactionListDateTimeFormat(row.createdAt)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography sx={styles.sorryText}>Sorry!!</Typography>
                      <Typography sx={styles.noDataRow}>
                        No Sell Data Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  };

  const RedeemTable = () => {
    return (
      <Card sx={styles.cardContainerStyle}>
        <CardHeader title="Redeem Requests" sx={styles.headerRow} />
        <CardContent sx={styles.cardPadding}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={styles.tableTitleRowBorder}>
                  <TableCell>#</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>User name</TableCell>
                  <TableCell>AEG Qty</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardData?.result?.redeemData &&
                dashboardData?.result?.redeemData.length > 0 ? (
                  dashboardData?.result?.redeemData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={styles.rowBorder}
                      onClick={() => setRedeemRow(row)}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <AEGIcon />
                      </TableCell>
                      <TableCell>{row.transactionId}</TableCell>
                      <TableCell>
                        {row.userName
                          ? row.userName?.includes("null")
                            ? "Anonymous User"
                            : row.userName
                          : "Anonymous User"}
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.boldText}>
                          {`${formatNumber(row.txValue)} AEG`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={[
                            styles.normalText,
                            {
                              color:
                                row.status === "Pending"
                                  ? "#DFB23F"
                                  : "#CB1F14",
                            },
                          ]}
                        >
                          {row.status}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {getTransactionListDateTimeFormat(row.createdAt)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography sx={styles.sorryText}>Sorry!!</Typography>
                      <Typography sx={styles.noDataRow}>
                        No Redeem Data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={styles.cardContainer}>
            <Box sx={{ flexGrow: 1, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <StatisticsCard
                    icon={<ActiveUsersIcon width={42} height={42} />}
                    title="Active Users"
                    currency=" "
                    value={
                      dashboardData?.result?.activeUser
                        ? dashboardData?.result?.activeUser.toString()
                        : "0"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatisticsCard
                    icon={<TotalPurchaseIcon width={42} height={42} />}
                    title="Total Purchase"
                    currency="AED"
                    value={
                      dashboardData?.result?.totalPurchase
                        ? dashboardData?.result?.totalPurchase.toString()
                        : zeroNumber()
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatisticsCard
                    icon={<LogoBlackAndWhite />}
                    title="Total AEG Holdings"
                    currency="AED"
                    value={
                      dashboardData?.result?.totalAegHold
                        ? dashboardData?.result?.totalAegHold.toString()
                        : zeroNumber()
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatisticsCard
                    icon={<RedeemIcon width={52} height={52} />}
                    title="Redeem Completed"
                    currency="AED"
                    value={
                      dashboardData?.result?.redeemCompleted
                        ? dashboardData?.result?.redeemCompleted.toString()
                        : zeroNumber()
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>

        {/* Redeem */}
        <Grid item xs={12} md={12}>
          <RedeemTable />
        </Grid>

        {/* Sell Request  */}
        <Grid item xs={12} md={12}>
          <SellTable />
        </Grid>
      </Grid>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
      {redeemRow !== null && (
        <RedeemDialog
          open={redeemRow !== null ? true : false}
          data={redeemRow}
          onClose={() => {
            dispatch(dashboardRequest());
            setRedeemRow(null);
          }}
          onError={(error: string) => {
            setAlertData({
              title: "Redeem Error",
              message: error,
              type: "error",
            });
            dispatch(dashboardRequest());
            setRedeemRow(null);
          }}
        />
      )}
      {sellRow !== null && (
        <SellDialog
          open={sellRow !== null ? true : false}
          data={sellRow}
          onClose={() => {
            dispatch(dashboardRequest());
            setSellRow(null);
          }}
          onError={(error: string) => {
            setAlertData({ message: error, type: "error" });
            dispatch(dashboardRequest());
            setSellRow(null);
          }}
          onSuccess={(message: string) => {
            setAlertData({ message: message, type: "success" });
            dispatch(dashboardRequest());
            setSellRow(null);
            //requestSell();
          }}
        />
      )}
    </Box>
  );
};

export default DashboardPage;
