// AlertComponent.jsx
import React from "react";
import { Snackbar, Alert, Typography, AlertTitle } from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  message: string;
  title?: string;
  type?: "error" | "info" | "success" | "warning";
};

const AlertComponent = (props: Props) => {
  const { open, onClose, message, type = "info", title = "" } = props;
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
      <Alert
        variant="filled"
        onClose={onClose}
        severity={type}
        sx={{
          width: "100%",
          backgroundColor: "gold", // Set the background to golden
          color: "black", // Optional: set the text color to ensure readability
        }}
      >
        {title ? <AlertTitle>{title}</AlertTitle> : <></>}
        <Typography sx={{ fontWeight: 400 }}>{message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default AlertComponent;
