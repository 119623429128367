import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const TransactionsAdminIcon = (props: Props) => {
  const { width = 24, height = 24 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none">
      <path
        d="M26.6667 4H10.3333C7.11683 4 4.5 6.61683 4.5 9.83333V26.1667C4.5 29.3832 7.11683 32 10.3333 32H26.6667C29.8832 32 32.5 29.3832 32.5 26.1667V9.83333C32.5 6.61683 29.8832 4 26.6667 4ZM17.065 16.5218L15 14.3122V23.8333C15 24.4773 14.4785 25 13.8333 25C13.1882 25 12.6667 24.4773 12.6667 23.8333V14.2585L10.4337 16.4915C9.9775 16.9477 9.24017 16.9477 8.784 16.4915C8.32783 16.0353 8.32783 15.298 8.784 14.8418L11.7718 11.854C12.9093 10.7165 14.7597 10.7165 15.896 11.854L18.7695 14.9293C19.2093 15.3995 19.1837 16.138 18.7135 16.579C18.4895 16.789 18.2025 16.8928 17.9167 16.8928C17.604 16.8928 17.2937 16.7692 17.065 16.523V16.5218ZM28.2172 21.1582L25.2293 24.146C24.6612 24.7142 23.9145 24.9988 23.1667 24.9988C22.4188 24.9988 21.6733 24.7142 21.104 24.146L18.1162 21.1582C17.66 20.702 17.66 19.9647 18.1162 19.5085C18.5723 19.0523 19.3097 19.0523 19.7658 19.5085L21.9988 21.7415V12.1667C21.9988 11.5227 22.5203 11 23.1655 11C23.8107 11 24.3322 11.5227 24.3322 12.1667V21.7415L26.5652 19.5085C27.0213 19.0523 27.7587 19.0523 28.2148 19.5085C28.671 19.9647 28.671 20.702 28.2148 21.1582H28.2172Z"
        fill="currentColor"
      />
      <clipPath id="clip0_1783_32788">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </svg>
  );
};
export default TransactionsAdminIcon;
