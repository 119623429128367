type Props = {
  width?: number;
  height?: number;
};
const TrendingDownIcon = (props: Props) => {
  const { width = 16, height = 11 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4146 10.2991C10.5259 10.5891 10.8513 10.734 11.1413 10.6226L15.5972 8.91216C15.7365 8.8587 15.8489 8.7521 15.9095 8.61581C15.9702 8.47953 15.9743 8.32472 15.9208 8.18544L14.2103 3.72949C14.099 3.43946 13.7736 3.2946 13.4836 3.40593C13.1936 3.51726 13.0487 3.84263 13.16 4.13265L14.3839 7.32081L13.569 6.958C11.7291 6.13882 10.3277 4.74322 9.48127 3.07784C9.39964 2.91724 9.24602 2.80545 9.0681 2.77718C8.89017 2.7489 8.70946 2.80757 8.58207 2.93496L5.75 5.76703L1.08525 1.10228C0.865578 0.88261 0.509422 0.88261 0.289752 1.10228C0.0700825 1.32195 0.0700825 1.6781 0.289752 1.89777L5.35225 6.96027C5.45774 7.06576 5.60082 7.12503 5.75 7.12503C5.89918 7.12503 6.04226 7.06576 6.14775 6.96027L8.85225 4.25577C9.82771 5.84661 11.2783 7.16959 13.1114 7.98574L13.9263 8.34855L10.7381 9.57237C10.4481 9.6837 10.3032 10.0091 10.4146 10.2991Z"
        fill="#FF0000"
      />
    </svg>
  );
};
export default TrendingDownIcon;
