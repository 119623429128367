import React from "react";
const EyeShowIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M2.03575 12.3224C1.96666 12.1151 1.9666 11.8907 2.03556 11.6834C3.42392 7.50972 7.36098 4.5 12.001 4.5C16.6389 4.5 20.5744 7.50692 21.9645 11.6776C22.0336 11.8849 22.0337 12.1093 21.9647 12.3166C20.5763 16.4903 16.6393 19.5 11.9993 19.5C7.36139 19.5 3.42583 16.4931 2.03575 12.3224Z"
        stroke="url(#paint0_linear_2598_345)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0002 12C15.0002 13.6569 13.6571 15 12.0002 15C10.3433 15 9.0002 13.6569 9.0002 12C9.0002 10.3431 10.3433 9 12.0002 9C13.6571 9 15.0002 10.3431 15.0002 12Z"
        stroke="url(#paint1_linear_2598_345)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2598_345"
          x1="1.98389"
          y1="12"
          x2="22.0164"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF601" />
          <stop offset="1" stopColor="#F1B20F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2598_345"
          x1="1.98389"
          y1="12"
          x2="22.0164"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF601" />
          <stop offset="1" stopColor="#F1B20F" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default EyeShowIcon;
