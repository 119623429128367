import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import Iconify from "../../components/icons/Iconify";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducers";
import { toPascalCase } from "../../utils/Utils";
import { ProfileIcon } from "../../assets";

const DRAWER_WIDTH = 240;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 70;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 1),
  paddingTop: "0.5em",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    height: APP_BAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const DashboardNavbar = ({ onOpenSidebar }: { onOpenSidebar: any }) => {
  const location = useLocation();
  const profileData = useSelector((state: RootState) => state.profileState);

  const AppTitle = useMemo(() => {
    switch (location.pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/dashboard/user-list":
        return "Users";
      case "/dashboard/redeem-request":
        return "Redeem Requests";
      case "/dashboard/sell-request":
        return "Sell Requests";
      case "/dashboard/transactions":
        return "Transactions";
      case "/dashboard/bank-list":
        return "Bank List";
      case "/dashboard/currency-list":
        return "Currency List";
      case "/dashboard/settings":
        return "Settings";
      case "/dashboard/profile":
        return "Profile";
      case "/dashboard/admin-user-list":
        return "Admin Users";
      default:
        if (location.pathname.includes("admin-user-list/user")) {
          return "Admin User Details";
        } else if (location.pathname.includes("user-list/user")) {
          return "User Details";
        }
        return " hi 👋, Welcome";
    }
  }, [location.pathname]);

  return (
    <RootStyle
      style={{
        backgroundColor: "#000000",
        position: "absolute",
        top: 0,
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
      }}
    >
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            color: "text.primary",
            display: { lg: "none" },
            mr: { lg: 0, md: 0, sm: 1, xs: 1 },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={styles.titleText}>{AppTitle}</Typography>
          <Box sx={styles.profileRow}>
            <Stack alignItems={"flex-end"}>
              <Typography
                sx={{ color: "#FFFFFF", fontSize: "1rem", fontWeight: 700 }}
              >
                {`${
                  profileData.result?.firstName
                    ? toPascalCase(profileData.result?.firstName)
                    : ""
                } ${
                  profileData.result?.lastName
                    ? toPascalCase(profileData.result?.lastName)
                    : ""
                }`}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  textTransform: "capitalize",
                }}
              >
                {profileData.result?.AdminRole}
              </Typography>
            </Stack>

            <Box sx={styles.profilePicture}>
              {profileData.result?.profilePic ? (
                <img
                  src={profileData.result?.profilePic}
                  style={styles.profilePictureImg}
                  alt={"ProfilePicture"}
                />
              ) : (
                <ProfileIcon />
              )}
            </Box>
          </Box>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
};
export default DashboardNavbar;

const styles = {
  titleText: { fontSize: "1.3rem", color: "#FFFFFF", fontWeight: 700 },
  profileRow: {
    display: "flex",
    flexDirection: "Row",
    alignItems: "center",
  },
  profilePicture: {
    borderRadius: "4em",
    border: "0.5px dashed rgba(255, 200, 0, 1)",
    height: "4.1em",
    maxHeight: "4.1em",
    minHeight: "4.1em",
    maxWidth: "4.1em",
    minWidth: "4.1em",
    width: "4.1em",
    marginRight: "0.5em",
    marginLeft: "0.5em",
  },
  profilePictureImg: {
    height: "4em",
    maxHeight: "4em",
    minHeight: "4em",
    maxWidth: "4em",
    minWidth: "4em",
    width: "4em",
    borderRadius: "4em",
    padding: "5px",
  },
};
