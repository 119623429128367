import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  configGroupFailure,
  configGroupRequest,
  configGroupSuccess,
} from "../reducers/AEGConfigGroupReducer";
import {
  AEGConfigEncryptResponse,
  AEGConfigResponse,
} from "../../types/AEGConfigModel";
import { apiDecrypt } from "../../utils/Encryption";

function* requestConfigGroupSaga(action: PayloadAction<string>): any {
  try {
    const response = yield call(
      axiosInstance.get,
      `/config-group/${action.payload}`
    );
    if (response.status === 200) {
      if (response?.data && response?.data?.dataCipher) {
        const data: AEGConfigEncryptResponse = response.data;
        const plainText: AEGConfigResponse = JSON.parse(
          apiDecrypt(data.dataCipher)
        );
        yield put(configGroupSuccess(plainText));
      } else {
        const data: AEGConfigResponse = response.data;
        yield put(configGroupSuccess(data));
      }
    } else {
      yield put(configGroupFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      configGroupFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchConfigGroupSaga() {
  yield takeLatest(configGroupRequest.type, requestConfigGroupSaga);
}

export default watchFetchConfigGroupSaga;
