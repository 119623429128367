import { all } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./reducers/RootReducers";
import watchFetchAuthSaga from "./saga/authSaga";
import watchFetchDashboardSaga from "./saga/dashboardSaga";
import watchFetchUsersSaga from "./saga/usersSaga";
import watchFetchUserDetailSaga from "./saga/userDetailSaga";
import watchFetchUserWalletSaga from "./saga/userWalletSaga";
import watchFetchBankSaga from "./saga/bankSaga";
import watchFetchRedeemSaga from "./saga/redeemSaga";
import watchFetchSellSaga from "./saga/sellSaga";
import watchFetchTransactionSaga from "./saga/transactionSaga";
import watchFetchCurrencySaga from "./saga/currencySaga";
import watchFetchConfigGroupSaga from "./saga/aegConfigGroupSaga";
import watchFetchAdminUsersSaga from "./saga/adminUserSaga";
import watchFetchProfileSaga from "./saga/ProfileSaga";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

function* rootSaga() {
  yield all([
    watchFetchAuthSaga(),
    watchFetchDashboardSaga(),
    watchFetchUsersSaga(),
    watchFetchUserDetailSaga(),
    watchFetchUserWalletSaga(),
    watchFetchBankSaga(),
    watchFetchRedeemSaga(),
    watchFetchSellSaga(),
    watchFetchTransactionSaga(),
    watchFetchCurrencySaga(),
    watchFetchConfigGroupSaga(),
    watchFetchAdminUsersSaga(),
    watchFetchProfileSaga(),
  ]);
}

sagaMiddleware.run(rootSaga);
export type AppDispatch = typeof store.dispatch;
export default store;
