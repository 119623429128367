import React from "react";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";

type Props = {
  icon: string;
  sx?: any;
  other?: any;
};

const Iconify = (props: Props) => {
  const { icon, sx, other } = props;
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
};
export default Iconify;
