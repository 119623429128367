import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    position: "relative",
    width: "60px",
    height: "20px",
    borderRadius: "25px",
    backgroundColor: "#0000",
    border: "3px solid #6c4b04",
  },
  loaderBall: {
    position: "absolute",
    width: "12px",
    top: 1,
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "#ffd700",
    animation: "$moveBall 1s infinite",
  },
  "@keyframes moveBall": {
    "0%, 100%": {
      left: "1px",
    },
    "50%": {
      left: "41px",
    },
  },
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loaderContainer}>
      <Box className={classes.loader}>
        <Box className={classes.loaderBall}></Box>
      </Box>
    </Box>
  );
};

export default Loader;
