import {
  RedeemApproveRequest,
  RedeemRejectedRequest,
} from "../types/RedeemListModel";
import axiosInstance from "./config";
import { SellApproveRequest, SellRejectedRequest } from "../types/SellModel";
import { UpdateProfileRequest } from "../types/UpdateProfile";
import { AEGConfig } from "../types/AEGConfigModel";
import { AdminUpdatePasswordRequest } from "../types/AdminUpdatePasswordModel";
import { ProfileImageUploadRequest } from "../types/ProfileImageModel";
import { UpdateUserStatusRequest } from "../types/UpdateUserStatus";
import { UpdateUserPasswordRequest } from "../types/UsersModel";
import { aesEncrypt } from "../utils/Encryption";

export const requestRedeemApproveApi = async (
  transactionId: number,
  redeemRequest: RedeemApproveRequest
) => {
  try {
    const response = await axiosInstance.patch(
      `/redeem/approve/${transactionId}`,
      redeemRequest
    );
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestRedeemRejectedApi = async (
  transactionId: number,
  redeemRequest: RedeemRejectedRequest
) => {
  try {
    const response = await axiosInstance.patch(
      `/redeem/reject/${transactionId}`,
      redeemRequest
    );
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestSellApproveApi = async (
  transactionId: number,
  sellRequest: SellApproveRequest
) => {
  try {
    const response = await axiosInstance.patch(
      `/sell/approve/${transactionId}`,
      sellRequest
    );
    return response;
  } catch (error: any) {
    throw new Error("Sell Approval error: ", error);
  }
};

export const requestSellRejectedApi = async (
  transactionId: number,
  sellRequest: SellRejectedRequest
) => {
  try {
    const response = await axiosInstance.patch(
      `/sell/reject/${transactionId}`,
      sellRequest
    );
    console.log("Reject Response: ", response);

    return response;
  } catch (error: any) {
    throw new Error("Sell Rejection error: ", error);
  }
};

export const requestGetTransactionDetails = async (transactionId: number) => {
  try {
    const response = await axiosInstance.get(`/transaction/${transactionId}`);
    return response;
  } catch (error: any) {
    throw new Error("Transaction error: ", error);
  }
};

export const requestUpdateProfile = async (
  userId: number,
  body: UpdateProfileRequest
) => {
  try {
    const response = await axiosInstance.patch(`/user/${userId}`, body);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestConfigGroupUpdate = async (
  path: string,
  body: AEGConfig[]
) => {
  try {
    console.log({ path, body });

    const encryptText = aesEncrypt(JSON.stringify(body));
    const encryptBody = {
      dataCipher: encryptText,
    };
    console.log("Encrypt Body: ", encryptBody);

    const response = await axiosInstance.patch(
      `/config-group/${path}`,
      encryptBody
    );
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestUserDetails = async (path: number) => {
  try {
    const response = await axiosInstance.get(`/user/${path}`);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestAdminUpdatePassword = async (
  adminId: number,
  body: AdminUpdatePasswordRequest
) => {
  try {
    const response = await axiosInstance.post(
      `/admin-user/update-password/${adminId}`,
      body
    );
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestUpdateProfilePassword = async (
  body: AdminUpdatePasswordRequest
) => {
  try {
    const response = await axiosInstance.post("/update-password", body);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestApproveBankApi = async (bankId: number) => {
  try {
    const response = await axiosInstance.patch(`/banks/approve/${bankId}`);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestRejectBankApi = async (bankId: number, body: any) => {
  try {
    const response = await axiosInstance.patch(`/banks/reject/${bankId}`, body);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestSellApi = async (orderId: number) => {
  try {
    const response = await axiosInstance.get(`/sell/${orderId}`);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestRedeemApi = async (orderId: number) => {
  try {
    const response = await axiosInstance.get(`/redeem/${orderId}`);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestUpdateCurrencyApi = async (cryptoId: number, body: any) => {
  try {
    const response = await axiosInstance.patch(`/currency/${cryptoId}`, body);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestAddAdminApi = async (body: any) => {
  try {
    const response = await axiosInstance.post("/admin-user", body);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const requestUpdateAdminApi = async (adminId: number, body: any) => {
  try {
    const response = await axiosInstance.patch(`/admin-user/${adminId}`, body);
    return response;
  } catch (error: any) {
    throw new Error("Request redeem error: ", error);
  }
};

export const uploadProfileImage = async (
  request: ProfileImageUploadRequest
) => {
  try {
    const response = await axiosInstance.post(
      "/profile/profile-image",
      request
    );
    return response;
  } catch (error) {
    throw new Error(
      "Setting bank as primary bank failed. Please check your credentials."
    );
  }
};

export const updateUserStatus = async (request: UpdateUserStatusRequest) => {
  try {
    const response = await axiosInstance.patch("/update-user-status", request);
    return response;
  } catch (error) {
    throw new Error(
      "Updating user status failed. Please check your credentials."
    );
  }
};

export const promoteAsAdmin = async (userId: number) => {
  try {
    const response = await axiosInstance.patch(`/user/assign-admin/${userId}`);
    return response;
  } catch (error) {
    throw new Error(
      "Promoting user as admin failed. Please check your credentials."
    );
  }
};

export const promoteAsUser = async (userId: number) => {
  try {
    const response = await axiosInstance.delete(`/admin-user/${userId}`);
    return response;
  } catch (error) {
    throw new Error(
      "Promoting user as admin failed. Please check your credentials."
    );
  }
};

export const updateUserPassword = async (
  userId: number,
  body: UpdateUserPasswordRequest
) => {
  try {
    const response = await axiosInstance.post(
      `/user/update-password/${userId}`,
      body
    );
    return response;
  } catch (error) {
    throw new Error(
      "Promoting user as admin failed. Please check your credentials."
    );
  }
};
