import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const PhoneIcon = (props: Props) => {
  const { width = 38, height = 38 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 39 38" fill="none">
      <circle cx="19.9204" cy="19" r="19" fill="#333333" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2329 12.9375C11.2329 11.4878 12.4082 10.3125 13.8579 10.3125H15.0581C15.8109 10.3125 16.4671 10.8249 16.6497 11.5552L17.6173 15.4255C17.7774 16.0659 17.5381 16.7399 17.01 17.1359L15.8781 17.9848C15.7606 18.073 15.735 18.2021 15.768 18.2922C16.7616 21.0046 18.9159 23.1588 21.6282 24.1524C21.7183 24.1854 21.8474 24.1598 21.9356 24.0423L22.7845 22.9104C23.1806 22.3823 23.8545 22.143 24.4949 22.3031L28.3652 23.2707C29.0955 23.4533 29.6079 24.1095 29.6079 24.8623V26.0625C29.6079 27.5122 28.4327 28.6875 26.9829 28.6875H25.0142C17.403 28.6875 11.2329 22.5174 11.2329 14.9062V12.9375Z"
        fill="white"
      />
    </svg>
  );
};
export default PhoneIcon;
