import { BankBg } from "../../assets";
import { COLOR } from "../../theme";

const styles = {
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
  },
  backButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: COLOR.background,
    width: "5em",
    height: "2em",
    justifyContent: "center",
    borderRadius: "2em",
    marginBottom: "1em",
    ":hover": {
      cursor: "pointer",
    },
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "1em",
  },
  tableContainer: {
    paddingLeft: "0em",
    paddingRight: "0em",
    marginTop: "1em",
    height: "60vh",
  },
  tableWalletContainer: {
    paddingLeft: "0em",
    paddingRight: "0em",
    marginTop: "1em",
    height: "38vh",
  },
  customRows: {
    color: "white",
    fontSize: 13,
    textAlign: "center",
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      textAlign: "center",
      color: "#FFFFFF",
      backgroundColor: COLOR.background,
    },
  },
  loadingData: {
    margin: "3em auto",
  },
  noDataRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: COLOR.offWhite,
    marginBottom: "6em",
  },
  sorryText: {
    fontSize: "1.8rem",
    fontWeight: 700,
    color: "#51547B",
    marginTop: "2em",
  },
  profileImageStyle: {
    width: "3em",
    height: "3em",
    borderRadius: "4em",
    padding: "0.2em",
    border: "1px dashed #FFC800",
  },
  searchInputStyle: {
    input: { color: "white" },
    borderColor: "white",
    width: "300px",
    height: "35px !important",
    maxHeight: "35px !important",
    padding: "0px",
  },
  filterContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#171616",
    padding: "1em",
  },
  userNameText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#FFFFFF",
    marginBottom: "1px",
  },
  userNameSectionText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "rgba(255,255,255,0.3)",
  },
  regularText: {
    fontSize: "0.9rem",
    fontWeight: 300,
    color: "#FFFFFF",
    marginLeft: "8px",
  },
  flexColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  bankDetailContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginTop: "1em",
  },
  bankNameLogoContainer: {
    display: "flex",
    alignItems: "center",
    flex: 5,
  },
  bankNameContainer: {
    display: "flex",
    flexDirection: "column",
  },
  bankNameStyle: {
    fontSize: "1em",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  deactivatedBankNameStyle: {
    fontSize: "1em",
    fontWeight: 600,
    color: "#5A5463",
  },

  bankLogoContainer: {
    display: "flex",
    flexDirection: "row",
    background: `url(${BankBg}) no-repeat`,
    backgroundSize: "cover",
    width: "3em",
    height: "3em",
    borderRadius: "3em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginRight: "0.5em",
  },
  bankLogoContainerDeactivated: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#333333",
    width: "3em",
    height: "3em",
    borderRadius: "3em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginRight: "0.5em",
  },
  bankLogoGlass: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(5px)",
    width: "2.5em",
    height: "2.5em",
    borderRadius: "2.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoGlassDeactivated: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    backdropFilter: "blur(5px)",
    width: "2.5em",
    height: "2.5em",
    borderRadius: "2.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoTextDeactivated: {
    fontSize: "1em",
    fontWeight: 700,
    color: "#5A5463",
  },
  maskedBankAccountText: {
    display: "flex",
    alignItems: "center",
    color: "#727272",
    fontSize: "0.9em",
    fontWeight: 400,
  },
  approveButton: {
    width: "10em",
    height: "2.3em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "rgba(0, 48, 2,1)",
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(0, 48, 2,0.4)",
      cursor: "pointer",
    },
  },
  userStatusText: {
    fontSize: "0.8rem",
    fontWeight: 500,
    marginLeft: "0.3em",
    color: COLOR.primary,
  },
  disableButton: {
    width: "10em",
    height: "2.3em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "#515252",
    transaction: "all 0.3s ease-in-out",
  },
  disableButtonTxt: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  approveButtonTxt: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  rejectedButton: {
    width: "10em",
    height: "2.3em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "#c71414",
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(45, 0, 0,0.5)",
      cursor: "pointer",
    },
  },
  rejectedButtonTxt: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  promoteToAdminButton: {
    width: "12em",
    height: "2.3em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "rgba(0, 48, 2,1)",
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(0, 48, 2,0.4)",
      cursor: "pointer",
    },
  },
  promoteToAdminTxt: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#FFFFFFF",
    textAlign: "center",
  },
  updatePasswordButton: {
    width: "12em",
    height: "2.3em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: COLOR.primary,
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: COLOR.primaryDark,
      cursor: "pointer",
    },
  },
  updatePasswordTxt: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#000000",
    textAlign: "center",
  },
  maskedBankAccountTextDeactivated: {
    display: "flex",
    alignItems: "center",
    color: "#5A5463",
    fontSize: "0.9em",
    fontWeight: 400,
  },
  bankLogoText: {
    fontSize: "1em",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  notVerified: {
    fontSize: "0.8em",
    fontWeight: 600,
    color: "#888888",
    backgroundColor: "rgba(0,0,0,0.8)",
    padding: "3px 8px",
    borderRadius: "30px",
  },
  imageChooserText: {
    fontWeight: 300,
    fontSize: "0.6rem",
    cursor: "pointer",
    color: "#FFFFFF",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  profileItemText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  croppedImageStyle: {
    width: "3.2em",
    height: "3.2em",
    borderRadius: "50px",
    border: "0.5px dashed #FFC018",
    padding: "0.25em",
    backgroundColor: "#000000",
  },
  totalRecordsStyle: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: 650,
  },
  editButtonContainer: {
    border: "1px solid #83807F",
    borderRadius: "5em",
    minWidth: "3.6em",
  },
  editText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#83807F",
    marginRight: "1em",
  },
  fullWidth: {
    width: "100%",
  },
  textFieldWidth: {
    flex: 1,
    minWidth: "15em",
    marginLeft: "1em",
  },
  rowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      padding: "1em 0.8em",
    },
    "&:last-child td, &:last-child th": { border: 0 },
  },
  iconStyle: {
    width: "2.5em",
    height: "2.5em",
  },
  inlineCenter: {
    alignItems: "center",
  },
  leftAlignText: {
    alignItems: "left",
    textAlign: "left",
    backgroundColor: "#010101",
  },
  balanceText: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  subText: {
    fontSize: "0.8rem",
    fontWeight: 400,
  },
  coinName: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  walletBalance: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginRight: "5px",
  },
  aedWalletBalance: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginRight: "5px",
    marginLeft: "1em",
  },
  aedWalletBalanceValue: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  tableBody: {
    padding: 0,
    margin: 0,
  },
};
export default styles;
