import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TransitionComponent from "./TransitionComponent";
import { CloseIcon, EyeShowIcon, EyeSlashIcon } from "../assets";
import PrimaryButton from "./PrimaryButton";
import { requestUpdateProfilePassword } from "../api/api";
import { AdminUpdatePasswordRequest } from "../types/AdminUpdatePasswordModel";
import { STORE_KEY } from "../utils/AppConstants";
import { useState } from "react";
import { generateRandomText } from "../utils/Utils";
import AlertComponent from "./AlertComponent";
import { isEmpty, isValidPassword } from "../validations/Validation";
import { aesEncrypt } from "../utils/Encryption";

type Props = {
  open: boolean;

  onClose: () => void;
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
};

const ChangePasswordDialog = (props: Props) => {
  const { open, onClose, onError, onSuccess } = props;
  const [toastMessage, setToastMessage] = useState("");

  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [oldPassword, setOldPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);

  const [oldPasswordError, setOldPasswordError] = useState<string | null>(null);
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);

  const updatePassword = async () => {
    try {
      let isValid = true;
      const user = localStorage.getItem(STORE_KEY.SESSION_DATA);
      if (!user) return;
      if (!oldPassword) {
        isValid = false;
        setOldPasswordError("Please enter old password");
      }
      if (!newPassword) {
        isValid = false;
        setNewPasswordError("Please enter new password");
      }
      if (!confirmPassword) {
        isValid = false;
        setConfirmPasswordError("Please enter confirm password");
      }
      if (oldPassword && isEmpty(oldPassword)) {
        isValid = false;
        setOldPasswordError("Please enter old password");
      }
      if (newPassword && isEmpty(newPassword)) {
        isValid = false;
        setNewPasswordError("Please enter new password");
      }
      if (newPassword && !isValidPassword(newPassword)) {
        isValid = false;
        setNewPasswordError(
          "Password should be at least 8 characters, must contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character"
        );
      }
      if (confirmPassword && isEmpty(confirmPassword)) {
        isValid = false;
        setConfirmPasswordError("Please enter confirm password");
      }

      if (newPassword !== confirmPassword) {
        isValid = false;
        setConfirmPasswordError("Password does not match");
      }
      if (isValid) {
        const body: AdminUpdatePasswordRequest = {
          old_password: oldPassword ? oldPassword : "",
          new_password: newPassword ? newPassword : "",
        };
        const encryptText = aesEncrypt(JSON.stringify(body));
        const encryptBody = {
          dataCipher: encryptText,
        };
        const response = await requestUpdateProfilePassword(encryptBody);
        if (response) {
          if (response.status === 200) {
            onSuccess(response.data.message);
          } else {
            onError(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log("Error in update password: ", error);
    }
  };

  const onGeneratePasswordPress = async () => {
    const password = generateRandomText(10);
    setNewPassword(password);
    setConfirmPassword(password);
    await navigator.clipboard.writeText(password);
    setToastMessage("Password generated and copied to clipboard");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={TransitionComponent}
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
          minWidth: "380px",
          maxWidth: "380px",
        },
      }}
    >
      <DialogTitle sx={styles.headerRow}>
        <Typography sx={styles.headerTitle} mr={3}>
          Change Password
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction={"column"} mb={2}>
          <label style={styles.labelText}>Old Password</label>
          <TextField
            sx={styles.inputStyle}
            value={oldPassword}
            onChange={(event) => {
              if (event.target.value && event.target.value.length > 0) {
                setOldPasswordError(null);
              }
              setOldPassword(event.target.value);
            }}
            type={oldPasswordVisible ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setOldPasswordVisible((prev) => !prev)}
                    edge="end"
                    style={{ color: "#FFC800" }}
                  >
                    {oldPasswordVisible ? <EyeShowIcon /> : <EyeSlashIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={oldPasswordError === null ? false : true}
            helperText={oldPasswordError !== null ? oldPasswordError : ""}
          />
        </Stack>
        <Stack direction={"column"} mb={2}>
          <label style={styles.labelText}>New Password</label>
          <TextField
            value={newPassword}
            onChange={(event) => {
              if (event.target.value && event.target.value.length > 0) {
                setNewPasswordError(null);
              }
              setNewPassword(event.target.value);
            }}
            type={newPasswordVisible ? "text" : "password"}
            sx={styles.inputStyle}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setNewPasswordVisible((prev) => !prev)}
                    edge="end"
                    style={{ color: "#FFC800" }}
                  >
                    {newPasswordVisible ? <EyeShowIcon /> : <EyeSlashIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={newPasswordError === null ? false : true}
            helperText={newPasswordError === null ? "" : newPasswordError}
          />
          <Typography
            sx={styles.generatePassword}
            onClick={onGeneratePasswordPress}
          >
            Generate Password
          </Typography>
        </Stack>

        <Stack direction={"column"} mb={4}>
          <label style={styles.labelText}>Confirm Password</label>
          <TextField
            value={confirmPassword}
            onChange={(event) => {
              if (event.target.value && event.target.value.length > 0) {
                setConfirmPasswordError(null);
              }
              setConfirmPassword(event.target.value);
            }}
            type={confirmPasswordVisible ? "text" : "password"}
            sx={styles.inputStyle}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setConfirmPasswordVisible((prev) => !prev)}
                    edge="end"
                    style={{ color: "#FFC800" }}
                  >
                    {confirmPasswordVisible ? (
                      <EyeShowIcon />
                    ) : (
                      <EyeSlashIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={confirmPasswordError === null ? false : true}
            helperText={
              confirmPasswordError === null ? "" : confirmPasswordError
            }
          />
        </Stack>
        <PrimaryButton title="Submit" onClick={updatePassword} />
      </DialogContent>
      {toastMessage && (
        <AlertComponent
          open={toastMessage ? true : false}
          onClose={() => setToastMessage("")}
          type={"info"}
          message={toastMessage}
        />
      )}
    </Dialog>
  );
};
export default ChangePasswordDialog;
const styles = {
  headerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  labelText: {
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 0.6)",
    marginBottom: "0.5em",
  },
  inputStyle: {
    input: {
      color: "white",
      backgroundColor: "transparent",
      fontWeight: 400,
    },
  },
  generatePassword: {
    textAlign: "right",
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 0.6)",
    cursor: "pointer",
    marginTop: "3px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};
