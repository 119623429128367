import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TransactionData,
  TransactionResponse,
} from "../../types/TransactionModel";

interface TransactionState {
  result: TransactionData | null;
  loading: boolean;
  error: string | null;
}

const initialState: TransactionState = {
  result: null,
  loading: false,
  error: null,
};

const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    transactionRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },

    transactionSuccess(state, action: PayloadAction<TransactionResponse>) {
      console.log("Transaction: ", action.payload.data);
      state.loading = false;
      state.result = action.payload.data;
    },
    transactionFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { transactionRequest, transactionSuccess, transactionFailure } =
  transactionSlice.actions;

export default transactionSlice.reducer;
