import {
  Box,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { COLOR } from "../../theme";
import { useEffect, useRef, useState } from "react";
import {
  CakeIcon,
  CloseIcon,
  EmailIcon,
  PhoneIcon,
  ProfileIcon,
} from "../../assets";
import { YMDToDMY } from "../../utils/DateUtils";
import profileBg from "../../assets/svg/profileBg.svg";
import PrimaryButton from "../../components/PrimaryButton";
import ChangePasswordDialog from "../../components/ChangePasswordDialog";
import AlertComponent from "../../components/AlertComponent";
import { AlertDataProps } from "../../types/Common";
import { ProfileImageUploadRequest } from "../../types/ProfileImageModel";
import ReactCrop, { Crop } from "react-image-crop";
import { uploadProfileImage } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { profileRequest } from "../../store/reducers/ProfileReducer";
import { RootState } from "../../store/reducers/RootReducers";

const ProfilePage = () => {
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);
  const dispatch = useDispatch();
  const profileData = useSelector((state: RootState) => state.profileState);
  const fileInputRef = useRef<any>(null);
  const [crop, setCrop] = useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    dispatch(profileRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader?.result) {
          setSelectedImage(reader.result.toString());
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (crop: Crop) => {
    makeCroppedImage(crop);
  };

  const makeCroppedImage = (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(
          imageRef.current,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        const base64Image = canvas.toDataURL("image/jpeg");
        setCroppedImageUrl(base64Image);
      }
    }
  };

  const handleImageUpload = async () => {
    try {
      if (croppedImageUrl) {
        const body: ProfileImageUploadRequest = {
          profile_image: croppedImageUrl,
        };
        const response: any = await uploadProfileImage(body);
        if (response) {
          if (response.status === 200) {
            setAlertData({
              message: response.data.message,
              type: "success",
            });
            dispatch(profileRequest());
            setSelectedImage("");
          } else {
            if (response.response?.data?.message) {
              setAlertData({
                message: response.response?.data?.message,
                type: "error",
              });
              setSelectedImage("");
            }
          }
        }
      }
    } catch (error) {
      console.log("Handle Image Upload Error", error);
    }
  };

  return (
    <Box>
      <Card sx={styles.cardContainerStyle}>
        <CardContent sx={styles.paddingZero}>
          <Box sx={styles.headerRow}>
            <Stack
              direction={"column"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
            >
              <input
                id="profile-image-chooser"
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <div
                style={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  height: "3em",
                }}
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                {profileData?.result?.profilePic ? (
                  <img
                    src={profileData?.result?.profilePic}
                    style={styles.profileImageStyle}
                    alt={profileData?.result?.email}
                  />
                ) : (
                  <ProfileIcon height={"3em"} width={"3em"} />
                )}
              </div>
              <label
                htmlFor="profile-image-chooser"
                style={styles.imageChooserText}
              >
                Edit
              </label>
            </Stack>

            <Stack direction={"column"} ml={2}>
              <Typography sx={styles.userNameText}>{`${
                profileData?.result?.firstName
                  ? profileData?.result?.firstName
                  : "Anonymous"
              } ${
                profileData?.result?.lastName
                  ? profileData?.result?.lastName
                  : "User"
              }`}</Typography>
              <Typography sx={styles.accountDetailText}>
                {"Account Details"}
              </Typography>
            </Stack>
          </Box>
          <Box sx={styles.contentArea}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                  <EmailIcon width={32} height={32} />
                  <Stack>
                    <Typography sx={styles.label}>Email</Typography>
                    <Typography sx={styles.valueStyle}>
                      {profileData?.result?.email}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} gap={2} mt={2}>
                  <PhoneIcon width={32} height={32} />
                  <Stack>
                    <Typography sx={styles.label}>Phone No.</Typography>
                    <Typography sx={styles.valueStyle}>
                      {profileData?.result?.phoneNumber
                        ? profileData?.result?.phoneNumber
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} gap={2} mt={2}>
                  <CakeIcon width={32} height={32} />
                  <Stack>
                    <Typography sx={styles.label}>Date of Birth</Typography>
                    <Typography sx={styles.valueStyle}>
                      {YMDToDMY(profileData?.result?.dob)}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <img src={profileBg} alt="Profile Bg" />
              </Grid>
            </Grid>
          </Box>
          {selectedImage && (
            <Dialog open={selectedImage ? true : false}>
              <DialogContent>
                <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={1}>
                  <Stack direction={"row"} sx={styles.inlineCenter}>
                    {croppedImageUrl && (
                      <div className="cropped-image">
                        <img
                          src={croppedImageUrl}
                          alt="Cropped"
                          style={styles.croppedImageStyle}
                        />
                      </div>
                    )}
                    <Typography sx={styles.profileItemText} ml={1}>
                      Image Cropper
                    </Typography>
                  </Stack>

                  <IconButton
                    sx={styles.iconBackground}
                    onClick={() => setSelectedImage("")}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Divider />

                <ReactCrop
                  style={{ width: 300, height: 300 }}
                  crop={crop}
                  aspect={1}
                  onChange={(newCrop) => setCrop(newCrop)}
                  onComplete={onCropComplete}
                >
                  <img ref={imageRef} src={selectedImage} alt="Crop" />
                </ReactCrop>

                <PrimaryButton title={"Upload"} onClick={handleImageUpload} />
              </DialogContent>
            </Dialog>
          )}
        </CardContent>
        <CardActions>
          <Stack sx={{ margin: "1em 1em 1em auto" }}>
            <PrimaryButton
              title="Change Password"
              onClick={() => setShowChangePasswordDialog(true)}
            />
          </Stack>
        </CardActions>
      </Card>
      {showChangePasswordDialog && (
        <ChangePasswordDialog
          open={showChangePasswordDialog}
          onClose={() => setShowChangePasswordDialog(false)}
          onError={(error) => {
            setAlertData({
              message: error,
              type: "error",
            });
            setShowChangePasswordDialog(false);
          }}
          onSuccess={(message) => {
            setAlertData({
              message: message,
              type: "success",
            });
            setShowChangePasswordDialog(false);
          }}
        />
      )}
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};
export default ProfilePage;

const styles = {
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
    height: "90%",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  paddingZero: {
    padding: 0,
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  croppedImageStyle: {
    width: "3.2em",
    height: "3.2em",
    borderRadius: "50px",
    border: "0.5px dashed #FFC018",
    padding: "0.25em",
    backgroundColor: "#000000",
  },
  profileItemText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  inlineCenter: {
    alignItems: "center",
  },
  cardPadding: {
    padding: "1em",
  },
  contentArea: {
    padding: "2em",
  },
  imageChooserText: {
    fontWeight: 300,
    fontSize: "0.6rem",
    cursor: "pointer",
    color: "#FFFFFF",
  },
  headerRow: {
    backgroundColor: "#171616",
    padding: "1em",
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileImageStyle: {
    height: "4em",
    width: "4em",
    maxHeight: "4em",
    maxWidth: "4em",
    borderRadius: "50%",
    padding: "5px",
    border: "1px dashed rgba(255, 200, 0,1)",
  },
  userNameText: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  accountDetailText: {
    fontSize: "0.7rem",
    fontWeight: 500,
    color: "rgba(255,255,255,0.6)",
  },
  label: {
    fontSize: "0.7rem",
    fontWeight: 500,
    color: "rgba(255,255,255,0.6)",
  },
  valueStyle: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "rgba(255,255,255,1)",
  },
};
