import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const BackArrowIcon = (props: Props) => {
  const { width = 18, height = 18 } = props;
  return (
    <svg height={height} viewBox="0 0 512 512" width={width}>
      <polyline
        points="244 400 100 256 244 112"
        fill="none"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="28px"
      />
      <line
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="28px"
        x1="120"
        x2="412"
        y1="256"
        y2="256"
      />
    </svg>
  );
};
export default BackArrowIcon;
