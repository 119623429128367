import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const BankIcon = (props: Props) => {
  const { width = 38, height = 38 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 38 38" fill="none">
      <circle cx="19" cy="19" r="19" fill="#333333" />
      <path
        d="M18.5494 7.57396C18.8223 7.39201 19.1779 7.39201 19.4508 7.57396L29.2008 14.074C29.5741 14.3229 29.675 14.8273 29.4261 15.2007C29.1772 15.5741 28.6728 15.675 28.2994 15.426L19.0001 9.2265L9.70078 15.426C9.32741 15.675 8.82296 15.5741 8.57405 15.2007C8.32513 14.8273 8.42603 14.3229 8.79939 14.074L18.5494 7.57396Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9375 16.1935V26.9375H28.75C29.1987 26.9375 29.5625 27.3013 29.5625 27.75C29.5625 28.1987 29.1987 28.5625 28.75 28.5625H9.25C8.80127 28.5625 8.4375 28.1987 8.4375 27.75C8.4375 27.3013 8.80127 26.9375 9.25 26.9375H10.0625V16.1935C10.0625 15.7934 10.3538 15.4529 10.7491 15.3909C13.4379 14.969 16.1937 14.75 19 14.75C21.8063 14.75 24.5621 14.969 27.2509 15.3909C27.6462 15.4529 27.9375 15.7934 27.9375 16.1935ZM19.8125 18.8125C19.8125 18.3638 19.4487 18 19 18C18.5513 18 18.1875 18.3638 18.1875 18.8125V26.125C18.1875 26.5737 18.5513 26.9375 19 26.9375C19.4487 26.9375 19.8125 26.5737 19.8125 26.125V18.8125ZM23.0625 18C23.5112 18 23.875 18.3638 23.875 18.8125V26.125C23.875 26.5737 23.5112 26.9375 23.0625 26.9375C22.6138 26.9375 22.25 26.5737 22.25 26.125V18.8125C22.25 18.3638 22.6138 18 23.0625 18ZM15.75 18.8125C15.75 18.3638 15.3862 18 14.9375 18C14.4888 18 14.125 18.3638 14.125 18.8125V26.125C14.125 26.5737 14.4888 26.9375 14.9375 26.9375C15.3862 26.9375 15.75 26.5737 15.75 26.125V18.8125Z"
        fill="white"
      />
      <path
        d="M19 13.5312C19.6731 13.5312 20.2188 12.9856 20.2188 12.3125C20.2188 11.6394 19.6731 11.0938 19 11.0938C18.3269 11.0938 17.7812 11.6394 17.7812 12.3125C17.7812 12.9856 18.3269 13.5312 19 13.5312Z"
        fill="white"
      />
    </svg>
  );
};
export default BankIcon;
