import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Stack,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  InputAdornment,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  TableRow,
  Box,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ProfileIcon, RightArrowIcon } from "../../assets";
import styles from "./UsersPage.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducers";
import { useLocation, useNavigate } from "react-router-dom";
import { usersRequest } from "../../store/reducers/UsersReducer";
import { UsersList } from "../../types/UsersModel";
import { getColorByStatus } from "../../utils/Utils";
import Loader from "../../components/Loader";

const UsersPage = () => {
  const recordsPerPage = 10;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const users = useSelector((state: RootState) => state.usersState);

  const [usersList, setUsersList] = useState<UsersList[]>([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const requestUsersList = (currentPage?: number) => {
    const queryString = [];
    if (filterStatus) {
      const query = `${encodeURIComponent("status")}=${encodeURIComponent(
        filterStatus
      )}`;
      queryString.push(query);
    }

    const query = `${encodeURIComponent("page")}=${encodeURIComponent(
      currentPage ? currentPage : page
    )}&${encodeURIComponent("limit")}=${encodeURIComponent(recordsPerPage)}`;
    queryString.push(query);

    if (
      searchInputRef?.current?.value &&
      searchInputRef?.current?.value?.length > 0
    ) {
      const query = `${encodeURIComponent("filter")}=${encodeURIComponent(
        searchInputRef?.current?.value
      )}`;
      queryString.push(query);
    }

    const strJoin = queryString.join("&");
    dispatch(usersRequest(strJoin));
  };

  useEffect(() => {
    requestUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (users.result?.users && users.result?.users.length > 0) {
      setUsersList(users.result?.users);
      setTotalPages(users?.result.totalPages);
      setTotalRecords(users?.result.totalCount);
    } else {
      setUsersList([]);
    }
  }, [users.result]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setFilterStatus(event.target.value);
  };

  useEffect(() => {
    if (filterStatus) {
      setPage(1);
      const query = `${encodeURIComponent("status")}=${encodeURIComponent(
        filterStatus
      )}&${encodeURIComponent("page")}=${encodeURIComponent(
        page
      )}&${encodeURIComponent("limit")}=${encodeURIComponent(recordsPerPage)}`;
      dispatch(usersRequest(query));
    } else {
      dispatch(usersRequest(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    requestUsersList(value);
    setPage(value);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      setTimeout(() => {
        setPage(1);
        requestUsersList(1);
      }, 1500);

      // throttledSearch(value);
    } else {
      requestUsersList();
    }
  };

  const handleRowClick = (userId: number) => {
    if (userId) {
      navigate("user/" + userId);
    }
  };

  return (
    <Card sx={styles.cardContainerStyle}>
      <CardContent sx={styles.paddingZero}>
        <Stack direction="row" sx={styles.filterContainer}>
          <TextField
            placeholder="Search"
            size="small"
            inputRef={searchInputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "white" }} />
                </InputAdornment>
              ),
              style: { color: "white", borderColor: "white" },
            }}
            variant="outlined"
            onChange={handleSearchChange}
            sx={styles.searchInputStyle}
          />
          <FormControl
            variant="outlined"
            sx={{
              minWidth: 160,
              height: "35px !important",
              maxHeight: "35px !important",
            }}
          >
            <Select
              value={filterStatus}
              onChange={handleStatusChange}
              displayEmpty
              sx={{
                color: "white",
                borderColor: "white",
              }}
              inputProps={{
                "aria-label": "Without label",
                height: "35px !important",
                maxHeight: "35px !important",
              }}
            >
              <MenuItem value="">
                <em>Choose Status</em>
              </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
              <MenuItem value="Unverified">Unverified</MenuItem>
              <MenuItem value="Suspended">Suspended</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <TableContainer sx={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableTitleRowBorder}>
                <TableCell>#</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell sx={styles.leftAlignText}>Email Address</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Box sx={styles.loadingData}>
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : usersList && usersList.length > 0 ? (
                usersList.map((row: UsersList, index: number) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(row.userId)}
                  >
                    <TableCell align="center">
                      {(page - 1) * 10 + index + 1}
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" spacing={2} alignItems="center">
                        {row.profilePic ? (
                          <img
                            src={row.profilePic}
                            alt={row.email}
                            style={styles.profileImageStyle}
                          />
                        ) : (
                          <ProfileIcon height={"3em"} width={"3em"} />
                        )}

                        <Typography sx={styles.customRows}>
                          {`${row.firstName ? row.firstName : "Anonymous"} ${
                            row.lastName ? row.lastName : "User"
                          }`}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography sx={styles.customRows}>
                        {row.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={styles.customRows}>
                        {row.phoneNumber ? row.phoneNumber : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          fontWeight: 400,
                          textAlign: "center",
                          color: getColorByStatus(row.status),
                        }}
                      >
                        {row.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <RightArrowIcon width={26} height={26} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography sx={styles.sorryText}>Sorry!!</Typography>
                    <Typography sx={styles.noDataRow}>No Data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
          px={3}
          mt={2}
        >
          <Typography
            sx={styles.totalRecordsStyle}
          >{`Total Records: ${totalRecords}`}</Typography>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            sx={{
              button: { color: "white" },
              ul: { justifyContent: "center" },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default UsersPage;
