import React from "react";

type Props = {
  width?: number;
  height?: number;
  circleFill?: boolean;
};

const UserIcon = (props: Props) => {
  const { width = 32, height = 32, circleFill = true } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 38 38" fill="none">
      <circle
        cx="19"
        cy="19"
        r="19"
        fill={circleFill ? "#1F1F1F" : "transparent"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5625 13.25C14.5625 11.0754 16.3254 9.3125 18.5 9.3125C20.6746 9.3125 22.4375 11.0754 22.4375 13.25C22.4375 15.4246 20.6746 17.1875 18.5 17.1875C16.3254 17.1875 14.5625 15.4246 14.5625 13.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2823 25.5922C11.35 21.6636 14.5555 18.5 18.5 18.5C22.4446 18.5 25.6501 21.6638 25.7177 25.5924C25.7221 25.853 25.572 26.0915 25.3352 26.2002C23.2536 27.1553 20.9382 27.6875 18.5003 27.6875C16.0621 27.6875 13.7465 27.1552 11.6648 26.1999C11.4279 26.0912 11.2778 25.8527 11.2823 25.5922Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default UserIcon;
