import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./AdminUserDetails.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminUsersDetailsRequest } from "../../../store/reducers/AdminUserReducer";
import { RootState } from "../../../store/reducers/RootReducers";
import {
  CakeIcon,
  CloseIcon,
  EditPencilIcon,
  EmailIcon,
  PhoneIcon,
  ProfileIcon,
  UserIcon,
} from "../../../assets";
import { Form, FormikProvider, useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import PrimaryButton from "../../../components/PrimaryButton";
import { getDateOfBirthFormat } from "../../../utils/DateUtils";
import { AdminUpdateRequest } from "../../../types/AdminUserModel";
import { requestUpdateAdminApi, uploadProfileImage } from "../../../api/api";
import { ProfileUpdateSchema } from "../../../validations/Validation";
import { ProfileImageUploadRequest } from "../../../types/ProfileImageModel";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { AlertDataProps } from "../../../types/Common";
import AlertComponent from "../../../components/AlertComponent";
import BackArrowIcon from "../../../assets/svg/BackArrowIcon";
import PromoteToUserDialog from "../../../components/PromoteToUserDialog";

const AdminUserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [crop, setCrop] = useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const userDetail = useSelector((state: RootState) => state.adminUserState);
  const [showPromoteUserDialog, setPromoteUserDialog] = useState(false);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  const getAdminDetails = () => {
    const userId = parseInt(location.pathname.split("/")[4]);
    dispatch(adminUsersDetailsRequest(userId));
  };

  useEffect(() => {
    getAdminDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitUpdateProfile = async (id: number, body: AdminUpdateRequest) => {
    try {
      const response = await requestUpdateAdminApi(id, body);
      if (response) {
        if (response.status === 200) {
          setAlertData({
            message: response.data.message,
            type: "success",
          });
          setEdit(false);
          getAdminDetails();
        } else {
          if (response.data) {
            setAlertData({
              message: response.data.message,
              type: "error",
            });
          } else {
            setAlertData({
              message: "Error in updating profile. Please try again.",
              type: "error",
            });
          }
        }
      } else {
        setAlertData({
          message: "Error in updating profile. Please try again.",
          type: "error",
        });
      }
    } catch (error) {
      setAlertData({
        message: "Error in updating profile. Please try again.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (userDetail?.details?.dob) {
      setSelectedDate(dayjs(userDetail?.details?.dob));
    }
  }, [userDetail]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userDetail?.details?.firstName || "",
      last_name: userDetail?.details?.lastName || "",
      phone_number: userDetail?.details?.phoneNumber || "",
    },
    validationSchema: ProfileUpdateSchema,
    onSubmit: (values) => {
      if (selectedDate) {
        const parsedDate = dayjs(selectedDate, "DD/MM/YYYY");
        const body: AdminUpdateRequest = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,
          status: userDetail?.details?.status,
          role: userDetail?.details?.AdminRole,
          dob: parsedDate.format("YYYY-MM-DD"),
          email: userDetail?.details?.email,
          email_verified: userDetail?.details?.emailVerified.toString(),
          phone_verified: userDetail?.details?.phoneVerified.toString(),
          twofa_disabled: userDetail?.details?.twoFactorEnabled.toString(),
        };
        if (userDetail?.details?.adminId) {
          submitUpdateProfile(userDetail?.details?.adminId, body);
        }
      } else {
        console.log("please select selected date ", selectedDate);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const onCropComplete = (crop: Crop) => {
    makeCroppedImage(crop);
  };

  const makeCroppedImage = (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(
          imageRef.current,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
        const base64Image = canvas.toDataURL("image/jpeg");
        setCroppedImageUrl(base64Image);
      }
    }
  };

  const handleImageUpload = async () => {
    try {
      if (croppedImageUrl) {
        const body: ProfileImageUploadRequest = {
          profile_image: croppedImageUrl,
        };
        const response: any = await uploadProfileImage(body);
        if (response) {
          if (response.status === 200) {
            setSelectedImage("");
            if (userDetail?.details?.adminId) {
              dispatch(adminUsersDetailsRequest(userDetail?.details?.adminId));
            }
          } else {
            if (response.response?.data?.message) {
              setAlertData({
                message: response.response?.data?.message,
                type: "error",
              });
              setSelectedImage("");
            }
          }
        }
      }
    } catch (error) {}
  };

  return (
    <Box>
      <Box sx={styles.backButton} onClick={() => navigate(-1)}>
        <BackArrowIcon />
        <Typography sx={{ fontSize: "0.8rem", fontWeight: 600 }}>
          Back
        </Typography>
      </Box>
      <Card sx={styles.cardContainerStyle}>
        <CardContent sx={styles.paddingZero}>
          <Stack sx={styles.headerRow}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              sx={styles.fullWidth}
            >
              {userDetail?.details?.profilePic ? (
                <img
                  src={userDetail?.details?.profilePic}
                  style={styles.profileImageStyle}
                  alt={userDetail?.details.email}
                />
              ) : (
                <ProfileIcon height={"3em"} width={"3em"} />
              )}
              <Stack>
                <Stack direction={"row"}>
                  <Typography sx={styles.userNameText}>
                    {userDetail?.details?.firstName
                      ? userDetail?.details?.firstName
                      : "Anonymous"}{" "}
                    {userDetail?.details?.lastName
                      ? userDetail?.details?.lastName
                      : "User"}
                  </Typography>
                  <Typography
                    sx={styles.roleText}
                  >{`(${userDetail?.details?.AdminRole})`}</Typography>
                </Stack>

                <Typography sx={styles.userNameSectionText}>
                  Account Details
                </Typography>
              </Stack>
            </Stack>
            {userDetail?.details?.AdminRole.toLowerCase() !== "superadmin" &&
              userDetail?.details?.status.toLowerCase() === "active" && (
                <Box
                  sx={styles.promoteToAdminButton}
                  mx={3}
                  onClick={() => setPromoteUserDialog(true)}
                >
                  <Typography sx={styles.promoteToAdminTxt}>
                    {"Remove as Admin"}
                  </Typography>
                </Box>
              )}

            <IconButton
              sx={styles.editButtonContainer}
              onClick={() => setEdit((prev) => !prev)}
            >
              {!edit ? (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                  sx={styles.fullWidth}
                >
                  <Typography sx={styles.editText}>Edit</Typography>
                  <EditPencilIcon />
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography sx={styles.editText}>Cancel</Typography>
                  <CloseIcon color="#83807F" />
                </Stack>
              )}
            </IconButton>
          </Stack>
          <Divider />
          {edit ? (
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box>
                  <Stack direction={"row"} alignItems={"center"} px={2} py={2}>
                    <UserIcon width={32} height={32} />
                    <Stack direction={"row"} alignItems={"center"} gap={3}>
                      <TextField
                        placeholder="First name"
                        sx={styles.textFieldWidth}
                        {...getFieldProps("first_name")}
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                      />
                      <TextField
                        placeholder="Last name"
                        sx={styles.textFieldWidth}
                        {...getFieldProps("last_name")}
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} px={2} py={2}>
                    <EmailIcon width={32} height={32} />
                    <TextField
                      type="email"
                      placeholder="Email Address"
                      sx={styles.textFieldWidth}
                      variant={"outlined"}
                      value={userDetail?.details?.email}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} px={2} py={2}>
                    <PhoneIcon width={32} height={32} />
                    <TextField
                      type="number"
                      placeholder="Phone No."
                      sx={styles.textFieldWidth}
                      {...getFieldProps("phone_number")}
                      error={Boolean(
                        touched.phone_number && errors.phone_number
                      )}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    px={2}
                    py={2}
                    mb={3}
                    gap={2}
                  >
                    <CakeIcon width={32} height={32} />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={selectedDate}
                        disableFuture
                        format={"DD/MM/YYYY"}
                        onChange={(value) => setSelectedDate(dayjs(value))}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack sx={{ margin: "0 auto", width: "40%" }}>
                    <PrimaryButton
                      title={"Update Profile"}
                      onClick={handleSubmit}
                    />
                  </Stack>
                </Box>
              </Form>
            </FormikProvider>
          ) : (
            <Box>
              <Stack direction={"row"} alignItems={"center"} px={2} py={2}>
                <EmailIcon width={32} height={32} />
                <Typography sx={styles.regularText}>
                  {userDetail?.details?.email}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction={"row"} alignItems={"center"} px={2} py={2}>
                <PhoneIcon width={32} height={32} />
                <Typography sx={styles.regularText}>
                  {userDetail?.details?.phoneNumber ? (
                    userDetail?.details?.phoneNumber
                  ) : (
                    <Typography sx={styles.notVerified}>
                      Not Verified
                    </Typography>
                  )}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction={"row"} alignItems={"center"} px={2} py={2}>
                <CakeIcon width={32} height={32} />
                <Typography sx={styles.regularText}>
                  {userDetail?.details?.dob ? (
                    getDateOfBirthFormat(userDetail?.details?.dob)
                  ) : (
                    <Typography sx={styles.notVerified}>
                      Not Verified
                    </Typography>
                  )}
                </Typography>
              </Stack>
            </Box>
          )}
        </CardContent>
      </Card>
      {selectedImage && (
        <Dialog open={selectedImage ? true : false}>
          <DialogContent>
            <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={1}>
              <Stack direction={"row"} sx={styles.inlineCenter}>
                {croppedImageUrl && (
                  <div className="cropped-image">
                    <img
                      src={croppedImageUrl}
                      alt="Cropped"
                      style={styles.croppedImageStyle}
                    />
                  </div>
                )}
                <Typography sx={styles.profileItemText} ml={1}>
                  Image Cropper
                </Typography>
              </Stack>

              <IconButton
                sx={styles.iconBackground}
                onClick={() => setSelectedImage("")}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />

            <ReactCrop
              style={{ width: 300, height: 300 }}
              crop={crop}
              aspect={1}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={onCropComplete}
            >
              <img ref={imageRef} src={selectedImage} alt="Crop" />
            </ReactCrop>

            <PrimaryButton title={"Upload"} onClick={handleImageUpload} />
          </DialogContent>
        </Dialog>
      )}
      {showPromoteUserDialog === true && (
        <PromoteToUserDialog
          open={showPromoteUserDialog}
          userId={
            userDetail?.details?.adminId ? userDetail?.details?.adminId : 0
          }
          onClose={() => setPromoteUserDialog(false)}
          onError={(error: string) => {
            setPromoteUserDialog(false);
            setAlertData({ message: error, type: "error" });
          }}
          onSuccess={(message: string) => {
            setPromoteUserDialog(false);
            setAlertData({ message: message, type: "success" });
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          }}
        />
      )}
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};
export default AdminUserDetails;
