import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  TextField,
  Select,
  InputAdornment,
  FormControl,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  SelectChangeEvent,
} from "@mui/material";
import styles from "./TransactionPage.styles";
import SearchIcon from "@mui/icons-material/Search";
import { getColorByStatus } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducers";
import { getTransactionListDateTimeFormat } from "../../utils/DateUtils";
import { transactionRequest } from "../../store/reducers/TransactionReducer";
import { Transaction } from "../../types/TransactionModel";
import TransactionDetails from "../../components/TransactionDetails";
import Loader from "../../components/Loader";

const TransactionsPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const recordsPerPage = 10;
  const transactionState = useSelector(
    (state: RootState) => state.transactionState
  );
  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState("");
  const [typeStatus, setTypeStatus] = useState("");
  const [trnxId, setTranxId] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [transactionList, setTransactionList] = useState<Transaction[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const requestTransaction = (currentPage?: number) => {
    const queryString = [];
    if (filterStatus || typeStatus) {
      if (filterStatus) {
        const query = `${encodeURIComponent("status")}=${encodeURIComponent(
          filterStatus
        )}`;
        queryString.push(query);
      }
      if (typeStatus) {
        const query = `${encodeURIComponent("type")}=${encodeURIComponent(
          typeStatus
        )}`;
        queryString.push(query);
      }
    }
    const query = `${encodeURIComponent("page")}=${encodeURIComponent(
      currentPage ? currentPage : 1
    )}&${encodeURIComponent("limit")}=${encodeURIComponent(recordsPerPage)}`;
    queryString.push(query);
    if (
      searchInputRef?.current?.value &&
      searchInputRef?.current?.value?.length > 0
    ) {
      const query = `${encodeURIComponent("filter")}=${encodeURIComponent(
        searchInputRef?.current?.value
      )}`;
      queryString.push(query);
    }

    const strJoin = queryString.join("&");
    dispatch(transactionRequest(strJoin));
  };

  useEffect(() => {
    requestTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (
      transactionState.result?.transactions &&
      transactionState.result.transactions.length > 0
    ) {
      setTransactionList(transactionState.result.transactions);
      setTotalPages(transactionState?.result.totalPages);
      setTotalRecords(transactionState?.result.totalCount);
    } else {
      setTransactionList([]);
    }
  }, [transactionState.result]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setFilterStatus(event.target.value);
  };

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setTypeStatus(event.target.value);
  };

  useEffect(() => {
    if (filterStatus || typeStatus) {
      const queryString = [];
      if (filterStatus) {
        const query = `${encodeURIComponent("status")}=${encodeURIComponent(
          filterStatus
        )}`;
        queryString.push(query);
      }
      if (typeStatus) {
        const query = `${encodeURIComponent("type")}=${encodeURIComponent(
          typeStatus
        )}`;
        queryString.push(query);
      }
      const strJoin = queryString.join("&");
      dispatch(transactionRequest(strJoin));
    } else {
      requestTransaction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus, typeStatus]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (
      searchInputRef?.current?.value &&
      searchInputRef?.current?.value.length > 0
    ) {
      requestTransaction(value);
    } else {
      requestTransaction(value);
      setPage(value);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      setTimeout(() => {
        setPage(1);
        requestTransaction(1);
      }, 1500);
    } else {
      requestTransaction();
    }
  };

  const getTransactionTypeTextStyle = (type: string) => {
    switch (type) {
      case "Transfer":
        return {
          fontSize: "0.8rem",
          fontWeight: 500,
          color: "rgba(203, 31, 20, 1)",
          textAlign: "center",
        };
      case "Redeem":
        return {
          fontSize: "0.8rem",
          fontWeight: 500,
          color: "rgba(231, 188, 68, 1)",
          textAlign: "center",
        };
      case "Sell":
        return {
          fontSize: "0.8rem",
          fontWeight: 500,
          color: "rgba(203, 31, 20, 1)",
          textAlign: "center",
        };
      case "Received":
        return {
          fontSize: "0.8rem",
          fontWeight: 500,
          color: "rgba(20, 203, 27, 1)",
          textAlign: "center",
        };
      case "Buy":
        return {
          fontSize: "0.8rem",
          fontWeight: 500,
          color: "rgba(20, 203, 27, 1)",
          textAlign: "center",
        };
    }
  };
  const handleRowClick = (transactionId: number) => {
    setTranxId(transactionId);
  };

  const handleDownloadClick = () => {
    if (transactionList && transactionList.length > 0) {
      // Define the headers for the CSV
      const headers = [
        "Transaction ID",
        "First Name",
        "Last Name",
        "Transaction Hash",
        "Transaction Type",
        "Status",
        "Transaction Value",
        "Transaction Value (AED)",
        "Transaction Fees",
        "Platform Fees",
        "Order ID",
        "Created At",
        "Crypto Name",
        "Crypto Ticker",
        "Crypto Icon URL",
      ];

      // Map over the transactions and convert each one into a CSV row
      const rows = transactionList.map((transaction) => [
        transaction.transactionId,
        transaction.firstName ?? "",
        transaction.lastName ?? "",
        transaction.transactionHash,
        transaction.transactionType,
        transaction.status,
        transaction.txValue,
        transaction.transactionValueAed,
        transaction.transactionFees,
        transaction.platformFees ?? "",
        transaction.orderId,
        transaction.createdAt,
        transaction.cryptoName,
        transaction.cryptoTicker,
        transaction.cryptoIconUrl,
      ]);

      // Join the headers and rows into a CSV string
      const csvContent = [
        headers.join(","), // Add headers
        ...rows.map((row) => row.join(",")), // Add each row of data
      ].join("\n");

      // Create a Blob from the CSV data
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Create a link and trigger the download
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "transactions.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Card sx={styles.cardContainerStyle}>
        <CardContent sx={styles.paddingZero}>
          <Stack direction="row" sx={styles.filterContainer}>
            <TextField
              placeholder="Search"
              size="small"
              inputRef={searchInputRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "white" }} />
                  </InputAdornment>
                ),
                style: { color: "white", borderColor: "white" },
              }}
              variant="outlined"
              onChange={handleSearchChange}
              sx={styles.searchInputStyle}
            />
            {/* <Box sx={styles.boxButton} onClick={handleDownloadClick}>
              <Typography sx={styles.downloadBtnText}>Download</Typography>
            </Box> */}
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <FormControl variant="outlined" sx={{ minWidth: 160 }}>
                <Select
                  value={typeStatus}
                  onChange={handleTypeChange}
                  displayEmpty
                  sx={{ color: "white", borderColor: "white" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>Choose Type</em>
                  </MenuItem>
                  <MenuItem value="Redeem">Redeem</MenuItem>
                  <MenuItem value="Sell">Sell</MenuItem>
                  <MenuItem value="Buy">Buy</MenuItem>
                  <MenuItem value="Transfer">Transfer</MenuItem>
                  <MenuItem value="Received">Received</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ minWidth: 160 }}>
                <Select
                  value={filterStatus}
                  onChange={handleStatusChange}
                  displayEmpty
                  sx={{ color: "white", borderColor: "white" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>Choose Status</em>
                  </MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Failed">Failed</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={styles.tableTitleRowBorder}>
                  <TableCell>#</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionState?.loading ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box sx={styles.loadingRow}>
                        <Loader />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : transactionList && transactionList.length > 0 ? (
                  transactionList?.map((row: Transaction, index: number) => (
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(row.transactionId)}
                    >
                      <TableCell align="center">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>

                      <TableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <img
                            src={row.cryptoIconUrl}
                            alt={row.cryptoTicker}
                            style={styles.profileImageStyle}
                          />
                          <Typography sx={styles.boldRow}>
                            {`${
                              row.cryptoTicker ? row.cryptoTicker : "Anonymous"
                            }`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="center">{row.transactionId}</TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {`${row.firstName ? row.firstName : "Anonymous"} ${
                            row.lastName ? row.lastName : "User"
                          }`}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {row.txValue}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={getTransactionTypeTextStyle(row.transactionType)}
                        >
                          {row.transactionType}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "0.9em",
                            fontWeight: 400,
                            textAlign: "center",
                            color: getColorByStatus(row.status),
                          }}
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.customRows}>
                          {getTransactionListDateTimeFormat(row.createdAt)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography sx={styles.sorryText}>Sorry!!</Typography>
                      <Typography sx={styles.noDataRow}>
                        No Data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
            px={3}
            mt={2}
          >
            <Typography
              sx={styles.totalRecordsStyle}
            >{`Total Records: ${totalRecords}`}</Typography>
            <Pagination
              count={totalPages ? totalPages : 1}
              page={page}
              color="primary"
              onChange={handleChangePage}
              sx={{
                button: { color: "white" },
                ul: { justifyContent: "center" },
              }}
            />
          </Box>
        </CardContent>
      </Card>
      {trnxId !== 0 && (
        <TransactionDetails
          open={trnxId !== 0 ? true : false}
          txnID={trnxId}
          handleClose={() => setTranxId(0)}
        />
      )}
    </>
  );
};

export default TransactionsPage;
