import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  RedeemDetail,
  RedeemDetailsResponse,
  RedeemList,
} from "../types/RedeemListModel";
import { ApproveIcon, CloseIcon, GoldBar, RejectedIcon } from "../assets";
import TransitionComponent from "./TransitionComponent";
import { formatNumber, getColorByStatus } from "../utils/Utils";
import { getTransactionListDateTimeFormat } from "../utils/DateUtils";
import { useEffect, useState } from "react";
import Loader from "./Loader";
import { isEmpty, isNumber } from "../validations/Validation";
import {
  requestRedeemApi,
  requestRedeemApproveApi,
  requestRedeemRejectedApi,
  requestUserDetails,
} from "../api/api";
import { RedeemData } from "../types/DashboardResponse";
import { PersonalDetails } from "../types/UserDetailModel";
import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers/RootReducers";
import { configGroupRequest } from "../store/reducers/AEGConfigGroupReducer";

type Props = {
  open: boolean;
  data: RedeemList | RedeemData;
  onClose: () => void;
  onError: (error: string) => void;
};

enum REDEEM_STATE {
  none,
  approve,
  reject,
  loading,
  approveSuccess,
  rejectedSuccess,
}

const RedeemDialog = (props: Props) => {
  const { open, data, onClose, onError } = props;
  const dispatch = useDispatch();
  const [redeemState, setRedeemState] = useState(REDEEM_STATE.none);
  const [showForm, setShowForm] = useState(true);
  const [redeemCode, setRedeemCode] = useState("");
  const [redeemCodeError, setRedeemCodeError] = useState("");
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [userData, setUserData] = useState<PersonalDetails | null>(null);
  const [redeemData, setRedeemData] = useState<RedeemDetail | null>(null);
  const configData = useSelector((state: RootState) => state.configGroupState);
  const [goldPricePerUnit, setGoldPricePerUnit] = useState<
    string | number | undefined
  >("");

  const getConfigValueByKey = () => {
    if (
      configData !== null &&
      configData.result &&
      configData.result.data.length > 0
    ) {
      const configValueRes = configData.result.data.find(
        (data) => data.configKey === "gold_per_unit"
      );
      setGoldPricePerUnit(configValueRes?.configValue);
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (configData?.result?.data && configData?.result?.data.length > 0) {
      getConfigValueByKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  useEffect(() => {
    dispatch(configGroupRequest("aeg_details"));
    requestUserDetails(data.userId)
      .then((response) => {
        if (response) {
          if (response?.status === 200) {
            setUserData(response.data.data.personal_details);
          } else {
            //onError(response.data.message);
          }
        }
      })
      .catch((error) => {
        onError(error);
      });
    requestRedeemApi(data.orderId)
      .then((response: AxiosResponse<RedeemDetailsResponse>) => {
        if (response) {
          if (response?.status === 200) {
            setRedeemData(response.data.data[0]);
          } else {
            //onError(response.data.message);
          }
        }
      })
      .catch((error) => {
        onError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFields = () => {
    setShowForm(true);
    setRedeemState(REDEEM_STATE.none);
    setRedeemCode("");
    setReason("");
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const onApproveClick = async () => {
    if (isEmpty(redeemCode)) {
      setRedeemCodeError("Please enter redeem code");
    } else if (isNumber(redeemCode) && redeemCode.length !== 6) {
      setRedeemCodeError("Please enter valid redeem code");
    } else {
      try {
        setRedeemState(REDEEM_STATE.loading);
        setShowForm(false);
        const response = await requestRedeemApproveApi(data.orderId, {
          code: redeemCode,
        });
        if (response) {
          if (response?.status === 200) {
            setRedeemState(REDEEM_STATE.approveSuccess);
          } else {
            resetFields();
            onError(response.data.message);
          }
        }
      } catch (error) {
        resetFields();
        onError("Request redeem error, Something went wrong");
      }
    }
  };
  const onRejectedClick = async () => {
    if (isEmpty(reason)) {
      setReasonError("Please enter reason");
    } else if (reason.length < 6) {
      setReasonError("Please enter valid reason");
    } else {
      try {
        setRedeemState(REDEEM_STATE.loading);
        setShowForm(false);
        const response = await requestRedeemRejectedApi(data.orderId, {
          reason: reason,
        });
        if (response) {
          if (response?.status === 200) {
            setRedeemState(REDEEM_STATE.rejectedSuccess);
          } else {
            resetFields();
            onError(response.data.message);
          }
        }
      } catch (error) {
        resetFields();
        onError("Request redeem error, Something went wrong");
      }
    }
  };

  const getRedeemValue = () => {
    try {
      return (
        parseFloat(goldPricePerUnit as string) *
        parseFloat(redeemData?.txValue as string)
      );
    } catch (error) {
      return 0;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionComponent}
    >
      <DialogTitle sx={styles.titleRow}>
        <Typography sx={styles.dialogTitle}>Redeem AEG</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.contentStyle}>
        {redeemState === REDEEM_STATE.approveSuccess && (
          <Box sx={styles.statusContainer}>
            <img
              src={ApproveIcon}
              alt="ApproveRedeem"
              style={styles.statusIcon}
            />
            <Typography sx={styles.statusTitle}>Approved</Typography>
            <Typography sx={styles.descText}>
              You can hand over the physical gold to the user
            </Typography>
            <Typography sx={styles.trnxSuccessTxt}>
              Transaction Successful
            </Typography>
            <Typography sx={styles.trnxIdTitle}>Transaction ID</Typography>
            <Typography sx={styles.trnxId}>{data.transactionId}</Typography>
          </Box>
        )}
        {redeemState === REDEEM_STATE.rejectedSuccess && (
          <Box sx={styles.statusContainer}>
            <img
              src={RejectedIcon}
              alt="rejected_state"
              style={styles.statusIcon}
            />
            <Typography sx={styles.statusRejectedTitle}>Rejected</Typography>
            <Typography sx={styles.reasonTitle}>Mentioned Reason</Typography>
            <Typography sx={styles.reasonDesc}>{reason}</Typography>

            <Typography sx={styles.trnxIdTitle}>Transaction ID</Typography>
            <Typography sx={styles.trnxId}>
              {redeemData?.transactionId}
            </Typography>
          </Box>
        )}
        {redeemState === REDEEM_STATE.loading && (
          <Box sx={styles.loadingContainer}>
            <Typography sx={styles.loadingTitle}>Please wait...</Typography>
            <Loader />
          </Box>
        )}
        {showForm && (
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              mt={2}
            >
              <Typography sx={styles.redeemValue} mx={1}>
                Redeem Value :
              </Typography>
              <img src={GoldBar} style={styles.goldBar} alt="GoldBar" />
              <Typography sx={styles.aegText} ml={1}>
                {`${
                  goldPricePerUnit && redeemData?.txValueAed
                    ? getRedeemValue()
                    : "0"
                } Gram Gold`}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={3}
              mt={2}
            >
              <Stack direction={"column"}>
                <Typography sx={styles.smallHeading}>Transaction ID</Typography>
                <Typography sx={styles.smallValue}>
                  {redeemData?.transactionId}
                </Typography>
              </Stack>
              <Stack direction={"column"} alignItems={"flex-end"}>
                <Typography sx={styles.smallHeading}>Order Id</Typography>
                <Typography sx={styles.smallValue}>
                  {redeemData?.orderId}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={3}
              my={2}
            >
              <Stack direction={"column"}>
                <Typography sx={styles.smallHeading}>AEG Qty</Typography>
                <Typography sx={styles.smallValue}>
                  {formatNumber(redeemData?.txValue)}
                </Typography>
              </Stack>
              <Stack direction={"column"} alignItems={"flex-end"}>
                <Typography sx={styles.smallHeading}>Status</Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    color: getColorByStatus(redeemData?.status),
                  }}
                >
                  {redeemData?.status}
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={3}
              my={2}
            >
              <Stack direction={"column"}>
                <Typography sx={styles.smallHeading}>Requested Date</Typography>
                <Typography sx={styles.smallValue}>
                  {getTransactionListDateTimeFormat(redeemData?.createdAt)}
                </Typography>
              </Stack>
              <Stack direction={"column"} alignItems={"center"}>
                <Typography sx={styles.smallHeading}>
                  Completion Date
                </Typography>
                <Typography sx={styles.smallValue}>
                  {redeemData?.completedAt
                    ? getTransactionListDateTimeFormat(redeemData?.completedAt)
                    : "-"}
                </Typography>
              </Stack>
            </Stack>
            <Divider />

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={3}
              my={2}
            >
              <Stack direction={"column"}>
                <Typography sx={styles.smallHeading}>User Name</Typography>
                <Typography sx={styles.smallValue}>{`${
                  userData?.firstName ? userData?.firstName : "Anonymous"
                } ${
                  userData?.lastName ? userData?.lastName : "User"
                }`}</Typography>
              </Stack>
              <Stack direction={"column"} alignItems={"center"}>
                <Typography sx={styles.smallHeading}>
                  {"Email Address"}
                </Typography>
                <Typography sx={styles.smallValue}>
                  {userData?.email ? userData?.email : "-"}
                </Typography>
              </Stack>

              <Stack direction={"column"} alignItems={"flex-end"}>
                <Typography sx={styles.smallHeading}>Phone No.</Typography>
                <Typography sx={styles.smallValue}>
                  {userData?.phoneNumber ? userData?.phoneNumber : "-"}
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={3}
              my={2}
            >
              <Stack direction={"column"} sx={{ flex: 1 }}>
                <Typography sx={styles.smallHeading}>Completed By</Typography>
                <Typography sx={styles.smallValue}>
                  {redeemData?.completedBy ? redeemData?.completedBy : "-"}
                </Typography>
              </Stack>
              {redeemData?.status === "Rejected" && (
                <Stack
                  direction={"column"}
                  alignItems={"flex-end"}
                  sx={{ flex: 2 }}
                >
                  <Typography sx={styles.smallHeading}>
                    Rejected Reason
                  </Typography>
                  <Typography sx={styles.smallValue}>
                    {redeemData?.statusReason}
                  </Typography>
                </Stack>
              )}
            </Stack>
            {redeemState === REDEEM_STATE.approve && (
              <Stack direction={"column"} mt={2}>
                <label style={styles.labelText}>Enter Redeem Code</label>
                <TextField
                  sx={styles.textFieldStyle}
                  type={"number"}
                  value={redeemCode}
                  onChange={(event) => {
                    if (event.target.value && event.target.value.length > 0) {
                      setRedeemCodeError("");
                    }
                    setRedeemCode(event.target.value);
                  }}
                  error={redeemCodeError !== "" ? true : false}
                  helperText={redeemCodeError === "" ? "" : redeemCodeError}
                />
              </Stack>
            )}
            {redeemState === REDEEM_STATE.reject && (
              <Stack direction={"column"} mt={2}>
                <label style={styles.labelText}>Reason</label>
                <TextField
                  sx={styles.textFieldStyle}
                  value={reason}
                  onChange={(event) => {
                    if (event.target.value && event.target.value.length > 0) {
                      setReasonError("");
                    }
                    setReason(event.target.value);
                  }}
                  error={reasonError !== "" ? true : false}
                  helperText={reasonError === "" ? "" : reasonError}
                />
              </Stack>
            )}
          </>
        )}
      </DialogContent>
      {redeemData?.status === "Pending" && (
        <DialogActions sx={styles.actionContainer}>
          {redeemState === REDEEM_STATE.none && (
            <>
              <Button
                sx={styles.approveButton}
                onClick={() => {
                  setRedeemState(REDEEM_STATE.approve);
                }}
              >
                Approve
              </Button>
              <Button
                sx={styles.rejectedButton}
                onClick={() => {
                  setRedeemState(REDEEM_STATE.reject);
                }}
              >
                Reject
              </Button>
            </>
          )}{" "}
          {redeemState === REDEEM_STATE.approve && (
            <>
              <Button
                sx={styles.backButton}
                onClick={() => {
                  setRedeemState(REDEEM_STATE.none);
                }}
              >
                Back
              </Button>
              <Button
                sx={styles.approveButton}
                onClick={() => {
                  onApproveClick();
                }}
              >
                Approve
              </Button>
            </>
          )}
          {redeemState === REDEEM_STATE.reject && (
            <>
              <Button
                sx={styles.backButton}
                onClick={() => {
                  setRedeemState(REDEEM_STATE.none);
                }}
              >
                Back
              </Button>
              <Button
                sx={styles.rejectedButton}
                onClick={() => {
                  onRejectedClick();
                }}
              >
                Reject
              </Button>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
export default RedeemDialog;

const styles = {
  dialogTitle: {
    fontSize: "1.3rem",
    fontWeight: 700,
    color: "#FFFFFF",
    marginRight: "1em",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #292929",
  },
  contentStyle: {
    padding: "2em",
  },
  redeemValue: {
    fontSize: "0.9rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  aegText: {
    fontSize: "1.3rem",
    fontWeight: 500,
    color: "#FFFFFF",
  },
  smallHeading: {
    fontSize: "0.8rem",
    fontWeight: 550,
    color: "rgba(200, 200, 200, 1)",
  },
  smallValue: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
  },

  labelText: {
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
  },
  goldBar: {
    width: "2.2em",
    heigh: "2.2em",
  },
  textFieldStyle: {
    mt: 1,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1em 2em 1em 2em",
  },
  approveButton: {
    width: "10em",
    padding: "0.5em 2em",
    background: "rgba(20, 203, 27, 1)",
    borderRadius: "10em",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginRight: "1em",
    "&:hover": {
      background: "rgba(20, 203, 27, 0.6)",
    },
  },
  rejectedButton: {
    width: "10em",
    padding: "0.5em 2em",
    background: "rgba(203, 31, 20, 1)",
    borderRadius: "10em",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginLeft: "1em",
    "&:hover": {
      background: "rgba(203, 31, 20, 0.6)",
    },
  },
  backButton: {
    width: "10em",
    padding: "0.5em 2em",
    background: "rgba(0, 0, 0, 1)",
    borderRadius: "10em",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginRight: "1em",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.2)",
    },
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "3em 5em",
  },
  loadingTitle: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 1)",
    marginBottom: "1em",
  },
  statusIcon: {
    width: "5em",
    height: "5em",
    alignSelf: "center",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "3em",
  },
  statusTitle: {
    fontSize: "1.5rem",
    fontWeight: 900,
    color: "rgba(255, 200, 0, 1)",
    margin: "1em 0em 0.5em 0em",
    alignSelf: "center",
  },
  statusRejectedTitle: {
    fontSize: "1.5rem",
    fontWeight: 900,
    color: "rgba(203, 31, 20, 1)",
    margin: "1em 0em 0.5em 0em",
    alignSelf: "center",
  },
  reasonTitle: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
  },
  reasonDesc: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(131, 128, 127, 1)",
    marginTop: "1em",
  },
  descText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 1)",

    alignSelf: "center",
  },
  trnxSuccessTxt: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 1)",
    marginTop: "1.5em",
    alignSelf: "center",
  },
  trnxIdTitle: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(75, 75, 74, 1)",
    marginTop: "2em",
    alignSelf: "center",
  },
  trnxId: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
    alignSelf: "center",
  },
};
