import { COLOR } from "..";

export default function Pagination(theme: any) {
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: COLOR.primary,
            color: COLOR.background,
            fontWeight: 600,
          },
          "&.Mui-selected:hover": {
            backgroundColor: COLOR.primary,
            color: COLOR.background,
          },
        },
      },
    },
  };
}
