import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../assets";
import TransitionComponent from "./TransitionComponent";
import { UpdateUserStatusRequest } from "../types/UpdateUserStatus";
import { updateUserStatus } from "../api/api";

type Props = {
  open: boolean;
  type: string;
  userId: number;
  onClose: () => void;
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
};

const UserStatusUpdateDialog = (props: Props) => {
  const { open, type, userId, onClose, onError, onSuccess } = props;

  const onConfirmClick = async () => {
    const body: UpdateUserStatusRequest = {
      user_id: userId.toString(),
      status: type === "suspend" ? "Suspended" : "Active",
    };
    console.log("body", body);

    const response = await updateUserStatus(body);
    if (response) {
      if (response.status === 200) {
        onSuccess(response.data.message);
      } else {
        onError(response.data.message);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={TransitionComponent}
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
          minWidth: "380px",
          maxWidth: "380px",
        },
      }}
    >
      <DialogTitle sx={styles.headerRow}>
        <Typography sx={styles.headerTitle} mr={3}>
          Status Confirmation
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography sx={styles.labelText}>
          {type === "suspend"
            ? "Are you sure you want to suspend this user?"
            : "Are you sure you want to activate this user?"}
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={5}
        >
          <Box sx={styles.approveButton} onClick={onConfirmClick}>
            <Typography sx={styles.approvedButtonTxt}>Yes</Typography>
          </Box>
          <Box sx={styles.rejectedButton} onClick={onClose}>
            <Typography sx={styles.rejectedButtonTxt}>No</Typography>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default UserStatusUpdateDialog;

const styles = {
  headerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "1.1rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  labelText: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
    marginBottom: "0.5em",
    textAlign: "center",
  },
  inputStyle: {
    input: {
      color: "white",
      backgroundColor: "transparent",
      fontWeight: 400,
    },
  },
  approveButton: {
    width: "6em",
    height: "2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "#14CB1B",
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(20, 203, 27,0.5)",
      cursor: "pointer",
    },
  },
  approvedButtonTxt: {
    fontSize: "0.9rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  rejectedButton: {
    width: "6em",
    height: "2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "#c71414",
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(199, 20, 20,0.5)",
      cursor: "pointer",
    },
  },
  rejectedButtonTxt: {
    fontSize: "0.9rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
};
