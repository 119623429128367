import { AuthBackground, FormBackground } from "../../assets";
export const useStyles = (isDesktop: boolean) => ({
  container: {
    display: "flex",
    flex: 1,
    height: "100vh",
    padding: isDesktop ? "3em" : "1em",
    backgroundColor: "rgba(10, 10, 10, 1)",
    backgroundImage: `url(${AuthBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "right",
    backgroundSize: "70% 100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  leftColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    paddingRight: "1em",
  },
  rightColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    alignItems: isDesktop ? "end" : "center",
  },
  formContainer: {
    display: "flex",
    width: isDesktop ? "40%" : "100%",
    height: "100%",
    padding: "2em",
    marginBottom: "5%",
    borderRadius: "3em",
    backgroundImage: `url(${FormBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  },

  leftContentHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  leftContentTitle: {
    color: "#FFC800",
    fontSize: "2.5rem",
    fontWeight: 700,
  },
  bulletContainer: {
    display: { xs: "none", sm: "none", md: "flex" },
    flexDirection: "column",
    justifyContent: "center",
  },
  bulletPoints: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0.8em 0",
  },
  bulletTitleText: {
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 600,
    marginLeft: "0.5em",
  },
  infoText: {
    display: { xs: "none", sm: "none", md: "flex" },
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 300,
    textAlign: "justify",
    marginTop: "3em",
  },
  copyrightText: {
    fontSize: "0.9rem",
    width: "100%",
    color: "rgba(107, 107, 107, 1)",
    fontWeight: 500,
    textAlign: "right",
    paddingBottom: "1em",
  },
});
