import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  bankDetailFailure,
  bankDetailRequest,
  bankDetailSuccess,
  bankListFailure,
  bankListRequest,
  bankListSuccess,
} from "../reducers/BankReducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { BankDetailResponse, BankResponse } from "../../types/BankDetailModel";

function* requestBankSaga(action: PayloadAction<string>): any {
  try {
    const response = yield call(axiosInstance.get, `/banks?${action.payload}`);
    if (response.status === 200) {
      const data: BankResponse = response.data;
      yield put(bankListSuccess(data));
    } else {
      yield put(bankListFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      bankListFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* requestBankDetailSaga(action: PayloadAction<number>): any {
  try {
    const response = yield call(
      axiosInstance.get,
      `/user/banks/${action.payload}`
    );
    if (response.status === 200) {
      const data: BankDetailResponse = response.data;
      yield put(bankDetailSuccess(data));
    } else {
      yield put(bankDetailFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      bankDetailFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchBankSaga() {
  yield takeLatest(bankDetailRequest.type, requestBankDetailSaga);
  yield takeLatest(bankListRequest.type, requestBankSaga);
}

export default watchFetchBankSaga;
