import { COLOR } from "../../../theme";

const styles = {
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
    height: "100%",
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "1em",
  },
  backButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: COLOR.background,
    width: "5em",
    height: "2em",
    justifyContent: "center",
    borderRadius: "2em",
    marginBottom: "1em",
    ":hover": {
      cursor: "pointer",
    },
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "8px",
    backgroundColor: "#171616",
    padding: "1em",
    width: "100%",
  },
  profileImageStyle: {
    width: "3em",
    height: "3em",
    borderRadius: "4em",
    padding: "0.2em",
    border: "1px dashed #FFC800",
  },
  userNameText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#FFFFFF",
    marginBottom: "1px",
  },
  roleText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
    marginLeft: "0.5em",
  },
  userNameSectionText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "rgba(255,255,255,0.3)",
  },
  editButtonContainer: {
    border: "1px solid #83807F",
    borderRadius: "5em",
    minWidth: "3em",
  },
  editText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#83807F",
    marginRight: "1em",
  },
  fullWidth: {
    width: "100%",
  },
  textFieldWidth: {
    flex: 1,
    minWidth: "15em",
    marginLeft: "1em",
  },
  regularText: {
    fontSize: "0.9rem",
    fontWeight: 300,
    color: "#FFFFFF",
    marginLeft: "8px",
  },
  notVerified: {
    fontSize: "0.8em",
    fontWeight: 600,
    color: "#888888",
    backgroundColor: "rgba(0,0,0,0.8)",
    padding: "3px 8px",
    borderRadius: "30px",
  },
  imageChooserText: {
    fontWeight: 300,
    fontSize: "0.6rem",
    cursor: "pointer",
    color: "#FFFFFF",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  croppedImageStyle: {
    width: "3.2em",
    height: "3.2em",
    borderRadius: "50px",
    border: "0.5px dashed #FFC018",
    padding: "0.25em",
    backgroundColor: "#000000",
  },
  profileItemText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  inlineCenter: {
    alignItems: "center",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  promoteToAdminButton: {
    width: "11em",
    height: "2.4em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "rgba(0, 48, 2,1)",
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(0, 48, 2,0.4)",
      cursor: "pointer",
    },
  },
  promoteToAdminTxt: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#FFFFFFF",
    textAlign: "center",
  },
};
export default styles;
