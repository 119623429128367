import {
  Box,
  Card,
  CardContent,
  InputAdornment,
  Stack,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  Button,
} from "@mui/material";
import styles from "./AdminUser.styles";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/reducers/RootReducers";
import { adminUsersRequest } from "../../../store/reducers/AdminUserReducer";
import Loader from "../../../components/Loader";
import AdminUsersAddDialog from "../../../components/AdminUsersAddDialog";
import { AlertDataProps } from "../../../types/Common";
import AlertComponent from "../../../components/AlertComponent";
import { ProfileIcon } from "../../../assets";
import { AdminUserData } from "../../../types/AdminUserModel";
import { toPascalCase } from "../../../utils/Utils";

const AdminUsers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminUser = useSelector((state: RootState) => state.adminUserState);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [usersList, setUsersList] = useState<AdminUserData[]>([]);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    dispatch(adminUsersRequest(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (adminUser.result && adminUser.result?.length > 0) {
      setUsersList(adminUser.result);
    } else {
      setUsersList([]);
    }
  }, [adminUser.result]);

  const searchUsers = (searchQuery: string) => {
    if (searchQuery === "" && usersList && usersList.length > 0) {
      setUsersList(usersList);
      return;
    }
    const results = usersList?.filter(
      (adminUser) =>
        adminUser?.firstName
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        adminUser?.lastName
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        adminUser?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        adminUser?.phoneNumber
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    if (results && results.length > 0) {
      setUsersList(results);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      searchUsers(value);
    } else {
      dispatch(adminUsersRequest(""));
    }
  };

  const handleRowClick = (adminId: number) => {
    if (adminId) {
      navigate("user/" + adminId);
    }
  };

  return (
    <Box>
      <Card sx={styles.cardContainerStyle}>
        <CardContent sx={styles.paddingZero}>
          <Stack direction="row" sx={styles.filterContainer}>
            <TextField
              placeholder="Search"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "white" }} />
                  </InputAdornment>
                ),
                style: { color: "white", borderColor: "white" },
              }}
              variant="outlined"
              onChange={handleSearchChange}
              sx={styles.searchInputStyle}
            />
            <Stack direction="row" alignItems={"center"} gap={2}>
              <Button
                sx={styles.addAdminButton}
                onClick={() => setShowAddUserDialog(true)}
              >
                Add Admin User
              </Button>
              {/* <FormControl
                variant="outlined"
                sx={{
                  minWidth: 160,
                  height: "35px !important",
                  maxHeight: "35px !important",
                }}
              >
                <Select
                  value={filterStatus}
                  onChange={handleStatusChange}
                  displayEmpty
                  sx={{
                    color: "white",
                    borderColor: "white",
                    height: "35px !important",
                    maxHeight: "35px !important",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>Choose Status</em>
                  </MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="Unverified">Unverified</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>
          </Stack>

          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={styles.tableTitleRowBorder}>
                  <TableCell align="center">#</TableCell>
                  <TableCell sx={styles.leftAlignText}>User Name</TableCell>
                  <TableCell sx={styles.leftAlignText}>Email Address</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminUser?.loading ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box sx={styles.loadingRow}>
                        <Loader />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : usersList && usersList?.length > 0 ? (
                  usersList.map((user, index) => (
                    <TableRow
                      key={index}
                      sx={styles.tableTitleRowBorder}
                      onClick={() => handleRowClick(user?.adminId)}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                          {user.profilePic ? (
                            <img
                              src={user.profilePic}
                              alt={user.email}
                              style={styles.profileImageStyle}
                            />
                          ) : (
                            <ProfileIcon height={"3em"} width={"3em"} />
                          )}
                          <Typography>
                            {`${
                              user?.firstName ? user?.firstName : "Anonymous"
                            } ${user?.lastName ? user?.lastName : "User"}`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell sx={styles.leftAlignText}>
                        {user?.email}
                      </TableCell>
                      <TableCell>
                        {user?.phoneNumber ? user?.phoneNumber : "-"}
                      </TableCell>
                      <TableCell>{user?.AdminRole}</TableCell>
                      <TableCell>{toPascalCase(user?.status)}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography sx={styles.sorryText}>Sorry!!</Typography>
                      <Typography sx={styles.noDataRow}>
                        No Data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
      {showAddUserDialog && (
        <AdminUsersAddDialog
          open={showAddUserDialog}
          handleClose={() => setShowAddUserDialog(false)}
          onError={(error) => {
            setAlertData({ message: error, type: "error" });
            setShowAddUserDialog(false);
          }}
          onSuccess={(message) => {
            setAlertData({ message: message, type: "error" });
            setShowAddUserDialog(false);
            dispatch(adminUsersRequest(""));
          }}
        />
      )}
    </Box>
  );
};
export default AdminUsers;
