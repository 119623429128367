import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  LoginCipherResponse,
  LoginRequest,
  LoginResponse,
} from "../../types/LoginModel";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  loginFailure,
  loginRequest,
  loginSuccess,
} from "../reducers/AuthReducer";
import { apiDecrypt } from "../../utils/Encryption";

function* requestLoginSaga(action: PayloadAction<LoginRequest>): any {
  try {
    const response = yield call(axiosInstance.post, "/login", action.payload);
    if (response.status === 200) {
      if (response?.data && response?.data?.dataCipher) {
        const data: LoginCipherResponse = response.data;
        const plainText: LoginResponse = JSON.parse(
          apiDecrypt(data.dataCipher)
        );
        yield put(
          loginSuccess({ token: plainText.data.token, result: plainText.data })
        );
      } else {
        const data: LoginResponse = response.data;
        yield put(loginSuccess({ token: data.data.token, result: data.data }));
      }
    } else {
      yield put(loginFailure(response.response.data.message));
    }
  } catch (error: any) {
    yield put(
      loginFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchAuthSaga() {
  yield takeLatest(loginRequest.type, requestLoginSaga);
}

export default watchFetchAuthSaga;
