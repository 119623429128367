import { COLOR } from "..";

export default function Tab(theme: any) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          fontWeight: 500,
          "&.Mui-selected": {
            color: COLOR.primary,
            fontWeight: 600,
          },
          "&.Mui-selected:hover": {
            color: COLOR.primary,
            fontWeight: 600,
          },
        },
      },
    },
  };
}
