import React from "react";

type Props = {
    width?: number;
    height?: number;
};

const RedeemIcon = (props: Props) => {
    const { width = 43, height = 19 } = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="52" height="52" rx="26" fill="white" />
            <path d="M32.5944 31.8624L24.4382 16.3476L29.0699 14.3647C29.5506 14.159 30.1086 14.3582 30.3503 14.8218L38.0087 29.5148L32.5944 31.8624Z" fill="black" />
            <path d="M31.7267 32.5769L23.2581 16.6879L20.5426 17.6075C19.9627 17.8039 19.6938 18.4706 19.9752 19.0143L29.0195 36.4896L31.7267 32.5769Z" fill="black" />
            <path d="M30.1649 37L32.5944 33.0873L38.321 30.5695L38.5668 32.3524C38.627 32.7888 38.3944 33.2132 37.9942 33.3974L30.1649 37Z" fill="black" />
            <path d="M39.2928 20.7027L35.6833 22.4038L38.321 27.5074L40.8996 26.6966C41.4803 26.514 41.7645 25.8593 41.5014 25.3104L39.2928 20.7027Z" fill="black" />
            <path d="M34.9892 21.3151L33.0803 17.9808L38.6681 19.8521L34.9892 21.3151Z" fill="black" />
            <path d="M25.8959 32.8151L20.551 22.8121L14.1649 24.9896L20.7592 27.7796V35.0947L25.8959 32.8151Z" fill="black" />
            <path d="M19.6486 28.392L13.4013 25.8062L10.6463 30.0502C10.3087 30.5704 10.521 31.2693 11.0909 31.5137L19.4403 35.0947L19.6486 28.392Z" fill="black" />
        </svg>

    );
};
export default RedeemIcon;
