import { COLOR } from "../../theme";

const styles = {
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
    height: "95%",
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "1em",
  },
  tableContainer: {
    paddingLeft: "1em",
    paddingRight: "1em",
    marginTop: "1em",
    height: "60vh",
  },
  boxButton: {
    backgroundColor: "rgba(183, 181, 179, 1)",

    borderRadius: "3em",
    padding: "0.5em 1.5em",
    height: "35px !important",
    maxHeight: "35px !important",
    "&:hover": {
      transitions: "all 0.5s ease",
      cursor: "pointer",
      backgroundColor: "rgba(183, 181, 179, 0.5)",
    },
  },
  downloadBtnText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#000000",
  },
  boldRow: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: 600,
    textAlign: "center",
  },
  customRows: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: 350,
    textAlign: "center",
  },
  totalRecordsStyle: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: 650,
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      textAlign: "center",
      color: "#FFFFFF",
      backgroundColor: "#1C1C1C",
    },
  },
  profileImageStyle: {
    width: "2.8em",
    height: "2.8em",
    maxWidth: "2.8em",
    minWidth: "2.8em",
    maxHeight: "2.8em",
    minHeight: "2.8em",
  },
  searchInputStyle: {
    input: { color: "white" },
    borderColor: "white",
    width: "300px",
    height: "40px !important",
    padding: "0px",
  },
  filterContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#171616",
    padding: "1em",
  },
  loadingRow: {
    padding: "3em",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noDataRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: COLOR.offWhite,
    marginBottom: "6em",
  },
  sorryText: {
    fontSize: "1.8rem",
    fontWeight: 700,
    color: "#51547B",
    marginTop: "2em",
  },
};
export default styles;
