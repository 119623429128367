import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import { sellRequest, sellSuccess, sellFailure } from "../reducers/SellReducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { SellResponse } from "../../types/SellModel";

function* requestSellSaga(action: PayloadAction<string>): any {
  try {
    let uri = action.payload ? `/sell?${action.payload}` : "/sell";
    const response = yield call(axiosInstance.get, uri);
    if (response.status === 200) {
      const data: SellResponse = response.data;
      yield put(sellSuccess(data));
    } else {
      yield put(sellFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      sellFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchSellSaga() {
  yield takeLatest(sellRequest.type, requestSellSaga);
}

export default watchFetchSellSaga;
