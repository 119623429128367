import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const RedeemRequestIcon = (props: Props) => {
  const { width = 32, height = 32 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none">
      <path
        d="M23.7234 24.3092L16.4231 10.1435L20.4689 8.37665C20.9534 8.16504 21.519 8.36722 21.7596 8.83807L28.5695 22.1657L23.7234 24.3092Z"
        fill="currentColor"
      />
      <path
        d="M22.9467 24.9615L15.3669 10.4541L13.034 11.26C12.4607 11.4581 12.1943 12.1168 12.4688 12.6577L20.5237 28.534L22.9467 24.9615Z"
        fill="currentColor"
      />
      <path
        d="M21.5488 29L23.7234 25.4275L28.8491 23.1287L29.0615 24.7002C29.12 25.133 28.8908 25.5538 28.4954 25.7394L21.5488 29Z"
        fill="currentColor"
      />
      <path
        d="M29.7189 14.1198L26.4882 15.6731L28.8491 20.3328L31.0584 19.6242C31.6329 19.4399 31.9146 18.7927 31.6581 18.2467L29.7189 14.1198Z"
        fill="currentColor"
      />
      <path
        d="M25.8669 14.679L24.1583 11.6346L29.1598 13.3432L25.8669 14.679Z"
        fill="currentColor"
      />
      <path
        d="M17.7278 25.179L12.9438 16.0459L7.22781 18.034L13.1302 20.5814V27.2604L17.7278 25.179Z"
        fill="currentColor"
      />
      <path
        d="M12.1361 21.1405L6.54438 18.7796L4.12685 22.5786C3.79744 23.0962 4.00757 23.7857 4.56969 24.0316L11.9497 27.2604L12.1361 21.1405Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default RedeemRequestIcon;
