import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const UsersIcon = (props: Props) => {
  const { width = 28, height = 28 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none">
      <path
        d="M13.75 19.1667C12.7116 19.1667 11.6966 18.8588 10.8333 18.2819C9.9699 17.705 9.29699 16.8851 8.89963 15.9258C8.50227 14.9664 8.3983 13.9108 8.60088 12.8924C8.80345 11.874 9.30346 10.9386 10.0377 10.2044C10.7719 9.47013 11.7074 8.97012 12.7258 8.76755C13.7442 8.56497 14.7998 8.66894 15.7591 9.0663C16.7184 9.46366 17.5383 10.1366 18.1152 10.9999C18.6921 11.8633 19 12.8783 19 13.9167C18.9985 15.3086 18.4448 16.643 17.4606 17.6273C16.4764 18.6115 15.1419 19.1651 13.75 19.1667ZM22.5 27.3333C22.4981 25.7868 21.883 24.3042 20.7894 23.2106C19.6958 22.117 18.2132 21.5019 16.6667 21.5H10.8333C9.28681 21.5019 7.80415 22.117 6.71059 23.2106C5.61703 24.3042 5.00185 25.7868 5 27.3333L5 32H22.5V27.3333ZM25.4167 14.5C24.3783 14.5 23.3633 14.1921 22.4999 13.6152C21.6366 13.0383 20.9637 12.2184 20.5663 11.2591C20.1689 10.2998 20.065 9.24418 20.2675 8.22578C20.4701 7.20738 20.9701 6.27192 21.7044 5.53769C22.4386 4.80347 23.374 4.30345 24.3924 4.10088C25.4108 3.89831 26.4664 4.00227 27.4258 4.39963C28.3851 4.79699 29.205 5.4699 29.7819 6.33326C30.3588 7.19662 30.6667 8.21165 30.6667 9.25C30.6651 10.6419 30.1115 11.9764 29.1273 12.9606C28.143 13.9448 26.8086 14.4985 25.4167 14.5ZM27.1667 16.8333H21.3333C21.1286 16.8427 20.9245 16.8634 20.722 16.8952C20.2978 17.8767 19.6713 18.7577 18.8833 19.4805C20.594 19.9645 22.1003 20.993 23.174 22.41C24.2477 23.8271 24.8302 25.5555 24.8333 27.3333H33V22.6667C32.9981 21.1201 32.383 19.6375 31.2894 18.5439C30.1958 17.4504 28.7132 16.8352 27.1667 16.8333Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default UsersIcon;
