import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const ActiveUsersIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 52 52" fill="none">
      <path
        d="M20.75 27.1667C19.7116 27.1667 18.6966 26.8588 17.8333 26.2819C16.9699 25.705 16.297 24.8851 15.8996 23.9258C15.5023 22.9664 15.3983 21.9108 15.6009 20.8924C15.8034 19.874 16.3035 18.9386 17.0377 18.2044C17.7719 17.4701 18.7074 16.9701 19.7258 16.7675C20.7442 16.565 21.7998 16.6689 22.7591 17.0663C23.7184 17.4637 24.5383 18.1366 25.1152 18.9999C25.6921 19.8633 26 20.8783 26 21.9167C25.9985 23.3086 25.4448 24.643 24.4606 25.6273C23.4764 26.6115 22.1419 27.1651 20.75 27.1667ZM29.5 35.3333C29.4981 33.7868 28.883 32.3042 27.7894 31.2106C26.6958 30.117 25.2132 29.5019 23.6667 29.5H17.8333C16.2868 29.5019 14.8042 30.117 13.7106 31.2106C12.617 32.3042 12.0019 33.7868 12 35.3333L12 40H29.5V35.3333ZM32.4167 22.5C31.3783 22.5 30.3633 22.1921 29.4999 21.6152C28.6366 21.0383 27.9637 20.2184 27.5663 19.2591C27.1689 18.2998 27.065 17.2442 27.2675 16.2258C27.4701 15.2074 27.9701 14.2719 28.7044 13.5377C29.4386 12.8035 30.374 12.3035 31.3924 12.1009C32.4108 11.8983 33.4664 12.0023 34.4258 12.3996C35.3851 12.797 36.205 13.4699 36.7819 14.3333C37.3588 15.1966 37.6667 16.2117 37.6667 17.25C37.6651 18.6419 37.1115 19.9764 36.1273 20.9606C35.143 21.9448 33.8086 22.4985 32.4167 22.5ZM34.1667 24.8333H28.3333C28.1286 24.8427 27.9245 24.8634 27.722 24.8952C27.2978 25.8767 26.6713 26.7577 25.8833 27.4805C27.594 27.9645 29.1003 28.993 30.174 30.41C31.2477 31.8271 31.8302 33.5555 31.8333 35.3333H40V30.6667C39.9981 29.1201 39.383 27.6375 38.2894 26.5439C37.1958 25.4504 35.7132 24.8352 34.1667 24.8333Z"
        fill="black"
      />
    </svg>
  );
};
export default ActiveUsersIcon;
