import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const CircleCheckedIcon = (props: Props) => {
  const { width = 22, height = 22 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#83807F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8636 7.91162C17.2517 7.36833 17.1258 6.61333 16.5825 6.22527C16.0393 5.83721 15.2843 5.96304 14.8962 6.50633L9.68125 13.8072L7.06368 11.1897C6.59159 10.7176 5.82617 10.7176 5.35407 11.1897C4.88198 11.6618 4.88198 12.4272 5.35407 12.8993L8.98071 16.5259C9.23198 16.7772 9.58108 16.9052 9.93522 16.8759C10.2894 16.8466 10.6127 16.6629 10.8192 16.3738L16.8636 7.91162Z"
        fill="black"
      />
    </svg>
  );
};
export default CircleCheckedIcon;
