import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const CircleStackIcon = (props: Props) => {
  const { width = 18, height = 18 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 5.3125C17.5 7.55616 14.1421 9.375 10 9.375C5.85786 9.375 2.5 7.55616 2.5 5.3125C2.5 3.06884 5.85786 1.25 10 1.25C14.1421 1.25 17.5 3.06884 17.5 5.3125Z"
        fill="white"
      />
      <path
        d="M10 10.625C12.2377 10.625 14.3254 10.1364 15.8987 9.28424C16.4736 8.97278 17.0206 8.58695 17.4789 8.13062C17.4929 8.23192 17.5 8.33426 17.5 8.4375C17.5 10.6812 14.1421 12.5 10 12.5C5.85786 12.5 2.5 10.6812 2.5 8.4375C2.5 8.33426 2.50711 8.23192 2.52108 8.13062C2.97936 8.58695 3.52635 8.97278 4.10135 9.28424C5.67457 10.1364 7.76234 10.625 10 10.625Z"
        fill="white"
      />
      <path
        d="M10 13.75C12.2377 13.75 14.3254 13.2614 15.8987 12.4092C16.4736 12.0978 17.0206 11.7119 17.4789 11.2556C17.4929 11.3569 17.5 11.4593 17.5 11.5625C17.5 13.8062 14.1421 15.625 10 15.625C5.85786 15.625 2.5 13.8062 2.5 11.5625C2.5 11.4593 2.50711 11.3569 2.52108 11.2556C2.97936 11.7119 3.52635 12.0978 4.10135 12.4092C5.67457 13.2614 7.76234 13.75 10 13.75Z"
        fill="white"
      />
      <path
        d="M10 16.875C12.2377 16.875 14.3254 16.3864 15.8987 15.5342C16.4736 15.2228 17.0206 14.8369 17.4789 14.3806C17.4929 14.4819 17.5 14.5843 17.5 14.6875C17.5 16.9312 14.1421 18.75 10 18.75C5.85786 18.75 2.5 16.9312 2.5 14.6875C2.5 14.5843 2.50711 14.4819 2.52108 14.3806C2.97936 14.8369 3.52635 15.2228 4.10135 15.5342C5.67457 16.3864 7.76234 16.875 10 16.875Z"
        fill="white"
      />
    </svg>
  );
};
export default CircleStackIcon;
