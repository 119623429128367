import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { BankList } from "../types/BankDetailModel";
import { CloseIcon } from "../assets";
import TransitionComponent from "./TransitionComponent";
import { getTransactionListDateTimeFormat } from "../utils/DateUtils";
import { requestApproveBankApi, requestRejectBankApi } from "../api/api";
import { useState } from "react";
type Props = {
  open: boolean;
  handleClose: () => void;
  bankDetails: BankList;
  onSuccess: (message: string) => void;
  onError: (error: string) => void;
};
const BankDetailsDialog = (props: Props) => {
  const { open, handleClose, bankDetails, onSuccess, onError } = props;
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [rejectMessage, setRejectMessage] = useState<string | null>(null);
  const [rejectMessageError, setRejectMessageError] = useState<string | null>(
    null
  );

  const approveBankHandler = async () => {
    try {
      const response = await requestApproveBankApi(bankDetails.bankId);
      if (response) {
        console.log("Approve Response: ", response);
        if (response.status === 200) {
          onSuccess(response.data.message);
        } else {
          onError(response.data.message);
        }
      }
    } catch (error) {}
  };

  const rejectBankHandler = async () => {
    try {
      if (rejectMessage) {
        if (rejectMessage.length > 4) {
          const response = await requestRejectBankApi(bankDetails.bankId, {
            reason: rejectMessage,
          });
          if (response) {
            console.log("Rejected Response: ", response);
            if (response.status === 200) {
              onSuccess(response.data.message);
            } else {
              onError(response.data.message);
            }
          }
        } else {
          setRejectMessageError("Enter at least 5 characters");
        }
      } else {
        setRejectMessageError("Please enter message");
      }
    } catch (error) {}
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
      onClose={handleClose}
    >
      <DialogContent sx={styles.container}>
        <Box sx={styles.headerStyle} mb={0.5}>
          <Typography sx={styles.dialogTitle}>Bank Details</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Table>
          <TableBody>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Record Id</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.bankId}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>User Name</TableCell>
              <TableCell sx={styles.tableColValue}>
                {`${
                  bankDetails.firstName ? bankDetails.firstName : "Anonymous"
                } ${bankDetails.lastName ? bankDetails.lastName : "User"}`}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Bank Name</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.bankName}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Bank Account</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.accountNumber}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Swift/BIC Code</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.swiftBic}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>IBAN Code</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.iban}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Beneficiary</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.beneficiaryName}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>
                Is Primary Account
              </TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.isPrimary === 1 ? "Yes" : "No"}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Status</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.status === "active" ? "Active" : "Inactive"}
              </TableCell>
            </TableRow>

            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Beneficiary</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.beneficiaryName}
              </TableCell>
            </TableRow>
            {bankDetails?.verificationStatus?.toLowerCase() === "rejected" && (
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.tableColHeader}>
                  Rejected Reason {bankDetails?.verificationStatus}
                </TableCell>
                <TableCell sx={styles.tableColValue}>
                  {bankDetails.rejectionReason}
                </TableCell>
              </TableRow>
            )}

            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>
                Approve/Rejected Date
              </TableCell>
              <TableCell sx={styles.tableColValue}>
                {getTransactionListDateTimeFormat(bankDetails.updatedAt)}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>
                Approve/Rejected By Admin
              </TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.verifiedBy}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {showRejectForm && (
          <>
            <Divider />
            <Stack direction={"column"} mt={2}>
              <label>Rejected Reason</label>
              <TextField
                type="text"
                multiline={true}
                minRows={3}
                value={rejectMessage}
                onChange={(event) => {
                  if (event.target.value && event.target.value.length > 0) {
                    setRejectMessageError(null);
                  }
                  setRejectMessage(event.target.value);
                }}
                error={rejectMessageError === null ? false : true}
                helperText={
                  rejectMessageError !== null ? rejectMessageError : ""
                }
              />
            </Stack>
          </>
        )}
      </DialogContent>
      {bankDetails.verificationStatus === "Pending" && (
        <DialogActions>
          {showRejectForm === false ? (
            <Box sx={styles.actionRow}>
              <Button sx={styles.approveBtn} onClick={approveBankHandler}>
                Approve
              </Button>
              <Button
                sx={styles.rejectBtn}
                onClick={() => setShowRejectForm(true)}
              >
                Reject
              </Button>
            </Box>
          ) : (
            <Box sx={styles.actionRow}>
              <Button
                sx={styles.backBtn}
                onClick={() => setShowRejectForm(false)}
              >
                Back
              </Button>
              <Button sx={styles.rejectBtn} onClick={rejectBankHandler}>
                Reject
              </Button>
            </Box>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
export default BankDetailsDialog;
const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  headerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "#FFFFFF",
  },
  tableColHeader: {
    fontWeight: 400,
    color: "#ffffff",
  },
  tableColValue: {
    fontWeigth: 400,
    color: "#d9d9d9",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  tableRow: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
    },
    "&:last-child td, &:last-child th": { border: 0 },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
      transition: "all 0.3s ease-in-out",
    },
    cursor: "pointer",
  },
  approveBtn: {
    width: "10em",
    padding: "0.5em 2em",
    background: "rgba(20, 203, 27, 1)",
    borderRadius: "10em",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginRight: "1em",
    "&:hover": {
      background: "rgba(20, 203, 27, 0.6)",
    },
  },
  rejectBtn: {
    width: "10em",
    padding: "0.5em 2em",
    background: "rgba(203, 31, 20, 1)",
    borderRadius: "10em",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginLeft: "1em",
    "&:hover": {
      background: "rgba(203, 31, 20, 0.6)",
    },
  },
  actionRow: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5em 1em",
  },
  backBtn: {
    width: "10em",
    padding: "0.5em 2em",
    background: "rgba(0, 0, 0, 1)",
    borderRadius: "10em",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginRight: "1em",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.2)",
    },
  },
};
