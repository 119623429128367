import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import styles from "../DashboardPage.styles";

type Props = {
  icon: any;
  title: string;
  currency: string;
  value: string;
};
const StatisticsCard = (props: Props) => {
  const { icon, title, currency, value } = props;

  return (
    <Card sx={styles.itemCardContainer} elevation={3}>
      <CardContent>
        <Stack direction={"row"} sx={styles.itemCenter}>
          <Box sx={styles.iconContainer}>{icon}</Box>
          <Typography sx={styles.statTitleText} ml={2}>
            {title}
          </Typography>
        </Stack>
      </CardContent>
      <Box sx={styles.statValue}>
        <Typography sx={styles.statValueText}>
          <span style={styles.statValueCurrecy}>{currency}</span>
          {value}
        </Typography>
      </Box>
    </Card>
  );
};
export default StatisticsCard;
