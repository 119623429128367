import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  userDetailFailure,
  userDetailRequest,
  userDetailSuccess,
} from "../reducers/UserDetailReducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { UserDetailResponse } from "../../types/UserDetailModel";

function* requestUserDetailSaga(action: PayloadAction<number>): any {
  try {
    const response = yield call(axiosInstance.get, `/user/${action.payload}`);
    if (response.status === 200) {
      const data: UserDetailResponse = response.data;
      yield put(userDetailSuccess(data));
    } else {
      yield put(userDetailFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      userDetailFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchUserDetailSaga() {
  yield takeLatest(userDetailRequest.type, requestUserDetailSaga);
}

export default watchFetchUserDetailSaga;
