import React from "react";

type Props = {
  width?: string;
  height?: string;
};

const LogoBlackAndWhite = (props: Props) => {
  const { width = "2em", height = "2em" } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 52 52" fill="none">
      <path
        d="M49.2817 35.9627L40.7427 25.7451L15.1987 25.8181L21.0503 32.4852C22.9904 34.6954 25.7887 35.9627 28.7296 35.9627H49.2817Z"
        fill="black"
      />
      <path
        d="M15.1987 25.7451H40.7427L17.1693 27.9346L15.1987 25.7451Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M15.1987 25.8181L40.7427 25.7451L17.0963 28.0076L15.1987 25.8181Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M17.0962 27.9346L40.7426 25.7451L18.9937 30.1971L17.0962 27.9346Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M27.7689 16.0381H2.71826L7.67992 22.1273C9.62024 24.5088 12.529 25.8908 15.6009 25.8908L40.7423 25.7448L35.6326 19.7317C33.6914 17.3919 30.809 16.0381 27.7689 16.0381Z"
        fill="black"
      />
    </svg>
  );
};
export default LogoBlackAndWhite;
