import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const TotalPurchaseIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 52 52" fill="none">
      <path
        d="M20.5085 20.3078C20.0523 19.8517 20.0523 19.1143 20.5085 18.6582C20.9647 18.202 21.702 18.202 22.1582 18.6582L24.8333 21.3333V13.1667C24.8333 12.5215 25.3548 12 26 12C26.6452 12 27.1667 12.5215 27.1667 13.1667V21.3333L29.8418 18.6582C30.298 18.202 31.0353 18.202 31.4915 18.6582C31.9477 19.1143 31.9477 19.8517 31.4915 20.3078L27.6497 24.1497C27.1982 24.6012 26.6043 24.8287 26.0105 24.831L26 24.8333L25.9895 24.831C25.3957 24.8287 24.8018 24.6012 24.3503 24.1497L20.5085 20.3078ZM36.5 26H33C31.7132 26 30.6667 27.0465 30.6667 28.3333C30.6667 29.6202 29.6202 30.6667 28.3333 30.6667H23.6667C22.3798 30.6667 21.3333 29.6202 21.3333 28.3333C21.3333 27.0465 20.2868 26 19 26H15.5C13.5703 26 12 27.5703 12 29.5V34.1667C12 37.3832 14.6168 40 17.8333 40H34.1667C37.3832 40 40 37.3832 40 34.1667V29.5C40 27.5703 38.4297 26 36.5 26Z"
        fill="black"
      />
    </svg>
  );
};
export default TotalPurchaseIcon;
