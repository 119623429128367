import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  redeemListSuccess,
  redeemDetailFailure,
  redeemDetailRequest,
  redeemDetailSuccess,
  redeemListFailure,
  redeemListRequest,
} from "../reducers/RedeemReducer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  RedeemDetailResponse,
  RedeemListResponse,
} from "../../types/RedeemListModel";

function* requestRedeemListSaga(action: PayloadAction<string>): any {
  try {
    let uri = action.payload ? `/redeem?${action.payload}` : "/redeem";
    const response = yield call(axiosInstance.get, uri);
    if (response.status === 200) {
      const data: RedeemListResponse = response.data;
      yield put(redeemListSuccess(data));
    } else {
      yield put(redeemListFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      redeemListFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* requestRedeemDetailSaga(action: PayloadAction<number>): any {
  try {
    const response = yield call(axiosInstance.get, `/redeem/${action.payload}`);
    if (response.status === 200) {
      const data: RedeemDetailResponse = response.data;
      yield put(redeemDetailSuccess(data));
    } else {
      yield put(redeemDetailFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      redeemDetailFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchRedeemSaga() {
  yield takeLatest(redeemListRequest.type, requestRedeemListSaga);
  yield takeLatest(redeemDetailRequest.type, requestRedeemDetailSaga);
}

export default watchFetchRedeemSaga;
