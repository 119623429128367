import Card from "./Card";
import Paper from "./Paper";
import Input from "./Input";
import Button from "./Button";
import Tooltip from "./Tooltip";
import Backdrop from "./Backdrop";
import Typography from "./Typography";
import CssBaseline from "./CssBaseline";
import Autocomplete from "./Autocomplete";
import { Theme } from "@mui/material";
import Pagination from "./Pagination";
import Tab from "./Tab";

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Card(theme),
    Input(theme),
    Paper(theme),
    Button(theme),
    Tooltip(theme),
    Backdrop(theme),
    Typography(theme),
    CssBaseline(theme),
    Autocomplete(theme),
    Pagination(theme),
    Tab(theme)
  );
}
