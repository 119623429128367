import { COLOR } from "../../theme";

const styles = {
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "1em",
  },
  tableContainer: {
    paddingLeft: "1em",
    paddingRight: "1em",
    marginTop: "1em",
    height: "60vh",
  },
  rowText: {
    color: "#FFFFFF",
    fontSize: "0.8rem",
    fontWeight: 400,
    textAlign: "center",
  },
  customRows: {
    color: "#FFFFFF",
    fontSize: "0.8rem",
    fontWeight: 400,
    textAlign: "center",
  },
  boldText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      textAlign: "center",
      color: "#FFFFFF",
      backgroundColor: "#1C1C1C",
    },
  },
  iconImageStyle: {
    width: "2.5em",
    height: "2.5em",
  },
  searchInputStyle: {
    input: { color: "white" },
    borderColor: "white",
    width: "300px",
    height: "40px !important",
    padding: "0px",
  },
  filterContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#171616",
    padding: "1em",
  },
  totalRecordsStyle: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: 650,
  },
  addNewButton: {
    backgroundColor: "rgba(20, 203, 27, 1)",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
    padding: "0.5em 1.5em",
    "&:hover": {
      transitions: "all 0.5s ease",
      backgroundColor: "rgba(20, 203, 27, 0.5)",
    },
  },
  centerAlign: {
    textAlign: "center",
    backgroundColor: "transparent",
  },
};
export default styles;
