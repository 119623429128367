import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const DocumentClipIcon = (props: Props) => {
  const { width = 18, height = 18 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.25153 5H9.37305L9.375 5H12.1915C13.7429 5.00223 14.9999 6.26058 14.9999 7.8125V15.625C16.3806 15.625 17.4999 14.5057 17.4999 13.125V5.09018C17.4999 3.83568 16.5626 2.74717 15.2799 2.6407C15.0934 2.62522 14.9066 2.61101 14.7194 2.59806C14.3028 1.79706 13.4653 1.25 12.5 1.25H11.25C10.2847 1.25 9.44723 1.79706 9.03064 2.59804C8.84339 2.611 8.65649 2.62522 8.46995 2.6407C7.21804 2.74461 6.29518 3.78391 6.25153 5ZM11.25 2.5C10.5596 2.5 10 3.05964 10 3.75H13.75C13.75 3.05964 13.1904 2.5 12.5 2.5H11.25Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 7.8125C2.5 6.94956 3.19956 6.25 4.0625 6.25H12.1875C13.0504 6.25 13.75 6.94956 13.75 7.8125V17.1875C13.75 18.0504 13.0504 18.75 12.1875 18.75H4.0625C3.19956 18.75 2.5 18.0504 2.5 17.1875V7.8125ZM10.488 11.6404C10.7037 11.3709 10.66 10.9776 10.3904 10.762C10.1209 10.5463 9.72759 10.59 9.51196 10.8596L7.44817 13.4393L6.69194 12.6831C6.44786 12.439 6.05214 12.439 5.80806 12.6831C5.56398 12.9271 5.56398 13.3229 5.80806 13.5669L7.05806 14.8169C7.18377 14.9426 7.35706 15.0089 7.53456 14.999C7.71207 14.9892 7.87699 14.9043 7.98804 14.7654L10.488 11.6404Z"
        fill="white"
      />
    </svg>
  );
};
export default DocumentClipIcon;
