import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  CakeIcon,
  CloseIcon,
  EditPencilIcon,
  EmailIcon,
  PhoneIcon,
  ProfileIcon,
  UserIcon,
} from "../../../assets";
import { Form, FormikProvider, useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import PrimaryButton from "../../../components/PrimaryButton";
import { getDateOfBirthFormat } from "../../../utils/DateUtils";
import WalletIcon from "../../../assets/svg/WalletIcon";
import TrendingDownIcon from "../../../assets/svg/TrendingDownIcon";
import TrendingUpIcon from "../../../assets/svg/TrendingUpIcon";
import TrendingStableIcon from "../../../assets/svg/TrendingStableIcon";
import styles from "../UsersPage.styles";
import dayjs, { Dayjs } from "dayjs";
import { ProfileUpdateSchema } from "../../../validations/Validation";
import { UpdateProfileRequest } from "../../../types/UpdateProfile";
import { useEffect, useRef, useState } from "react";
import { requestUpdateProfile, uploadProfileImage } from "../../../api/api";
import { AlertDataProps } from "../../../types/Common";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/reducers/RootReducers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getBankName,
  maskBankAccount,
  toFixedDecimal,
} from "../../../utils/Utils";
import NoDataFound from "../../../components/NoDataFound";
import VerifiedIcon from "@mui/icons-material/Verified";
import BankIcon from "../../../assets/svg/BankIcon";
import AlertComponent from "../../../components/AlertComponent";
import { userDetailRequest } from "../../../store/reducers/UserDetailReducer";
import { userWalletRequest } from "../../../store/reducers/UserWalletReducer";
import { bankDetailRequest } from "../../../store/reducers/BankReducer";
import { useLocation, useNavigate } from "react-router-dom";
import BankDetailsDialog from "../../../components/BankDetailsDialog";
import { BankList } from "../../../types/BankDetailModel";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ProfileImageUploadRequest } from "../../../types/ProfileImageModel";
import BackArrowIcon from "../../../assets/svg/BackArrowIcon";
import UserStatusUpdateDialog from "../../../components/UserStatusUpdateDialog";
import PromoteToAdminDialog from "../../../components/PromoteToAdminDialog";
import UpdatePasswordDialog from "../../../components/UpdatePasswordDialog";

const UserDetailPage = () => {
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [crop, setCrop] = useState<Crop>();
  const [showStatusUpdateDialog, setShowStatusUpdateDialog] = useState<
    string | null
  >(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const userDetail = useSelector((state: RootState) => state.userDetailState);
  const userWallet = useSelector((state: RootState) => state.userWalletState);
  const userBank = useSelector((state: RootState) => state.bankState);
  const [showBankDetails, setShowBankDetails] = useState<BankList | null>(null);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [showPromoteToAdmin, setShowPromoteToAdmin] = useState<boolean>(false);
  const [updatePasswordDialog, setShowUpdatePasswordDialog] = useState(false);

  const onPageLoad = () => {
    const userId = parseInt(location.pathname.split("/")[4]);
    dispatch(userDetailRequest(userId));
    dispatch(userWalletRequest(userId));
    dispatch(bankDetailRequest(userId));
  };

  useEffect(() => {
    resetForm();
    onPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserProfile = async (body: UpdateProfileRequest) => {
    console.log("Body: ", body);

    const response = await requestUpdateProfile(
      userDetail?.result?.userId ? userDetail?.result?.userId : 0,
      body
    );
    if (response) {
      if (response.status === 200) {
        setAlertData({
          message: response.data.message,
          type: "success",
        });
        setEdit(false);
        if (userDetail?.result?.userId) {
          dispatch(userDetailRequest(userDetail?.result?.userId));
        }
      } else {
        if (response?.data?.message) {
          setAlertData({
            message: response.data.message,
            type: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (userDetail?.result?.dob) {
      setSelectedDate(dayjs(userDetail?.result?.dob));
    }
    if (userDetail?.result?.phoneNumber) {
      formik.setFieldValue("phone_number", userDetail?.result?.phoneNumber);
    }
    if (userDetail?.result?.firstName) {
      formik.setFieldValue("first_name", userDetail?.result?.firstName);
    }
    if (userDetail?.result?.lastName) {
      formik.setFieldValue("last_name", userDetail?.result?.lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail?.result]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
    },
    validationSchema: ProfileUpdateSchema,
    onSubmit: (values) => {
      if (selectedDate) {
        const parsedDate = dayjs(selectedDate, "DD/MM/YYYY");
        const body: UpdateProfileRequest = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number.toString(),
          dob: parsedDate.format("YYYY-MM-DD"),
          profile: userDetail?.result?.profilePic || "",
        };
        updateUserProfile(body);
      } else {
        console.log("please select selected date ", selectedDate);
      }
    },
  });

  const submitProfileUpdate = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (edit === false) {
      if (userDetail?.result?.dob) {
        setSelectedDate(dayjs(userDetail?.result?.dob));
      }
      if (userDetail?.result?.phoneNumber) {
        formik.setFieldValue("phone_number", userDetail?.result?.phoneNumber);
      }
      if (userDetail?.result?.firstName) {
        formik.setFieldValue("first_name", userDetail?.result?.firstName);
      }
      if (userDetail?.result?.lastName) {
        formik.setFieldValue("last_name", userDetail?.result?.lastName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  const onCropComplete = (crop: Crop) => {
    makeCroppedImage(crop);
  };

  const makeCroppedImage = (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(
          imageRef.current,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        const base64Image = canvas.toDataURL("image/jpeg");
        setCroppedImageUrl(base64Image);
      }
    }
  };

  const handleImageUpload = async () => {
    try {
      if (croppedImageUrl) {
        const body: ProfileImageUploadRequest = {
          profile_image: croppedImageUrl,
        };
        const response: any = await uploadProfileImage(body);
        if (response) {
          if (response.status === 200) {
            setSelectedImage("");
            if (userDetail?.result?.userId) {
              dispatch(userDetailRequest(userDetail?.result?.userId));
            }
          } else {
            if (response?.response?.data?.message) {
              setAlertData({
                message: response?.response?.data?.message,
                type: "error",
              });
              setSelectedImage("");
            }
          }
        }
      }
    } catch (error) {}
  };

  const handleActiveUserClick = (event: any) => {
    event.stopPropagation();
    if (userDetail?.result?.status.toLowerCase() !== "active") {
      setShowStatusUpdateDialog("active");
    }
  };

  const handleSuspendUserClick = (event: any) => {
    event.stopPropagation();
    if (userDetail?.result?.status.toLowerCase() === "active") {
      setShowStatusUpdateDialog("suspend");
    }
  };

  return (
    <Box>
      <Box sx={styles.backButton} onClick={() => navigate(-1)}>
        <BackArrowIcon />
        <Typography sx={{ fontSize: "0.8rem", fontWeight: 600 }}>
          Back
        </Typography>
      </Box>
      <Grid container sx={{ padding: { xs: 1 } }} spacing={{ xs: 2, md: 3 }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card sx={styles.cardContainerStyle}>
              <CardContent sx={styles.cardPadding}>
                <Box sx={styles.flexColumn}>
                  <Stack direction={"row"} alignItems={"center"}>
                    {userDetail?.result?.profilePic ? (
                      <img
                        src={userDetail?.result?.profilePic}
                        style={styles.profileImageStyle}
                        alt={userDetail?.result?.email}
                      />
                    ) : (
                      <ProfileIcon height={"3em"} width={"3em"} />
                    )}

                    <Stack direction={"column"} ml={1}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography sx={styles.userNameText}>{`${
                          userDetail?.result?.firstName
                            ? userDetail?.result?.firstName
                            : "Anonymous"
                        } ${
                          userDetail?.result?.lastName
                            ? userDetail?.result?.lastName
                            : "User"
                        }`}</Typography>

                        <Typography
                          sx={styles.userStatusText}
                        >{`(${userDetail?.result?.status})`}</Typography>
                      </Stack>

                      <Typography sx={styles.userNameSectionText}>
                        Account Details
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* Update user status buttons implementation */}
                  {userDetail?.result?.status === "Active" ||
                  userDetail?.result?.status === "Suspended" ? (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      sx={{ ml: 1, mr: 1 }}
                      gap={0.5}
                    >
                      <Stack direction={"column"} alignItems={"center"} gap={1}>
                        <Box
                          sx={
                            userDetail?.result?.status.toLowerCase() ===
                            "active"
                              ? styles.disableButton
                              : styles.approveButton
                          }
                          onClick={handleActiveUserClick}
                        >
                          <Typography
                            sx={
                              userDetail?.result?.status.toLowerCase() ===
                              "active"
                                ? styles.disableButtonTxt
                                : styles.approveButtonTxt
                            }
                          >
                            Mark as Active
                          </Typography>
                        </Box>
                        <Box
                          sx={
                            userDetail?.result?.status.toLowerCase() ===
                            "active"
                              ? styles.rejectedButton
                              : styles.disableButton
                          }
                          onClick={handleSuspendUserClick}
                        >
                          <Typography
                            sx={
                              userDetail?.result?.status.toLowerCase() ===
                              "active"
                                ? styles.rejectedButtonTxt
                                : styles.disableButtonTxt
                            }
                          >
                            Mark as Suspended
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  ) : (
                    <></>
                  )}

                  {/* End of update user status buttons implementation */}
                  <IconButton
                    sx={styles.editButtonContainer}
                    onClick={() => setEdit((prev) => !prev)}
                  >
                    {!edit ? (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-around"}
                        sx={styles.fullWidth}
                      >
                        <Typography sx={styles.editText}>Edit</Typography>
                        <EditPencilIcon />
                      </Stack>
                    ) : (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography sx={styles.editText}>Cancel</Typography>
                        <CloseIcon color="#83807F" />
                      </Stack>
                    )}
                  </IconButton>
                </Box>
                <Divider />
                {edit ? (
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <>
                        <Stack direction={"row"} alignItems={"center"} py={2}>
                          <UserIcon width={32} height={32} />
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={3}
                          >
                            <TextField
                              placeholder="First name"
                              sx={styles.textFieldWidth}
                              {...getFieldProps("first_name")}
                              error={Boolean(
                                touched.first_name && errors.first_name
                              )}
                              helperText={
                                touched.first_name && errors.first_name
                              }
                            />
                            <TextField
                              placeholder="Last name"
                              sx={styles.textFieldWidth}
                              {...getFieldProps("last_name")}
                              error={Boolean(
                                touched.last_name && errors.last_name
                              )}
                              helperText={touched.last_name && errors.last_name}
                            />
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} py={2}>
                          <EmailIcon width={32} height={32} />
                          <TextField
                            type="email"
                            placeholder="Email Address"
                            sx={styles.textFieldWidth}
                            variant={"outlined"}
                            value={userDetail?.result?.email}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} py={2}>
                          <PhoneIcon width={32} height={32} />
                          <TextField
                            type="number"
                            placeholder="Phone No."
                            sx={styles.textFieldWidth}
                            {...getFieldProps("phone_number")}
                            error={Boolean(
                              touched.phone_number && errors.phone_number
                            )}
                            helperText={
                              touched.phone_number && errors.phone_number
                            }
                          />
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          py={2}
                          mb={3}
                          gap={2}
                        >
                          <CakeIcon width={32} height={32} />
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={selectedDate}
                              disableFuture
                              format={"DD/MM/YYYY"}
                              onChange={(value) =>
                                setSelectedDate(dayjs(value))
                              }
                            />
                          </LocalizationProvider>
                        </Stack>
                        <Stack sx={{ margin: "0 auto", width: "40%" }}>
                          <PrimaryButton
                            title={"Update Profile"}
                            onClick={submitProfileUpdate}
                          />
                        </Stack>
                      </>
                    </Form>
                  </FormikProvider>
                ) : (
                  <Box>
                    <Stack direction={"row"} alignItems={"center"} py={2}>
                      <EmailIcon width={32} height={32} />
                      <Typography sx={styles.regularText}>
                        {userDetail?.result?.email}
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack direction={"row"} alignItems={"center"} py={2}>
                      <PhoneIcon width={32} height={32} />
                      <Typography sx={styles.regularText}>
                        {userDetail?.result?.phoneNumber ? (
                          userDetail?.result?.phoneNumber
                        ) : (
                          <Typography sx={styles.notVerified}>
                            Not Verified
                          </Typography>
                        )}
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack direction={"row"} alignItems={"center"} py={2}>
                      <CakeIcon width={32} height={32} />
                      <Typography sx={styles.regularText}>
                        {userDetail?.result?.dob ? (
                          getDateOfBirthFormat(userDetail?.result?.dob)
                        ) : (
                          <Typography sx={styles.notVerified}>
                            Not Verified
                          </Typography>
                        )}
                      </Typography>
                    </Stack>
                  </Box>
                )}
                <Divider />
                <Stack direction={"row"} alignItems={"center"} mt={1}>
                  {/*Promote to admin buttons implementation */}
                  <Box
                    sx={styles.promoteToAdminButton}
                    mx={2}
                    onClick={() => setShowPromoteToAdmin(true)}
                  >
                    <Typography sx={styles.promoteToAdminTxt}>
                      {"Promote as Admin"}
                    </Typography>
                  </Box>
                  {/* End Promote to admin buttons implementation */}

                  {/*Promote to admin buttons implementation */}
                  <Box
                    sx={styles.updatePasswordButton}
                    mx={2}
                    onClick={() => setShowUpdatePasswordDialog(true)}
                  >
                    <Typography sx={styles.updatePasswordTxt}>
                      {"Update Password"}
                    </Typography>
                  </Box>
                  {/* End Promote to admin buttons implementation */}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card sx={styles.cardContainerStyle}>
            <CardContent sx={styles.cardPadding}>
              <Box>
                <Stack direction={"row"} alignItems={"center"}>
                  <BankIcon width={32} height={32} />
                  <Typography sx={styles.userNameText} ml={1}>
                    User Bank Detail
                  </Typography>
                </Stack>
                <Divider sx={{ mt: 1, mb: 2 }} />
                {userBank?.bankResult && userBank?.bankResult.length > 0 ? (
                  userBank?.bankResult?.map((bank, index) => (
                    <Grid
                      container
                      sx={{
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setShowBankDetails(bank);
                      }}
                      key={index}
                    >
                      <Grid item md={8} sm={8}>
                        <Box sx={styles.bankNameLogoContainer}>
                          <Box
                            sx={
                              bank.status === "active"
                                ? styles.bankLogoContainer
                                : styles.bankLogoContainerDeactivated
                            }
                          >
                            <Box
                              sx={
                                bank.status === "active"
                                  ? styles.bankLogoGlass
                                  : styles.bankLogoGlassDeactivated
                              }
                            >
                              <Typography
                                sx={
                                  bank.status === "active"
                                    ? styles.bankLogoText
                                    : styles.bankLogoTextDeactivated
                                }
                              >
                                {getBankName(bank.bankName)}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={styles.bankNameContainer}>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <Typography
                                sx={
                                  bank.status === "active"
                                    ? styles.bankNameStyle
                                    : styles.deactivatedBankNameStyle
                                }
                              >
                                {bank.bankName}
                              </Typography>
                              {bank.verificationStatus === "Verified" ? (
                                <VerifiedIcon
                                  sx={{
                                    fontSize: "1.1em",
                                    color:
                                      bank?.status === "active"
                                        ? "#14CB1B"
                                        : "#5A5463",
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </Stack>

                            <Typography
                              sx={
                                bank.status === "active"
                                  ? styles.maskedBankAccountText
                                  : styles.maskedBankAccountTextDeactivated
                              }
                            >
                              {maskBankAccount(bank.accountNumber)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <NoDataFound message="The user's bank data could not be found." />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card sx={styles.cardContainerStyle}>
            <CardContent sx={styles.cardPadding}>
              <Box>
                <Stack direction={"row"} alignItems={"center"} mb={1}>
                  <WalletIcon width={32} height={32} />
                  <Typography sx={styles.userNameText} ml={1}>
                    User Wallet
                  </Typography>
                </Stack>
                <Divider />
                {userWallet?.result ? (
                  <Box>
                    <Stack direction={"row"} alignItems={"center"} mt={1}>
                      <Typography sx={styles.walletBalance}>
                        Crypto Balance
                      </Typography>
                      <TrendingDownIcon />
                      <Stack direction={"row"} alignItems={"baseline"} ml={1}>
                        <Typography sx={styles.aedWalletBalance}>
                          {"AED"}
                        </Typography>
                        <Typography sx={styles.aedWalletBalanceValue}>
                          {userWallet?.result?.totalBalanceAed}
                        </Typography>
                      </Stack>
                    </Stack>
                    <TableContainer sx={styles.tableWalletContainer}>
                      <Table>
                        <TableBody>
                          {userWallet?.result?.wallets?.map(
                            (element, index) => (
                              <TableRow sx={styles.rowBorder} key={index}>
                                <TableCell>
                                  <Stack
                                    direction={"row"}
                                    gap={1}
                                    sx={styles.inlineCenter}
                                  >
                                    <img
                                      src={element.cryptoIconUrl}
                                      alt={element.cryptoName}
                                      style={styles.iconStyle}
                                    />
                                    <Stack direction={"column"}>
                                      <Typography sx={styles.coinName}>
                                        {element.cryptoName}
                                      </Typography>
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                      >
                                        <Typography
                                          sx={styles.subText}
                                          mr={0.5}
                                        >
                                          {element.cryptoTicker}
                                        </Typography>
                                        {element.trend === "up" ? (
                                          <TrendingUpIcon />
                                        ) : element.trend === "down" ? (
                                          <TrendingDownIcon />
                                        ) : (
                                          <TrendingStableIcon />
                                        )}
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                </TableCell>
                                <TableCell align="center">
                                  <Stack direction={"column"}>
                                    <Typography
                                      sx={{
                                        ...styles.balanceText,
                                        color: "#FFC800",
                                      }}
                                    >{`${element.balance} ${element.cryptoTicker}`}</Typography>
                                    <Typography sx={{ ...styles.subText }}>
                                      {`≈ ${toFixedDecimal(
                                        element.balanceAed
                                      )} AED`}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <NoDataFound message="The user's wallet data could not be found." />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {selectedImage && (
        <Dialog open={selectedImage ? true : false}>
          <DialogContent>
            <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={1}>
              <Stack direction={"row"} sx={styles.inlineCenter}>
                {croppedImageUrl && (
                  <div className="cropped-image">
                    <img
                      src={croppedImageUrl}
                      alt="Cropped"
                      style={styles.croppedImageStyle}
                    />
                  </div>
                )}
                <Typography sx={styles.profileItemText} ml={1}>
                  Image Cropper
                </Typography>
              </Stack>

              <IconButton
                sx={styles.iconBackground}
                onClick={() => setSelectedImage("")}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />

            <ReactCrop
              style={{ width: 300, height: 300 }}
              crop={crop}
              aspect={1}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={onCropComplete}
            >
              <img ref={imageRef} src={selectedImage} alt="Crop" />
            </ReactCrop>

            <PrimaryButton title={"Upload"} onClick={handleImageUpload} />
          </DialogContent>
        </Dialog>
      )}

      {showBankDetails !== null && (
        <BankDetailsDialog
          open={showBankDetails !== null}
          bankDetails={showBankDetails}
          handleClose={() => setShowBankDetails(null)}
          onError={(error) => {
            setShowBankDetails(null);
            setAlertData({ message: error, type: "error" });
          }}
          onSuccess={(message) => {
            setShowBankDetails(null);
            setAlertData({ message: message, type: "success" });
          }}
        />
      )}

      {showStatusUpdateDialog !== null && (
        <UserStatusUpdateDialog
          open={showStatusUpdateDialog !== null}
          type={showStatusUpdateDialog}
          userId={userDetail?.result?.userId ? userDetail?.result?.userId : 0}
          onClose={() => setShowStatusUpdateDialog(null)}
          onError={(error) => {
            setShowStatusUpdateDialog(null);
            onPageLoad();
            setAlertData({ message: error, type: "error" });
          }}
          onSuccess={(message) => {
            setShowStatusUpdateDialog(null);
            onPageLoad();
            setAlertData({ message: message, type: "success" });
          }}
        />
      )}
      {updatePasswordDialog === true && (
        <UpdatePasswordDialog
          open={updatePasswordDialog}
          userId={userDetail?.result?.userId ? userDetail?.result?.userId : 0}
          onClose={() => setShowUpdatePasswordDialog(false)}
          onError={(error) => {
            setShowUpdatePasswordDialog(false);
            setAlertData({ message: error, type: "error" });
          }}
          onSuccess={(message) => {
            setShowUpdatePasswordDialog(false);
            setAlertData({ message: message, type: "success" });
          }}
        />
      )}
      {showPromoteToAdmin === true && (
        <PromoteToAdminDialog
          open={showPromoteToAdmin}
          userId={userDetail?.result?.userId ? userDetail?.result?.userId : 0}
          onClose={() => setShowPromoteToAdmin(false)}
          onError={(error) => {
            setShowPromoteToAdmin(false);
            setAlertData({ message: error, type: "error" });
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }}
          onSuccess={(message) => {
            setShowPromoteToAdmin(false);
            setAlertData({ message: message, type: "success" });
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }}
        />
      )}
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};
export default UserDetailPage;
