type Props = {
  width?: number;
  height?: number;
};
const TrendingUpIcon = (props: Props) => {
  const { width = 16, height = 11 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4146 0.700913C10.5259 0.410887 10.8513 0.266026 11.1413 0.377356L15.5972 2.08784C15.7365 2.1413 15.8489 2.2479 15.9095 2.38419C15.9702 2.52047 15.9743 2.67528 15.9208 2.81456L14.2103 7.27051C14.099 7.56054 13.7736 7.7054 13.4836 7.59407C13.1936 7.48274 13.0487 7.15737 13.16 6.86735L14.3839 3.67919L13.569 4.042C11.7291 4.86118 10.3277 6.25678 9.48127 7.92216C9.39964 8.08276 9.24602 8.19455 9.0681 8.22282C8.89017 8.2511 8.70946 8.19243 8.58207 8.06504L5.75 5.23297L1.08525 9.89772C0.865578 10.1174 0.509422 10.1174 0.289752 9.89772C0.0700825 9.67805 0.0700825 9.3219 0.289752 9.10223L5.35225 4.03973C5.45774 3.93424 5.60082 3.87497 5.75 3.87497C5.89918 3.87497 6.04226 3.93424 6.14775 4.03973L8.85225 6.74423C9.82771 5.15339 11.2783 3.83041 13.1114 3.01426L13.9263 2.65145L10.7381 1.42763C10.4481 1.3163 10.3032 0.99094 10.4146 0.700913Z"
        fill="#14CB1B"
      />
    </svg>
  );
};
export default TrendingUpIcon;
