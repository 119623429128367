import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  dashboardFailure,
  dashboardRequest,
  dashboardSuccess,
} from "../reducers/DashboardReducer";
import { DashboardResponse } from "../../types/DashboardResponse";

function* requestDashboardSaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/");
    if (response.status === 200) {
      const data: DashboardResponse = response.data;
      yield put(dashboardSuccess(data));
    } else {
      yield put(dashboardFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      dashboardFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchDashboardSaga() {
  yield takeLatest(dashboardRequest.type, requestDashboardSaga);
}

export default watchFetchDashboardSaga;
