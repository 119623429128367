import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const SellRequestIcon = (props: Props) => {
  const { width = 32, height = 32 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none">
      <path
        d="M10.001 12.081C10.001 10.416 11.584 8.606 13.141 7.857C12.958 7.674 12.782 7.474 12.631 7.259C12.259 6.731 12.68 6 13.326 6H15.673C16.356 6 16.729 6.776 16.324 7.326C16.184 7.516 16.026 7.694 15.862 7.857C17.419 8.607 19 10.416 19 12.08C19 13.69 17.767 14.999 16.25 14.999H12.75C11.233 14.999 10 13.689 10 12.08L10.001 12.081ZM21.708 10.293L23.001 9V12C23.001 12.552 23.448 13 24.001 13C24.554 13 25.001 12.552 25.001 12V9L26.294 10.293C26.489 10.488 26.745 10.586 27.001 10.586C27.257 10.586 27.513 10.488 27.708 10.293C28.099 9.902 28.099 9.27 27.708 8.879L25.415 6.586C25.042 6.212 24.556 6.018 24.065 6.002C24.044 6.001 24.023 6 24.001 6C23.979 6 23.958 6 23.937 6.002C23.446 6.018 22.96 6.212 22.587 6.586L20.294 8.879C19.903 9.27 19.903 9.902 20.294 10.293C20.685 10.684 21.317 10.684 21.708 10.293ZM29.149 14.681C28.634 14.212 27.963 13.969 27.271 14.003C26.574 14.035 25.932 14.337 25.477 14.838L21.936 18.575C21.968 18.785 22.001 18.995 22.001 19.213C22.001 21.296 20.446 23.089 18.384 23.383L14.132 23.979C13.585 24.057 13.079 23.677 13.001 23.131C12.923 22.584 13.303 22.078 13.849 22L18.011 21.417C18.947 21.283 19.759 20.611 19.951 19.685C20.247 18.26 19.161 17 17.787 17H10C7.791 17 6 18.791 6 21V26C6 28.209 7.791 30 10 30H14.262C17.067 30 19.742 28.822 21.636 26.754L29.338 18.345C30.286 17.283 30.2 15.639 29.149 14.681Z"
        fill="currentColor"
      />
      <clipPath id="clip0_1783_30262">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </svg>
  );
};
export default SellRequestIcon;
