import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const EmailIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 39 38" fill="none">
      <circle cx="19.375" cy="19" r="19" fill="#333333" />
      <path
        d="M8.375 15.9191V24.5C8.375 26.1569 9.71815 27.5 11.375 27.5H26.375C28.0319 27.5 29.375 26.1569 29.375 24.5V15.9191L20.4473 21.4131C19.4831 22.0064 18.2669 22.0064 17.3027 21.4131L8.375 15.9191Z"
        fill="white"
      />
      <path
        d="M29.375 14.1578V14C29.375 12.3431 28.0319 11 26.375 11H11.375C9.71815 11 8.375 12.3431 8.375 14V14.1578L18.0889 20.1356C18.571 20.4323 19.179 20.4323 19.6611 20.1356L29.375 14.1578Z"
        fill="white"
      />
    </svg>
  );
};
export default EmailIcon;
