import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const AEGIcon = (props: Props) => {
  const { width = 32, height = 32 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 206 206" fill="none">
      <circle cx="103" cy="103" r="103" fill="#906B27" />
      <path
        d="M193.029 100C193.029 151.362 151.386 193 100.014 193C48.6431 193 7 151.362 7 100C7 48.6385 48.6431 7 100.014 7C151.386 7 193.029 48.6385 193.029 100Z"
        fill="#D9D9D9"
        stroke="url(#paint0_linear_1_16)"
        strokeWidth="14"
      />
      <ellipse cx="99.5169" cy="100" rx="86.5797" ry="87" fill="#BA9E0C" />
      <ellipse
        cx="102.502"
        cy="103"
        rx="83.5942"
        ry="84"
        fill="url(#paint1_linear_1_16)"
      />
      <path
        d="M151.094 124.999L132.328 102.435L76.1908 102.596L89.0507 117.319C93.3142 122.2 99.4639 124.999 105.927 124.999H151.094Z"
        fill="white"
      />
      <path
        d="M76.1908 102.435H132.328L80.5214 107.27L76.1908 102.435Z"
        fill="#F2B80E"
        fillOpacity="0.5"
      />
      <path
        d="M76.1908 102.596L132.328 102.435L80.361 107.431L76.1908 102.596Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M80.3614 107.27L132.328 102.435L84.5316 112.266L80.3614 107.27Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M103.816 81H48.7633L59.6673 94.4471C63.9315 99.706 70.3241 102.758 77.0749 102.758L132.327 102.436L121.098 89.1568C116.832 83.9897 110.497 81 103.816 81Z"
        fill="white"
      />
      <linearGradient
        id="paint0_linear_1_16"
        x1="100.014"
        y1="0"
        x2="100.014"
        y2="200"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBCD21" />
        <stop offset="1" stopColor="#D48E1C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_16"
        x1="102.502"
        y1="19"
        x2="102.502"
        y2="187"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBCD21" />
        <stop offset="1" stopColor="#DFA51D" />
      </linearGradient>
    </svg>
  );
};
export default AEGIcon;
