import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "./AuthReducer";
import DashboardReducer from "./DashboardReducer";
import UsersReducer from "./UsersReducer";
import UserDetailReducer from "./UserDetailReducer";
import UserWalletReducer from "./UserWalletReducer";
import BankReducer from "./BankReducer";
import RedeemReducer from "./RedeemReducer";
import SellReducer from "./SellReducer";
import TransactionReducer from "./TransactionReducer";
import CurrencyReducer from "./CurrencyReducer";
import AEGConfigGroupReducer from "./AEGConfigGroupReducer";
import AdminUserReducer from "./AdminUserReducer";
import ProfileReducer from "./ProfileReducer";

const AppReducer = combineReducers({
  authState: AuthReducer,
  dashboardState: DashboardReducer,
  usersState: UsersReducer,
  userDetailState: UserDetailReducer,
  userWalletState: UserWalletReducer,
  bankState: BankReducer,
  redeemState: RedeemReducer,
  sellState: SellReducer,
  transactionState: TransactionReducer,
  currencyState: CurrencyReducer,
  configGroupState: AEGConfigGroupReducer,
  adminUserState: AdminUserReducer,
  profileState: ProfileReducer,
});
export type RootState = ReturnType<typeof AppReducer>;
const RootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT_REQUEST") {
    return AppReducer(undefined, action);
  }
  return AppReducer(state, action);
};
export default RootReducer;
