import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  profileFailure,
  profileRequest,
  profileSuccess,
} from "../reducers/ProfileReducer";

function* requestUserProfileSaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/profile");
    if (response.status === 200) {
      yield put(profileSuccess(response.data));
    } else {
      yield put(profileFailure(response.response.data.message));
    }
  } catch (error: any) {
    console.log("Error in fetching profile: ", error);

    yield put(
      profileFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchProfileSaga() {
  yield takeLatest(profileRequest.type, requestUserProfileSaga);
}

export default watchFetchProfileSaga;
