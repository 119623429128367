import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import AEGTokenSpecs from "./Tabs/AEGTokenSpecs";
import EmailConfig from "./Tabs/EmailConfig";
import GoldRateApi from "./Tabs/GoldRateApi";
import WalletVault from "./Tabs/WalletVault";
import CryptoCurrencyConfig from "./Tabs/CryptoCurrencyConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducers";

const tabs = {
  AEGTokenSpecs: "AEG Token Specs",
  EmailConfig: "Email SMTP Configuration",
  GoldRateApi: "Gold Rate API",
  cryptoScanner: "Cryptocurrency Scanner",
  hotWallet: "Hot Wallet Vault",
};

const SettingsPage = () => {
  const [value, setValue] = useState(tabs.AEGTokenSpecs);
  const authData = useSelector((state: RootState) => state.authState);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          onChange={handleChange}
          value={value}
          variant="fullWidth"
          sx={{
            fontSize: "1rem",
            fontWeight: 500,
          }}
          aria-label="lab API tabs example"
        >
          <Tab label={tabs.AEGTokenSpecs} value={tabs.AEGTokenSpecs} />
          <Tab label={tabs.EmailConfig} value={tabs.EmailConfig} />
          <Tab label={tabs.GoldRateApi} value={tabs.GoldRateApi} />
          <Tab label={tabs.cryptoScanner} value={tabs.cryptoScanner} />
          {authData?.result?.adminRole.toLowerCase() === "superadmin" && (
            <Tab label={tabs.hotWallet} value={tabs.hotWallet} />
          )}
        </Tabs>
      </Box>
      {value === tabs.AEGTokenSpecs && <AEGTokenSpecs />}
      {value === tabs.EmailConfig && <EmailConfig />}
      {value === tabs.GoldRateApi && <GoldRateApi />}
      {value === tabs.cryptoScanner && <CryptoCurrencyConfig />}
      {authData?.result?.adminRole.toLowerCase() === "superadmin" &&
        value === tabs.hotWallet && <WalletVault />}
    </Box>
  );
};
export default SettingsPage;
