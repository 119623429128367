import BigNumber from "bignumber.js";

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const maskBankAccount = (accountNumber: string | undefined) => {
  if (accountNumber) {
    const accountStr = String(accountNumber);
    const lastFourDigits = accountStr.slice(-4);
    const maskedSection = "X".repeat(accountStr.length - 4);
    return maskedSection + lastFourDigits;
  } else {
    return "";
  }
};

export const trimAddress = (address: string | undefined, count?: number) => {
  if (address) {
    return `${address.substring(0, count ? count : 5)}...${address.substring(
      address.length - (count ? count : 5),
      address.length
    )}`;
  } else {
    return "";
  }
};

export const getBankName = (bankName: string | undefined) => {
  if (bankName) {
    const bankSplit = bankName.split(" ");
    return bankSplit[0].slice(0, 1).toUpperCase();
  } else {
    return "BB";
  }
};

export const generatePayload = (id: number) => {
  return "WPJG" + id + "QfzB2k+88KhWX9W8bw";
};

export const multiplyBigNumber = (a: any, b: any): string => {
  BigNumber.config({ DECIMAL_PLACES: 2 });
  let x: BigNumber;
  let y: BigNumber;
  if (a) {
    x = new BigNumber(a);
    if (x.isNaN()) {
      x = new BigNumber(0);
    }
  } else {
    x = new BigNumber(0);
  }
  if (b) {
    y = new BigNumber(b);
    if (y.isNaN()) {
      y = new BigNumber(0);
    }
  } else {
    y = new BigNumber(0);
  }
  return x.multipliedBy(y).toFixed(2).toString();
};

export const addBigNumber = (a: any, b: any): string => {
  BigNumber.config({ DECIMAL_PLACES: 2 });
  let x: BigNumber;
  let y: BigNumber;
  if (a) {
    x = new BigNumber(a);
    if (x.isNaN()) {
      x = new BigNumber(0);
    }
  } else {
    x = new BigNumber(0);
  }
  if (b) {
    y = new BigNumber(b);
    if (y.isNaN()) {
      y = new BigNumber(0);
    }
  } else {
    y = new BigNumber(0);
  }
  return x.plus(y).toFixed(2).toString();
};

export const calcualtePercentage = (percentage: any, value: any): string => {
  BigNumber.config({ DECIMAL_PLACES: 2 });
  let x: BigNumber;
  let y: BigNumber;
  if (percentage) {
    x = new BigNumber(percentage);
    if (x.isNaN()) {
      x = new BigNumber(0);
    }
  } else {
    x = new BigNumber(0);
  }

  if (value) {
    y = new BigNumber(value);
    if (y.isNaN()) {
      y = new BigNumber(0);
    }
  } else {
    y = new BigNumber(0);
  }
  return x.dividedBy(100).multipliedBy(y).toString();
};

export const zeroNumber = () => {
  return new BigNumber(0).toFixed(2).toString();
};

export const toFixedDecimal = (value: any, decimal?: number) => {
  if (value) {
    let x = new BigNumber(value);
    if (decimal === 0) {
      return x.toString();
    }
    return x.toFixed(decimal ? decimal : 2).toString();
  } else {
    return "0.00";
  }
};

export const formatNumber = (number: string | number | undefined) => {
  if (number) {
    let x = new BigNumber(number);
    const formattedNumber = x.toFixed().toString();
    return formattedNumber;
  }
};

export const toPascalCase = (text: string) => {
  const firstLetter = text.substring(0, 1).toUpperCase();
  const otherLetter = text.substring(1, text.length).toLowerCase();
  return firstLetter + otherLetter;
};

export const throttle = (func: Function, limit: number) => {
  let lastFunc: NodeJS.Timeout;
  let lastRan: number;
  return function (this: any, ...args: any[]) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const getColorByStatus = (value: string | undefined) => {
  if (!value) {
    return "rgba(237, 9, 5,1)";
  }
  switch (value) {
    case "Pending":
    case "Unverified":
      return "rgba(231, 188, 68, 1)";
    case "Approved":
    case "Active":
      return "rgba(20, 203, 27, 1)";
    case "Rejected":
    case "Inactive":
      return "rgba(237, 9, 5,1)";
    case "Failed":
      return "rgba(237, 9, 5,1)";
    default:
      return "rgba(237, 9, 5,1)";
  }
};

export const generateRandomText = (length: number = 8): string => {
  if (length < 8) {
    throw new Error("Length must be at least 8 characters.");
  }

  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const digits = "0123456789";
  const symbols = "!@#$%^&*()-_=+[]{}|;:,.<>?/";

  // Ensure the string has at least one of each required character type
  const randomText: string[] = [
    uppercase[Math.floor(Math.random() * uppercase.length)],
    lowercase[Math.floor(Math.random() * lowercase.length)],
    digits[Math.floor(Math.random() * digits.length)],
    symbols[Math.floor(Math.random() * symbols.length)],
  ];

  // Fill the rest of the string with random choices from all sets combined
  const allCharacters = uppercase + lowercase + digits + symbols;
  for (let i = randomText.length; i < length; i++) {
    randomText.push(
      allCharacters[Math.floor(Math.random() * allCharacters.length)]
    );
  }

  // Shuffle the array to ensure randomness
  for (let i = randomText.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [randomText[i], randomText[j]] = [randomText[j], randomText[i]];
  }

  // Convert the array to a string and return
  return randomText.join("");
};
