import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  transactionRequest,
  transactionSuccess,
  transactionFailure,
} from "../reducers/TransactionReducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { TransactionResponse } from "../../types/TransactionModel";

function* requestTransactionSaga(action: PayloadAction<string>): any {
  try {
    let uri = `/transaction?${action.payload}`;
    const response = yield call(axiosInstance.get, uri);
    if (response.status === 200) {
      const data: TransactionResponse = response.data;
      yield put(transactionSuccess(data));
    } else {
      yield put(transactionFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      transactionFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchTransactionSaga() {
  yield takeLatest(transactionRequest.type, requestTransactionSaga);
}

export default watchFetchTransactionSaga;
