import { COLOR } from "../../../theme";

const styles = {
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "1em",
  },
  tableContainer: {
    paddingLeft: "0em",
    paddingRight: "0em",
    marginTop: "1em",
    height: "60vh",
    width: "100%",
    overflowX: "auto",
  },
  customRows: {
    color: "white",
    fontSize: 13,
    textAlign: "center",
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      color: "#FFFFFF",
      textAlign: "center",
      backgroundColor: "#1C1C1C",
    },
  },
  profileImageStyle: {
    width: "3em",
    height: "3em",
    borderRadius: "4em",
    padding: "0.2em",
    border: "1px dashed #FFC800",
  },
  searchInputStyle: {
    input: { color: "white" },
    borderColor: "white",
    width: "300px",
    height: "35px !important",
    maxHeight: "35px !important",
    padding: "0px",
  },
  filterContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#171616",
    padding: "1em",
  },
  leftAlignText: {
    alignItems: "left",
    textAlign: "left !important",
    backgroundColor: "transparent",
  },
  loadingRow: {
    padding: "3em",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addAdminButton: {
    backgroundColor: "rgba(20, 203, 27, 1)",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
    padding: "0.5em 1.5em",
    height: "35px !important",
    maxHeight: "35px !important",
    "&:hover": {
      transitions: "all 0.5s ease",
      backgroundColor: "rgba(20, 203, 27, 0.5)",
    },
  },
  noDataRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: COLOR.offWhite,
    marginBottom: "6em",
  },
  sorryText: {
    fontSize: "1.8rem",
    fontWeight: 700,
    color: "#51547B",
    marginTop: "2em",
  },
};
export default styles;
