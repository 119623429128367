import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const CakeIcon = (props: Props) => {
  const { width = 20, height = 16 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 38 38" fill="none">
      <circle cx="19" cy="19" r="19" fill="#333333" />
      <path
        d="M30 24.5C28.624 24.5 28.4649 23.125 26.3415 23.125C24.2088 23.125 24.0421 24.5 22.6708 24.5C21.3107 24.5 21.1261 23.125 19 23.125C16.8962 23.125 16.6813 24.5 15.3415 24.5C13.9592 24.5 13.8093 23.125 11.6708 23.125C9.53229 23.125 9.37976 24.5 8 24.5V21.0625C8 19.9238 9.0558 19 10.3571 19H11.1429V12.8125H14.2857V19H17.4286V12.8125H20.5714V19H23.7143V12.8125H26.8571V19H27.6429C28.9442 19 30 19.9238 30 21.0625V24.5ZM30 30H8V25.875C10.1291 25.875 10.2966 24.5 11.6708 24.5C13.0434 24.5 13.2055 25.875 15.3415 25.875C17.4454 25.875 17.6602 24.5 19 24.5C20.3823 24.5 20.5322 25.875 22.6708 25.875C24.7999 25.875 24.9674 24.5 26.3415 24.5C27.6914 24.5 27.8762 25.875 30 25.875V30ZM12.7143 12.125C11.8426 12.125 11.1429 11.5127 11.1429 10.75C11.1429 9.41797 12.7143 9.76172 12.7143 8C13.3036 8 14.2857 9.26758 14.2857 10.4062C14.2857 11.5449 13.5859 12.125 12.7143 12.125ZM19 12.125C18.1283 12.125 17.4286 11.5127 17.4286 10.75C17.4286 9.41797 19 9.76172 19 8C19.5893 8 20.5714 9.26758 20.5714 10.4062C20.5714 11.5449 19.8717 12.125 19 12.125ZM25.2857 12.125C24.4141 12.125 23.7143 11.5127 23.7143 10.75C23.7143 9.41797 25.2857 9.76172 25.2857 8C25.875 8 26.8571 9.26758 26.8571 10.4062C26.8571 11.5449 26.1574 12.125 25.2857 12.125Z"
        fill="white"
      />
    </svg>
  );
};
export default CakeIcon;
