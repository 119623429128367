import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData, UsersListResponse } from "../../types/UsersModel";

interface UserState {
  result: UserData | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  result: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    usersRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    usersSuccess(state, action: PayloadAction<UsersListResponse>) {
      state.loading = false;
      state.result = action.payload.data;
    },
    usersFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { usersRequest, usersSuccess, usersFailure } = userSlice.actions;

export default userSlice.reducer;
