import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const EditPencilIcon = (props: Props) => {
  const { width = 14, height = 15 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 17 18" fill="none">
      <path
        d="M13.0342 7.67157L5.19781 15.5076C3.93066 16.7747 2.17841 17.5 0.389892 17.5H0V17.1101C0 15.3217 0.725381 13.5695 1.99253 12.3024L9.82892 4.46645L13.0342 7.67157ZM13.1317 1.16386L10.7674 3.52803L13.9727 6.73315L16.337 4.36898C17.221 3.48497 17.221 2.04788 16.337 1.16386C15.4529 0.279847 14.018 0.27758 13.1317 1.16386Z"
        fill="#83807F"
      />
    </svg>
  );
};
export default EditPencilIcon;
