import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const TransactionsIcon = (props: Props) => {
  const { width = 24, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 27"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.09369 0C4.47814 0.00178632 2.92931 0.594986 1.78694 1.64948C0.644568 2.70397 0.00193518 4.13366 0 5.62494V25.8747C0.000171703 26.0793 0.0607985 26.2801 0.175354 26.4552C0.289909 26.6304 0.454055 26.7734 0.650121 26.8689C0.846187 26.9644 1.06675 27.0087 1.28806 26.997C1.50937 26.9854 1.72306 26.9183 1.90611 26.8028L4.47277 25.184L7.03943 26.8028C7.24221 26.931 7.48224 26.9996 7.72802 26.9996C7.97379 26.9996 8.21382 26.931 8.4166 26.8028L10.976 25.184L13.5353 26.8028C13.7382 26.9313 13.9785 27 14.2245 27C14.4705 27 14.7108 26.9313 14.9137 26.8028L17.473 25.1851L20.0324 26.8017C20.2153 26.9168 20.4288 26.9836 20.6498 26.9952C20.8708 27.0067 21.0911 26.9624 21.2869 26.8671C21.4827 26.7718 21.6467 26.6291 21.7613 26.4543C21.8759 26.2794 21.9367 26.0791 21.9373 25.8747V5.62494C21.9353 4.13366 21.2927 2.70397 20.1503 1.64948C19.008 0.594986 17.4591 0.00178632 15.8436 0L6.09369 0ZM13.4061 15.7498H6.09369C5.77046 15.7498 5.46047 15.6313 5.23191 15.4203C5.00335 15.2094 4.87495 14.9232 4.87495 14.6248C4.87495 14.3265 5.00335 14.0403 5.23191 13.8294C5.46047 13.6184 5.77046 13.4999 6.09369 13.4999H13.4061C13.7293 13.4999 14.0393 13.6184 14.2679 13.8294C14.4965 14.0403 14.6249 14.3265 14.6249 14.6248C14.6249 14.9232 14.4965 15.2094 14.2679 15.4203C14.0393 15.6313 13.7293 15.7498 13.4061 15.7498ZM17.0623 10.1249C17.0623 10.4233 16.9339 10.7094 16.7054 10.9204C16.4768 11.1314 16.1668 11.2499 15.8436 11.2499H6.09369C5.77046 11.2499 5.46047 11.1314 5.23191 10.9204C5.00335 10.7094 4.87495 10.4233 4.87495 10.1249C4.87495 9.82653 5.00335 9.54038 5.23191 9.32941C5.46047 9.11843 5.77046 8.99991 6.09369 8.99991H15.8436C16.1668 8.99991 16.4768 9.11843 16.7054 9.32941C16.9339 9.54038 17.0623 9.82653 17.0623 10.1249Z" />
    </svg>
  );
};
export default TransactionsIcon;
