import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  currencyFailure,
  currencyRequest,
  currencySuccess,
} from "../reducers/CurrencyReducer";
import { CurrencyResponse } from "../../types/CurrencyModel";

function* requestCurrencySaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/currency");
    if (response.status === 200) {
      const data: CurrencyResponse = response.data;
      yield put(currencySuccess(data));
    } else {
      yield put(currencyFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      currencyFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchCurrencySaga() {
  yield takeLatest(currencyRequest.type, requestCurrencySaga);
}

export default watchFetchCurrencySaga;
