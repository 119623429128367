import {
  Card,
  CardContent,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./CurrencyPage.styles";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducers";
import { Currency } from "../../types/CurrencyModel";
import { currencyRequest } from "../../store/reducers/CurrencyReducer";
import { getColorByStatus } from "../../utils/Utils";
import UpdateCurrencyDialog from "../../components/UpdateCurrencyDialog";
import { AlertDataProps } from "../../types/Common";
import AlertComponent from "../../components/AlertComponent";

const CurrencyPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currency = useSelector((state: RootState) => state.currencyState);
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(
    null
  );

  useEffect(() => {
    if (currency.result && currency.result?.length > 0) {
      setCurrencyList(currency.result);
    }
  }, [currency.result]);

  useEffect(() => {
    dispatch(currencyRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const searchUsers = (searchQuery: string) => {
    if (searchQuery === "" && currency.result && currency.result?.length > 0) {
      setCurrencyList(currency.result);
      return;
    }
    // eslint-disable-next-line array-callback-return
    const results = currency?.result?.filter(
      (data) =>
        data?.cryptoName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        data?.cryptoBlockchain
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        data?.cryptoTicker?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (results && results.length > 0) {
      setCurrencyList(results);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      searchUsers(value);
    } else {
      if (currency?.result && currency.result.length > 0) {
        setCurrencyList(currency.result);
      }
    }
  };

  return (
    <>
      <Card sx={styles.cardContainerStyle}>
        <CardContent sx={styles.paddingZero}>
          <Stack direction="row" sx={styles.filterContainer}>
            <TextField
              placeholder="Search"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "white" }} />
                  </InputAdornment>
                ),
                style: { color: "white", borderColor: "white" },
              }}
              variant="outlined"
              onChange={handleSearchChange}
              sx={styles.searchInputStyle}
            />
            {/* <Button sx={styles.addNewButton}>Add New</Button> */}
          </Stack>
          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={styles.tableTitleRowBorder}>
                  <TableCell>Currency</TableCell>
                  <TableCell>Ticker</TableCell>
                  <TableCell>Blockchain</TableCell>
                  <TableCell>Contract Address</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currencyList &&
                  currencyList.length > 0 &&
                  currencyList.map((currency) => (
                    <TableRow
                      key={currency.cryptoId}
                      onClick={() => setSelectedCurrency(currency)}
                    >
                      <TableCell>
                        <Stack direction="row" alignItems="center">
                          <img
                            src={currency.cryptoIconUrl}
                            style={styles.iconImageStyle}
                            alt={currency.cryptoTicker}
                          />
                          <Typography sx={styles.boldText} ml={1}>
                            {currency.cryptoName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.rowText}>
                          {currency.cryptoTicker}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.centerAlign}>
                        <Typography sx={styles.boldText}>
                          {currency.cryptoBlockchain}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={styles.rowText}>
                          {currency.cryptoContractAddress}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: 400,
                            textAlign: "center",
                            color: getColorByStatus(currency.status),
                          }}
                        >
                          {currency.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        {selectedCurrency !== null && (
          <UpdateCurrencyDialog
            open={selectedCurrency !== null ? true : false}
            onClose={() => setSelectedCurrency(null)}
            data={selectedCurrency}
            onError={(error) => {
              setAlertData({ message: error, type: "error" });
              setSelectedCurrency(null);
            }}
            onSuccess={(message) => {
              setAlertData({ message: message, type: "success" });
              setSelectedCurrency(null);
            }}
          />
        )}
      </Card>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </>
  );
};

export default CurrencyPage;
