import { COLOR } from "../../theme";

const styles = {
  cardContainer: {
    background:
      "radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(217, 154, 38, 0.25) 0%, rgba(217, 154, 38, 0.15) 77.08%, rgba(181, 181, 181, 0.1) 100%)",
    color: "#fff",
    border: "1px solid rgba(135,123,61,1)",
    borderImage:
      "radial-gradient(80.38% 222.5% at -13.75% -12.36%, #877B3D 0%, rgba(255, 255, 255, 0) 100%),radial-gradient(80.69% 208.78% at 108.28% 112.58%, #FFF1BF 0%, rgba(183, 142, 38, 0) 100%)",
    height: "100%",
    padding: 0,
    borderRadius: "8px",
  },
  itemCenter: {
    alignItems: "center",
  },
  itemCardContainer: {
    bgcolor: "rgba(15, 15, 15, 1)",
    color: "text.primary",
    padding: 0,
    height: "100%",
    boxShadow:
      "0px 1px 2px 0px rgba(255,255,255,0.4),0px 2px 2px 0px rgba(0,0,0,0.2),0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: "8px",
  },
  iconContainer: {
    backgroundColor: "#FFFFFF",
    width: "3em",
    maxWidth: "3em",
    minWidth: "3em",
    height: "3em",
    maxHeight: "3em",
    minHeight: "3em",
    borderRadius: "3em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  statValue: {
    display: "flex",
    flexDirction: "row",
    justifyContent: "center",
    alignItem: "baseline",
    marginBottom: "1em",
    backgroundColor: "#000000",
    padding: "0.3em",
  },
  statValueText: {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  statValueCurrecy: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginRight: "5px",
  },
  statTitleText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  noDataRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: COLOR.offWhite,
    marginBottom: "6em",
  },
  sorryText: {
    fontSize: "1.8rem",
    fontWeight: 700,
    color: "#51547B",
    marginTop: "2em",
  },
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
  },
  cardPadding: {
    padding: "1em",
  },
  cardTitle: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  tableTH: {
    fontSize: "0.8rem",
    fontWeight: 600,
    textAlign: "center",
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      textAlign: "center",
      color: "#FFFFFF",
    },
  },
  boldText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
    textAlign: "center",
  },
  normalText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
    textAlign: "center",
  },
  rowBorder: {
    td: {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 400,
      color: "#FFFFFF",
      textAlign: "center",
    },
    "&:last-child td, &:last-child th": { border: 0 },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
      transition: "all 0.3s ease-in-out",
    },
    cursor: "pointer",
  },
  centerText: {
    textAlign: "center",
    backgroundColor: "transparent",
  },
  leftText: {
    align: "left",
    textAlign: "left",
    backgroundColor: "transparent",
  },
  headerRow: {
    backgroundColor: "#171616",
    padding: "1em",
    "& .MuiCardHeader-title": {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#FFFFFF",
    },
  },
};
export default styles;
