import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DashboardData,
  DashboardResponse,
} from "../../types/DashboardResponse";

interface DashboardState {
  result: DashboardData | null;
  loading: boolean;
  error: string | null;
}

const initialState: DashboardState = {
  result: null,
  loading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardRequest(state) {
      state.loading = true;
      state.error = null;
    },
    dashboardSuccess(state, action: PayloadAction<DashboardResponse>) {
      state.loading = false;
      state.result = action.payload.data;
    },
    dashboardFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { dashboardRequest, dashboardSuccess, dashboardFailure } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
