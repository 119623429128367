import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { Currency } from "../types/CurrencyModel";
import TransitionComponent from "./TransitionComponent";
import PrimaryButton from "./PrimaryButton";
import { Form, FormikProvider, useFormik } from "formik";
import { UpdateCurrencySchema } from "../validations/Validation";
import { CloseIcon } from "../assets";
import { requestUpdateCurrencyApi } from "../api/api";
import React from "react";
import Loader from "./Loader";

type Props = {
  open: boolean;
  onClose: () => void;
  data: Currency;
  onSuccess: (message: string) => void;
  onError: (error: string) => void;
};

const UpdateCurrencyDialog = (props: Props) => {
  const { open, onClose, data, onSuccess, onError } = props;
  const [loading, setLoading] = React.useState(false);

  const updateCurrency = async (body: any) => {
    try {
      const response = await requestUpdateCurrencyApi(data.cryptoId, body);
      setLoading(false);
      if (response) {
        console.log("Update Currency Response: ", response);

        if (response.data === 200) {
          onSuccess(response.data.message);
        } else {
          onError(response.data.message);
        }
      } else {
        onError("Failed to update currency");
      }
    } catch (error) {
      setLoading(false);
      onError("Failed to update currency");
    }
  };

  const formik = useFormik({
    initialValues: {
      cryptoName: data.cryptoName,
      cryptoTicker: data.cryptoTicker,
      cryptoBlockchain: data.cryptoBlockchain,
      cryptoContractAddress: data.cryptoContractAddress,
      cryptoIconUrl: data.cryptoIconUrl,
      cryptoRpcUrl: data.cryptoRpcUrl,
      cryptoExplorerUrl: data.cryptoExplorerUrl,
      description: data.description,
    },
    validationSchema: UpdateCurrencySchema,
    onSubmit: (value) => {
      setLoading(true);
      const body = {
        name: value.cryptoName,
        coinTicker: value.cryptoTicker,
        blockchain: value.cryptoBlockchain,
        contractAddress: value.cryptoContractAddress,
        iconUrl: value.cryptoIconUrl,
        rpcUrl: value.cryptoRpcUrl,
        explorerUrl: value.cryptoExplorerUrl,
        description: value.description,
        status: data.status,
      };
      console.log("Body: ", body);

      updateCurrency(body);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionComponent}
      keepMounted
      PaperProps={{
        sx: {
          height: "60vh",
          minWidth: {
            xs: "100%",
            sm: "100%",
            md: "500px",
          },
        },
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
      onClose={onClose}
    >
      <DialogTitle sx={styles.headerRowStyle}>
        <Typography sx={styles.headerTextStyle}>Update Currency</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction={"column"} mb={2}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={2}
              >
                <label style={styles.labelStyle}>Crypto Name</label>
                <Typography>{data.status}</Typography>
              </Stack>

              <TextField
                fullWidth
                autoComplete="Crypto Name"
                type="text"
                {...getFieldProps("cryptoName")}
                error={Boolean(touched.cryptoName && errors.cryptoName)}
                helperText={touched.cryptoName && errors.cryptoName}
              />
            </Stack>
            <Stack direction={"column"} mb={2}>
              <label style={styles.labelStyle}>Crypto Ticker</label>
              <TextField
                fullWidth
                autoComplete="Crypto Ticker"
                type="text"
                {...getFieldProps("cryptoTicker")}
                error={Boolean(touched.cryptoTicker && errors.cryptoTicker)}
                helperText={touched.cryptoTicker && errors.cryptoTicker}
              />
            </Stack>
            <Stack direction={"column"} mb={2}>
              <label style={styles.labelStyle}>Crypto Blockchain</label>
              <TextField
                fullWidth
                autoComplete="Crypto Blockchain"
                type="text"
                {...getFieldProps("cryptoBlockchain")}
                error={Boolean(
                  touched.cryptoBlockchain && errors.cryptoBlockchain
                )}
                helperText={touched.cryptoBlockchain && errors.cryptoBlockchain}
              />
            </Stack>

            <Stack direction={"column"} mb={2}>
              <label style={styles.labelStyle}>Crypto Contract Address</label>
              <TextField
                fullWidth
                autoComplete="Crypto Contract Address"
                type="text"
                {...getFieldProps("cryptoContractAddress")}
                error={Boolean(
                  touched.cryptoContractAddress && errors.cryptoContractAddress
                )}
                helperText={
                  touched.cryptoContractAddress && errors.cryptoContractAddress
                }
              />
            </Stack>

            <Stack direction={"column"} mb={2}>
              <label style={styles.labelStyle}>Crypto Icon</label>
              <TextField
                fullWidth
                autoComplete="Crypto Icon"
                type="text"
                {...getFieldProps("cryptoIconUrl")}
                error={Boolean(touched.cryptoIconUrl && errors.cryptoIconUrl)}
                helperText={touched.cryptoIconUrl && errors.cryptoIconUrl}
              />
            </Stack>

            <Stack direction={"column"} mb={2}>
              <label style={styles.labelStyle}>Crypto RPC URL</label>
              <TextField
                fullWidth
                autoComplete="Crypto RPC URL"
                type="text"
                {...getFieldProps("cryptoRpcUrl")}
                error={Boolean(touched.cryptoRpcUrl && errors.cryptoRpcUrl)}
                helperText={touched.cryptoRpcUrl && errors.cryptoRpcUrl}
              />
            </Stack>

            <Stack direction={"column"} mb={2}>
              <label style={styles.labelStyle}>Crypto Explorer URL</label>
              <TextField
                fullWidth
                autoComplete="Crypto Explorer URL"
                type="text"
                {...getFieldProps("cryptoExplorerUrl")}
                error={Boolean(
                  touched.cryptoExplorerUrl && errors.cryptoExplorerUrl
                )}
                helperText={
                  touched.cryptoExplorerUrl && errors.cryptoExplorerUrl
                }
              />
            </Stack>

            <Stack direction={"column"} mb={2}>
              <label style={styles.labelStyle}>Description</label>
              <TextField
                fullWidth
                autoComplete="description"
                multiline
                minRows={3}
                type="text"
                {...getFieldProps("description")}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <Loader />
        ) : (
          <PrimaryButton title="Update" onClick={handleSubmit} />
        )}
      </DialogActions>
    </Dialog>
  );
};
export default UpdateCurrencyDialog;

const styles = {
  headerRowStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTextStyle: {
    fontSize: "1.2rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
  },
  labelStyle: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 0.6)",
    marginBottom: "5px",
  },
};
