import { BankBg } from "../../assets";
import { COLOR } from "../../theme";

const styles = {
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "1em",
  },
  tableContainer: {
    paddingLeft: "1em",
    paddingRight: "1em",
    marginTop: "1em",
    height: "60vh",
  },
  activeRow: {
    color: "#14CB1B",
    fontSize: "0.9rem",
    fontWeight: 350,
  },
  deActiveRow: {
    color: "#CB1F14",
    fontSize: "0.9rem",
    fontWeight: 350,
  },
  pendingRow: {
    color: "#FFC800",
    fontSize: "0.9rem",
    fontWeight: 350,
  },

  customRows: {
    color: "white",
    fontSize: 13,
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      color: "#FFFFFF",
      backgroundColor: "#1C1C1C",
    },
  },
  profileImageStyle: {
    width: "3em",
    height: "3em",
  },
  searchInputStyle: {
    input: { color: "white" },
    borderColor: "white",
    width: "300px",
    height: "40px !important",
    padding: "0px",
  },
  filterContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#171616",
    padding: "1em",
  },
  bankDetailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginTop: "1em",
  },
  bankNameLogoContainer: {
    display: "flex",
    alignItems: "center",
    flex: 5,
  },
  bankNameContainer: {
    display: "flex",
    flexDirection: "column",
  },
  bankNameStyle: {
    fontSize: "1em",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  deactivatedBankNameStyle: {
    fontSize: "1em",
    fontWeight: 600,
    color: "#5A5463",
  },
  bankLogoContainer: {
    display: "flex",
    flexDirection: "row",
    background: `url(${BankBg}) no-repeat`,
    backgroundSize: "cover",
    width: "3em",
    height: "3em",
    borderRadius: "3em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginRight: "0.5em",
  },
  bankLogoContainerDeactivated: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#333333",
    width: "3em",
    height: "3em",
    borderRadius: "3em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginRight: "0.5em",
  },
  bankLogoGlass: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(5px)",
    width: "2.5em",
    height: "2.5em",
    borderRadius: "2.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoGlassDeactivated: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    backdropFilter: "blur(5px)",
    width: "2.5em",
    height: "2.5em",
    borderRadius: "2.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoTextDeactivated: {
    fontSize: "1em",
    fontWeight: 700,
    color: "#5A5463",
  },
  maskedBankAccountText: {
    display: "flex",
    alignItems: "center",
    color: "#727272",
    fontSize: "0.9em",
    fontWeight: 400,
  },
  maskedBankAccountTextDeactivated: {
    display: "flex",
    alignItems: "center",
    color: "#5A5463",
    fontSize: "0.9em",
    fontWeight: 400,
  },
  bankLogoText: {
    fontSize: "1em",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  totalRecordsStyle: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: 650,
  },
  centerText: {
    textAlign: "center",
    backgroundColor: "transparent",
  },
  loadingRow: {
    padding: "3em",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noDataRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: COLOR.offWhite,
    marginBottom: "6em",
  },
  sorryText: {
    fontSize: "1.8rem",
    fontWeight: 700,
    color: "#51547B",
    marginTop: "2em",
  },
};
export default styles;
