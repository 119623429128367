import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const RightArrowIcon = (props: Props) => {
  const { width = 38, height = 38 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 39 38" fill="none">
      <circle cx="19.5" cy="19" r="19" fill="#333333" />
      <path
        d="M16.5 12L24.5 18.75L16.5 25.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default RightArrowIcon;
