import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RedeemData,
  RedeemDetailResponse,
  RedeemList,
  RedeemListResponse,
} from "../../types/RedeemListModel";

interface RedeemListState {
  resultList: RedeemData | null;
  resultDetail: RedeemList | null;
  loading: boolean;
  error: string | null;
}

const initialState: RedeemListState = {
  resultList: null,
  resultDetail: null,
  loading: false,
  error: null,
};

const redeemSlice = createSlice({
  name: "redeem",
  initialState,
  reducers: {
    redeemListRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    redeemDetailRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    redeemListSuccess(state, action: PayloadAction<RedeemListResponse>) {
      state.loading = false;
      state.resultList = action.payload.data;
    },
    redeemDetailSuccess(state, action: PayloadAction<RedeemDetailResponse>) {
      state.loading = false;
      state.resultDetail = action.payload.data[0];
    },
    redeemListFailure(state, action: PayloadAction<string>) {
      state.resultList = null;
      state.loading = false;
      state.error = action.payload;
    },
    redeemDetailFailure(state, action: PayloadAction<string>) {
      state.resultDetail = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  redeemListRequest,
  redeemDetailRequest,
  redeemListSuccess,
  redeemDetailSuccess,
  redeemListFailure,
  redeemDetailFailure,
} = redeemSlice.actions;

export default redeemSlice.reducer;
