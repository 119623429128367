type Props = {
  width?: number;
  height?: number;
};
const TrendingStableIcon = (props: Props) => {
  const { width = 16, height = 11 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 5.5H15C15.5523 5.5 16 5.05228 16 4.5C16 3.94772 15.5523 3.5 15 3.5H1C0.447715 3.5 0 3.94772 0 4.5C0 5.05228 0.447715 5.5 1 5.5Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};
export default TrendingStableIcon;
