import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  BankData,
  BankDetailResponse,
  BankList,
  BankResponse,
} from "../../types/BankDetailModel";

interface BankDetailState {
  result: BankData | null;
  bankResult: BankList[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: BankDetailState = {
  result: null,
  bankResult: null,
  loading: false,
  error: null,
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    bankListRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    bankDetailRequest(state, action: PayloadAction<number>) {
      state.loading = true;
      state.error = null;
    },
    bankListSuccess(state, action: PayloadAction<BankResponse>) {
      state.loading = false;
      state.result = action.payload.data;
    },
    bankDetailSuccess(state, action: PayloadAction<BankDetailResponse>) {
      console.log("Bank details: ", action.payload.data);
      state.loading = false;
      state.bankResult = action.payload.data;
    },
    bankListFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
    bankDetailFailure(state, action: PayloadAction<string>) {
      state.bankResult = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  bankListRequest,
  bankListSuccess,
  bankListFailure,
  bankDetailRequest,
  bankDetailSuccess,
  bankDetailFailure,
} = bankSlice.actions;

export default bankSlice.reducer;
