/* eslint-disable array-callback-return */
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../store/reducers/RootReducers";
import { useEffect, useState } from "react";
import { configGroupRequest } from "../../../store/reducers/AEGConfigGroupReducer";
import { COLOR } from "../../../theme";
import AlertComponent from "../../../components/AlertComponent";
import { AlertDataProps } from "../../../types/Common";
import { Form, FormikProvider, useFormik } from "formik";
import { requestConfigGroupUpdate } from "../../../api/api";
import { emailSpecsSchema } from "../../../validations/Validation";
import PrimaryButton from "../../../components/PrimaryButton";

const EmailConfig = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const configData = useSelector((state: RootState) => state.configGroupState);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    dispatch(configGroupRequest("mailer"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (configData && configData.result && configData.result.data.length > 0) {
      formik.setFieldValue("serverName", getConfigValueByKey("server"));
      formik.setFieldValue("hostName", getConfigValueByKey("host"));
      formik.setFieldValue("portNumber", getConfigValueByKey("port"));
      formik.setFieldValue("userName", getConfigValueByKey("username"));
      formik.setFieldValue("password", getConfigValueByKey("password"));
      formik.setFieldValue(
        "senderAddress",
        getConfigValueByKey("from_address")
      );
      formik.setFieldValue(
        "userSupportEmail",
        getConfigValueByKey("user_support_email")
      );
      formik.setFieldValue(
        "devTeamEmail",
        getConfigValueByKey("devteam_email")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  const getConfigValueByKey = (key: string) => {
    if (
      configData !== null &&
      configData.result &&
      configData.result.data.length > 0
    ) {
      const configValueRes = configData.result.data.find(
        (data) => data.configKey === key
      );
      return configValueRes?.configValue;
    } else {
      return "";
    }
  };

  const submitConfigData = async (data: any) => {
    const response = await requestConfigGroupUpdate("mailer", data);
    if (response) {
      if (response.status === 200) {
        setAlertData({
          message: response.data.message,
          type: "success",
        });
        dispatch(configGroupRequest("mailer"));
      } else {
        setAlertData({
          message: response.data.message,
          type: "error",
        });
      }
    } else {
      setAlertData({
        message: "Failed to update config data. Please try again.",
        type: "error",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      serverName: "",
      hostName: "",
      portNumber: "",
      userName: "",
      password: "",
      senderAddress: "",
      userSupportEmail: "",
      devTeamEmail: "",
    },
    validationSchema: emailSpecsSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      if (configData?.result?.data && configData.result.data.length > 0) {
        const configDataList = configData.result.data;
        const updatedData = configDataList.map((data) => {
          if (data.configKey === "server") {
            return {
              ...data,
              configValue: formik.getFieldProps("serverName").value,
            };
          } else if (data.configKey === "host") {
            return {
              ...data,
              configValue: formik.getFieldProps("hostName").value,
            };
          } else if (data.configKey === "port") {
            return {
              ...data,
              configValue: formik.getFieldProps("portNumber").value,
            };
          } else if (data.configKey === "username") {
            return {
              ...data,
              configValue: formik.getFieldProps("userName").value,
            };
          } else if (data.configKey === "password") {
            return {
              ...data,
              configValue: formik.getFieldProps("password").value,
            };
          } else if (data.configKey === "from_address") {
            return {
              ...data,
              configValue: formik.getFieldProps("senderAddress").value,
            };
          } else if (data.configKey === "user_support_email") {
            return {
              ...data,
              configValue: formik.getFieldProps("userSupportEmail").value,
            };
          } else if (data.configKey === "devteam_email") {
            return {
              ...data,
              configValue: formik.getFieldProps("devTeamEmail").value,
            };
          }
        });
        submitConfigData(updatedData);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Box sx={styles.container}>
      <Card sx={styles.cardContainerStyle}>
        <CardHeader sx={styles.headerRow} title="Email Configuration" />
        <CardContent sx={styles.cardPadding}>
          <Box>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Server</label>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("serverName")}
                      error={Boolean(touched.serverName && errors.serverName)}
                      helperText={touched.serverName && errors.serverName}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Host</label>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("hostName")}
                      error={Boolean(touched.hostName && errors.hostName)}
                      helperText={touched.hostName && errors.hostName}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Port</label>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("portNumber")}
                      error={Boolean(touched.portNumber && errors.portNumber)}
                      helperText={touched.portNumber && errors.portNumber}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>User Name</label>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("userName")}
                      error={Boolean(touched.userName && errors.userName)}
                      helperText={touched.userName && errors.userName}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Password</label>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("password")}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Sender Address</label>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("senderAddress")}
                      error={Boolean(
                        touched.senderAddress && errors.senderAddress
                      )}
                      helperText={touched.senderAddress && errors.senderAddress}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Support Email</label>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("userSupportEmail")}
                      error={Boolean(
                        touched.userSupportEmail && errors.userSupportEmail
                      )}
                      helperText={
                        touched.userSupportEmail && errors.userSupportEmail
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={styles.labelText}>Dev Team Email</label>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("devTeamEmail")}
                      error={Boolean(
                        touched.devTeamEmail && errors.devTeamEmail
                      )}
                      helperText={touched.devTeamEmail && errors.devTeamEmail}
                    />
                  </Grid>
                </Grid>
                <Stack direction="row" sx={styles.updateButton}>
                  <PrimaryButton title="Update" onClick={handleSubmit} />
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </CardContent>
      </Card>

      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};
export default EmailConfig;
const styles = {
  container: {
    padding: "1em",
  },
  labelText: {
    fontSize: "1rem",
    fontWeight: 350,
    color: "#FFFFFF",
    marginBottom: "0.5em",
  },
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "1em",
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      color: "#FFFFFF",
      backgroundColor: "#1C1C1C",
    },
  },
  headerRow: {
    backgroundColor: "#171616",
    padding: "1em",
    "& .MuiCardHeader-title": {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#FFFFFF",
    },
  },
  textCenter: {
    textAlign: "center",
    backgroundColor: "transparent",
  },
  updateButton: {
    width: { sm: "100%", xs: "100%", md: "40%" },
    margin: "3em auto",
  },
};
