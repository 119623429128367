/* eslint-disable array-callback-return */
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../store/reducers/RootReducers";
import { useEffect, useState } from "react";
import { configGroupRequest } from "../../../store/reducers/AEGConfigGroupReducer";
import { COLOR } from "../../../theme";
import AlertComponent from "../../../components/AlertComponent";
import { AlertDataProps } from "../../../types/Common";
import { Form, FormikProvider, useFormik } from "formik";
import { aegTokenSpecsSchema } from "../../../validations/Validation";
import PrimaryButton from "../../../components/PrimaryButton";
import { requestConfigGroupUpdate } from "../../../api/api";

const AEGTokenSpecs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const configData = useSelector((state: RootState) => state.configGroupState);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    dispatch(configGroupRequest("aeg_details"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (configData && configData.result && configData.result.data.length > 0) {
      formik.setFieldValue(
        "goldPerPrice",
        getConfigValueByKey("gold_per_unit")
      );
      formik.setFieldValue(
        "sellMarginFees",
        getConfigValueByKey("sell_margin_fees_percent")
      );
      formik.setFieldValue(
        "sellMinimumQty",
        getConfigValueByKey("sell_minimum_qty")
      );
      formik.setFieldValue("buyMax", getConfigValueByKey("buy_max_qty"));
      formik.setFieldValue(
        "goldPriceAed",
        getConfigValueByKey("gold_price_aed")
      );
      formik.setFieldValue(
        "trnxFeesMultiplier",
        getConfigValueByKey("tx_fees_multiplier")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  const getConfigValueByKey = (key: string) => {
    if (
      configData !== null &&
      configData.result &&
      configData.result.data.length > 0
    ) {
      const configValueRes = configData.result.data.find(
        (data) => data.configKey === key
      );
      return configValueRes?.configValue;
    } else {
      return "";
    }
  };

  const submitConfigData = async (data: any) => {
    const response = await requestConfigGroupUpdate("aeg_details", data);
    if (response) {
      if (response.status === 200) {
        setAlertData({
          message: response.data.message,
          type: "success",
        });
        dispatch(configGroupRequest("aeg_details"));
      } else {
        setAlertData({
          message: response.data.message,
          type: "error",
        });
      }
    } else {
      setAlertData({
        message: "Failed to update config data. Please try again.",
        type: "error",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      goldPerPrice: "",
      sellMarginFees: "",
      sellMinimumQty: "",
      buyMax: "",
      goldPriceAed: "",
      trnxFeesMultiplier: "",
    },
    validationSchema: aegTokenSpecsSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      if (configData?.result?.data && configData.result.data.length > 0) {
        const configDataList = configData.result.data;
        const updatedData = configDataList.map((data) => {
          if (data.configKey === "gold_per_unit") {
            return {
              ...data,
              configValue: formik.getFieldProps("goldPerPrice")?.value,
            };
          } else if (data.configKey === "sell_margin_fees_percent") {
            return {
              ...data,
              configValue: formik.getFieldProps("sellMarginFees")?.value,
            };
          } else if (data.configKey === "sell_minimum_qty") {
            return {
              ...data,
              configValue: formik.getFieldProps("sellMinimumQty")?.value,
            };
          } else if (data.configKey === "buy_max_qty") {
            return {
              ...data,
              configValue: formik.getFieldProps("buyMax")?.value,
            };
          } else if (data.configKey === "gold_price_aed") {
            return {
              ...data,
              configValue: formik.getFieldProps("goldPriceAed")?.value,
            };
          } else if (data.configKey === "tx_fees_multiplier") {
            return {
              ...data,
              configValue: formik.getFieldProps("trnxFeesMultiplier")?.value,
            };
          }
        });
        submitConfigData(updatedData);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Box sx={styles.container}>
      <Card sx={styles.cardContainerStyle}>
        <CardHeader sx={styles.headerRow} title="AEG Details" />
        <CardContent sx={styles.cardPadding}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <label style={styles.labelText}>Gold Gram Per AEG</label>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    {...getFieldProps("goldPerPrice")}
                    error={Boolean(touched.goldPerPrice && errors.goldPerPrice)}
                    helperText={touched.goldPerPrice && errors.goldPerPrice}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label style={styles.labelText}>Sell Margin Fees</label>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    {...getFieldProps("sellMarginFees")}
                    error={Boolean(
                      touched.sellMarginFees && errors.sellMarginFees
                    )}
                    helperText={touched.sellMarginFees && errors.sellMarginFees}
                    sx={styles.inputStyle}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            backgroundColor: "#171616",
                            minHeight: "2.4em",
                            borderRadius: "0px 8px 8px 0px",
                          }}
                        >
                          <Typography sx={styles.inputSuffix}>%</Typography>
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: {
                          textAlign: "right",
                          fontWeight: 700,
                          letterSpacing: "0.2em",
                          boxSizing: "border-box",
                          paddingRight: "5px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <label style={styles.labelText}>Sell Minimum Qty</label>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    {...getFieldProps("sellMinimumQty")}
                    error={Boolean(
                      touched.sellMinimumQty && errors.sellMinimumQty
                    )}
                    helperText={touched.sellMinimumQty && errors.sellMinimumQty}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <label style={styles.labelText}>Buy Max Qty</label>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    {...getFieldProps("buyMax")}
                    error={Boolean(touched.buyMax && errors.buyMax)}
                    helperText={touched.buyMax && errors.buyMax}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <label style={styles.labelText}>Gold Price AED</label>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    {...getFieldProps("goldPriceAed")}
                    error={Boolean(touched.goldPriceAed && errors.goldPriceAed)}
                    helperText={touched.goldPriceAed && errors.goldPriceAed}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label style={styles.labelText}>
                    Transaction Fees Multiplier
                  </label>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    {...getFieldProps("trnxFeesMultiplier")}
                    error={Boolean(
                      touched.trnxFeesMultiplier && errors.trnxFeesMultiplier
                    )}
                    helperText={
                      touched.trnxFeesMultiplier && errors.trnxFeesMultiplier
                    }
                  />
                </Grid>
              </Grid>
              <Stack direction="row" sx={styles.updateButton}>
                <PrimaryButton title="Update" onClick={handleSubmit} />
              </Stack>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>

      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};
export default AEGTokenSpecs;
const styles = {
  container: {
    padding: "1em",
  },
  labelText: {
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#FFFFFF",
    marginBottom: "0.5em",
  },
  cardContainerStyle: {
    backgroundColor: COLOR.background,
    color: "white",
    borderRadius: "8px",
    minHeight: "95%",
  },
  paddingZero: {
    padding: 0,
  },
  cardPadding: {
    padding: "1em",
  },
  inputSuffix: {
    fontSize: "0.8rem",
    fontWeight: 700,
    padding: "5px 1em",
  },
  headerRow: {
    backgroundColor: "#171616",
    padding: "1em",
    "& .MuiCardHeader-title": {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#FFFFFF",
    },
  },
  tabHeading: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "#FFFFFF",
    padding: "1em",
  },
  textCenter: {
    textAlign: "center",
    backgroundColor: "transparent",
  },
  updateButton: {
    width: { sm: "100%", xs: "100%", md: "40%" },
    margin: "3em auto",
  },
  inputStyle: {
    fontSize: "1rem",
    "& .MuiInputBase-root": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      padding: "0 0 0 0",
    },
  },
};
