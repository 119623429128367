import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon, EyeShowIcon, EyeSlashIcon } from "../assets";
import TransitionComponent from "./TransitionComponent";
import { Form, FormikProvider, useFormik } from "formik";
import { AddAdminUserSchema } from "../validations/Validation";
import { useState } from "react";
import Loader from "./Loader";
import PrimaryButton from "./PrimaryButton";
import { requestAddAdminApi } from "../api/api";

type Props = {
  open: boolean;
  handleClose: () => void;
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
};

const AdminUsersAddDialog = (props: Props) => {
  const { open, handleClose, onError, onSuccess } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const onRequestSubmit = async (body: any) => {
    setLoading(true);
    const response = await requestAddAdminApi(body);
    setLoading(false);
    if (response) {
      if (response.status === 200) {
        onSuccess(response.data.message);
        console.log("Response: ", response.data);
      } else {
        onError(response.data.message);
        console.log("Response: ", response.data.message);
      }
    } else {
      onError("Something went wrong, !Please contact support");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: AddAdminUserSchema,
    onSubmit: (value) => {
      const body = {
        email: value.email,
        password: value.password,
      };
      onRequestSubmit(body);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
    >
      <DialogTitle sx={styles.headerStyle}>
        <Typography sx={styles.headerTitle}>Add Admin User</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Email Address"
              sx={styles.inputStyle}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              sx={styles.inputStyle}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                      style={{ color: "#FFC800" }}
                    >
                      {showPassword ? <EyeShowIcon /> : <EyeSlashIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <Box sx={{ lg: { marginTop: "6em" }, md: { marginTop: "8em" } }}>
              {loading ? (
                <Loader />
              ) : (
                <PrimaryButton title={"Sign In"} onClick={handleSubmit} />
              )}
            </Box>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};
export default AdminUsersAddDialog;
const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
    minWidth: "30vw",
  },
  dialogContent: {
    margin: "1em",
  },
  headerTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  headerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1em",
    margin: 0,
    borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
  },
  inputStyle: {
    input: {
      color: "white",
      fontWeight: 400,
    },
    marginBottom: "2em",
  },
};
