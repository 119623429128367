import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  adminUsersRequest,
  adminUsersFailure,
  adminUsersSuccess,
  adminUsersDetailsRequest,
  adminUsersDetailsSuccess,
  adminUsersDetailsFailure,
} from "../reducers/AdminUserReducer";
import {
  AdminUserDetailResponse,
  AdminUserResponse,
} from "../../types/AdminUserModel";

function* requestAdminUserSaga(action: PayloadAction<string>): any {
  try {
    let uri = action.payload ? `/admin-user?${action.payload}` : "/admin-user";
    const response = yield call(axiosInstance.get, uri);
    if (response.status === 200) {
      const data: AdminUserResponse = response.data;
      yield put(adminUsersSuccess(data));
    } else {
      yield put(adminUsersFailure(response.data.message));
    }
  } catch (error: any) {
    console.log("User Error: ", error);
    yield put(
      adminUsersFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* requestAdminUserDetailsSaga(action: PayloadAction<number>): any {
  try {
    const response = yield call(
      axiosInstance.get,
      `/admin-user/${action.payload}`
    );
    console.log("User Admin Details: 111 ", response);
    if (response.status === 200) {
      const data: AdminUserDetailResponse = response.data;

      yield put(adminUsersDetailsSuccess(data));
    } else {
      yield put(adminUsersDetailsFailure(response.data.message));
    }
  } catch (error: any) {
    console.log("User Error: ", error);
    yield put(
      adminUsersDetailsFailure(
        error.response ? error.response.data : error.message
      )
    );
  }
}

function* watchFetchAdminUsersSaga() {
  yield takeLatest(adminUsersRequest.type, requestAdminUserSaga);
  yield takeLatest(adminUsersDetailsRequest.type, requestAdminUserDetailsSaga);
}

export default watchFetchAdminUsersSaga;
