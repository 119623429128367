import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PersonalDetails,
  UserDetailResponse,
} from "../../types/UserDetailModel";

interface UserDetailState {
  result: PersonalDetails | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserDetailState = {
  result: null,
  loading: false,
  error: null,
};

const userDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    userDetailRequest(state, action: PayloadAction<number>) {
      state.loading = true;
      state.error = null;
    },
    userDetailSuccess(state, action: PayloadAction<UserDetailResponse>) {
      state.loading = false;
      state.result = action.payload.data.personal_details;
    },
    userDetailFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { userDetailRequest, userDetailSuccess, userDetailFailure } =
  userDetailSlice.actions;

export default userDetailSlice.reducer;
