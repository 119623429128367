import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AEGConfigResponse } from "../../types/AEGConfigModel";

interface AEGConfigGroupState {
  result: AEGConfigResponse | null;
  loading: boolean;
  error: string | null;
}

const initialState: AEGConfigGroupState = {
  result: null,
  loading: false,
  error: null,
};

const configGroupSlice = createSlice({
  name: "configGroup",
  initialState,
  reducers: {
    configGroupRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    configGroupSuccess(state, action: PayloadAction<AEGConfigResponse>) {
      state.loading = false;
      state.result = action.payload;
    },
    configGroupFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { configGroupRequest, configGroupSuccess, configGroupFailure } =
  configGroupSlice.actions;

export default configGroupSlice.reducer;
