import { COLOR } from "../../theme";

export const styles = {
  container: {
    width: "100%",
    backgroundColor: "rgba(10, 10, 10, 0.9)",
    border: "1px solid rgba(98, 77, 0, 1)",
    borderRadius: "1em",

  },
  pageTitle: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "#FFFFFF",
    textAlign: "center",
    marginTop: "2em",
  },
  cardPadding:{padding:"1em",height:'100%'},
  inlineSpaceBetween:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:"start",
    height:'100%'
  },
btnStyle:{
  marginTop:'3em'
},
  titleText:{
    fontSize:"1.2rem",
    fontWeight:600,
    color:"#FFFFFF",
    textAlign:"center",
    marginBottom:'3em'
  },
  inputStyle:{
      input: {
        color: "white",
        fontWeight: 400,
      },
      marginBottom:"2em"
  },
  linkStyle: {
    color: COLOR.primary,
    fontSize: "0.8rem",
    fontWeight: 600,
    alignSelf: "end",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  signInLink: {
    color: COLOR.primary,
    fontSize: "1em",
    fontWeight: 600,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};
