import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  usersFailure,
  usersRequest,
  usersSuccess,
} from "../reducers/UsersReducer";
import { UsersListResponse } from "../../types/UsersModel";
import { PayloadAction } from "@reduxjs/toolkit";

function* requestUserSaga(action: PayloadAction<string>): any {
  try {
    let uri = action.payload ? `/user?${action.payload}` : "/user";
    const response = yield call(axiosInstance.get, uri);
    if (response.status === 200) {
      const data: UsersListResponse = response.data;
      yield put(usersSuccess(data));
    } else {
      yield put(usersFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      usersFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchUsersSaga() {
  yield takeLatest(usersRequest.type, requestUserSaga);
}

export default watchFetchUsersSaga;
