import React from "react";
import ThemeProvider from "./theme";
import theme from "./theme/index";
import AppRouter from "./AppRouter";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
