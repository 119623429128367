import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TransitionComponent from "./TransitionComponent";
import { CloseIcon } from "../assets";
import { Form, FormikProvider, useFormik } from "formik";
import { updatePasswordSchema } from "../validations/Validation";
import PrimaryButton from "./PrimaryButton";
import { UpdateUserPasswordRequest } from "../types/UsersModel";
import { updateUserPassword } from "../api/api";

type Props = {
  open: boolean;
  userId: number;
  onClose: () => void;
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
};
const UpdatePasswordDialog = (props: Props) => {
  const { open, userId, onClose, onError, onSuccess } = props;

  const updatePasswordRequest = async (
    id: number,
    body: UpdateUserPasswordRequest
  ) => {
    try {
      console.log("Body: ", body);

      const response = await updateUserPassword(id, body);
      if (response) {
        if (response.status === 200) {
          onSuccess(response.data.message);
        } else {
          onError(response.data.message);
        }
      }
    } catch (error) {
      onError("Something went wrong");
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: updatePasswordSchema,
    onSubmit: (value) => {
      if (userId) {
        const body: UpdateUserPasswordRequest = {
          old_password: value.oldPassword,
          new_password: value.newPassword,
        };
        updatePasswordRequest(userId, body);

        //dispatch(loginRequest(body));
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={TransitionComponent}
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
          minWidth: "380px",
          maxWidth: "380px",
        },
      }}
    >
      <DialogTitle sx={styles.headerRow}>
        <Typography sx={styles.headerTitle} mr={3}>
          Update Password
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <TextField
              fullWidth
              autoComplete="off"
              type="text"
              label="Old Password"
              sx={styles.inputStyle}
              {...getFieldProps("oldPassword")}
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              helperText={touched.oldPassword && errors.oldPassword}
            />

            <TextField
              fullWidth
              autoComplete="off"
              type="text"
              label="New Password"
              sx={styles.inputStyle}
              {...getFieldProps("newPassword")}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />

            <TextField
              fullWidth
              autoComplete="off"
              type="text"
              label="Confirm Password"
              sx={styles.inputStyle}
              {...getFieldProps("confirmPassword")}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mt={2}
            >
              <PrimaryButton title="Update" onClick={handleSubmit} />
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};
export default UpdatePasswordDialog;

const styles = {
  headerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "1.1rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  labelText: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
    marginBottom: "0.5em",
    textAlign: "center",
  },
  inputStyle: {
    input: {
      color: "white",
      backgroundColor: "transparent",
      fontWeight: 400,
    },
    marginTop: "1em",
  },
  approveButton: {
    width: "6em",
    height: "2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "#14CB1B",
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(20, 203, 27,0.5)",
      cursor: "pointer",
    },
  },
  approvedButtonTxt: {
    fontSize: "0.9rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  rejectedButton: {
    width: "6em",
    height: "2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3em",
    backgroundColor: "#c71414",
    transaction: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(199, 20, 20,0.5)",
      cursor: "pointer",
    },
  },
  rejectedButtonTxt: {
    fontSize: "0.9rem",
    fontWeight: 700,
    color: "#FFFFFF",
  },
};
