import { Typography, Box, Stack } from "@mui/material";
import "../../custom-styles.css";
import { Outlet } from "react-router-dom";
import { LogoIcon } from "../../assets";
import { useStyles } from "./styles";
import useResponsive from "../../hooks/useResponsive";

const AuthLayout = () => {
  const isDesktop = useResponsive({ query: "up", key: "lg" });
  const styles = useStyles(isDesktop || false);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftContentHeader}>
        <Stack direction={"row"} alignItems={"center"}>
          <LogoIcon width={98} height={67} />
          <Typography sx={styles.leftContentTitle} ml={2}>
            AEG
          </Typography>
        </Stack>
        <Typography sx={styles.leftContentTitle}>Admin Dashboard</Typography>
      </Box>
      <Box sx={styles.formContainer}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthLayout;
